import React from 'react';
import { NotFoundInner } from 'pages/NotFound';
import { useNavigate, Route, Routes } from 'react-router-dom';
import _ from 'lodash';
import DashboardLayout from 'pages/Dashboard/layouts/DashboardLayout';
import SidebarMenu from 'pages/Dashboard/components/SidebarMenu';
import Entity from 'pages/Dashboard/pages/Entities';
import EntitiesList from 'pages/Dashboard/pages/Entities/list';
import { useRequireAdminUser } from '../../lib/auth';
import Requests from './pages/Requests';
import { PendingUserStore } from './store/pendingUsers';
import { StaffUserStore } from './store/staffUser';

function IndexRedirect() {
  const user = useRequireAdminUser();
  const navigate = useNavigate();
  if (!_.isEmpty(user.entities)) {
    navigate('./entities/list');
  } else {
    navigate('./entities/list');
  }
  return null;
}

export default function DashboardRouter() {
  return (
    <DashboardLayout sidebar={<SidebarMenu />}>
      <StaffUserStore>
        <PendingUserStore>
          <Routes>
            <Route path="/" element={<IndexRedirect />} />
            <Route path="/entities/show/:entityId/*" element={<Entity />} />
            <Route exact path="/entities/list" element={<EntitiesList />} />
            <Route path="/entities/requests/*" element={<Requests />} />
            <Route path="*" element={<NotFoundInner />} />
          </Routes>
        </PendingUserStore>
      </StaffUserStore>
    </DashboardLayout>
  );
}
