import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../../layouts/BasicLayout';
import { useResourceMany } from '../../lib/api';
import { Paragraph, Title, NormalText } from '../../components/Typography';
import Button from '../../components/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const PREFIX = 'Άδεια ειδικού σκοπού εργαζόμενων γονέων: ';
const Main = ({ t }) => {
  const { data: templates } = useResourceMany('templates');
  const adeiaTemplates = templates.data.filter(template =>
    template.refname.includes('ADEIA-COVID19-')
  );
  return (
    <div>
      <ul>
        {adeiaTemplates.map((template, key) => (
          <li>
            <NormalText>
              <Link
                to={`/create/q/confirm-personal-info?refname=${
                  template.refname
                }`}
              >
                {template.shortname.replace(PREFIX, '')}
              </Link>
            </NormalText>
          </li>
        ))}
      </ul>
    </div>
  );
};
Main.propTypes = {
  t: PropTypes.func.isRequired,
};

export default function Home() {
  const { t } = useTranslation();
  return (
    <BasicLayout
      main={<Main t={t} />}
      topPadding
      title={t('adeiacovid_page.title')}
      isLoggedIn={false}
    />
  );
}
