import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import el from './locales/el';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      el: {
        translation: el,
      },
    },
    lng: 'el',
    keySeparator: '.', // we use keys in form messages.welcome
    nsSeparator: '|',
  });

export default i18n;
