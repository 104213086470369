import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShowQuestionPage from './ShowQuestionPage';
import ShowDeclaration from './ShowDeclaration';
import NotFound from '../NotFound';

export default function ShowRouter() {
  return (
    <Routes>
      <Route path="q/:qid" element={<ShowQuestionPage />} />
      <Route path=":ref" element={<ShowDeclaration />} />
      <Route path=":ref/:inboxId" element={<ShowDeclaration />} />
      <Route path="" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
