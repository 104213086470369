import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Title, Paragraph } from '../../components/Typography';
import DeclarationQRCode from '../../components/Declaration/DeclarationQRCode';
import DeclarationActions from '../../components/DeclarationActions';
import { Grid } from '@material-ui/core';

export function DashboardBar({ inboxItem, declaration, template, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.dashboardBar}>
      <Grid item xs={12} md={4}>
        <Title className={classes.sidebarTitle} size="md">
          {t('declaration_entity_table.refCode')}
        </Title>
        <Paragraph className={classes.refCode}>
          {declaration.reference_code}
        </Paragraph>
      </Grid>
      <Grid item xs={12} md={4}>
        <Title className={classes.sidebarTitle} size="md">
          {t('declaration_entity_table.status')}
        </Title>
        <Paragraph>{`${inboxItem.inbox_status}_verbose`}</Paragraph>
      </Grid>
      <Grid item xs={12} md={4}>
        <Title className={classes.sidebarTitle} size="md">
          {t('declaration_entity_table.template')}
        </Title>
        <Paragraph>{template.refname}</Paragraph>
      </Grid>
    </Grid>
  );
}

export default function Sidebar({ declaration, template, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <DeclarationQRCode declaration={declaration} />
      <div className={classes.statusRow}>
        <Title className={classes.sidebarTitle} size="md">
          {t('declaration_entity_table.refCode')}
        </Title>
        <Paragraph>{declaration.reference_code}</Paragraph>
      </div>
      <div className={classes.statusRow}>
        <Title className={classes.sidebarTitle} size="md">
          {t('declaration_entity_table.status')}
        </Title>
        <Paragraph>{`${declaration.state}_verbose`}</Paragraph>
      </div>
      <div className={classes.statusRow}>
        <Title className={classes.sidebarTitle} size="md">
          {t('declaration_entity_table.template')}
        </Title>
        <Paragraph>{template.refname}</Paragraph>
      </div>
      <div className={classes.statusRow}>
        <DeclarationActions
          isOwner={props.isOwner}
          isAdmin={props.isAdmin}
          declaration={declaration}
          template={template}
        />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  declaration: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};

const useStyles = makeStyles(theme => ({
  statusRow: {
    marginBottom: theme.spacing(4),
  },
  sidebarTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  sidebarList: {
    padding: 0,
  },
  sidebar: {
    marginTop: theme.spacing(8),
  },
  refCode: {
    fontWeight: 600,
    fontSize: '1.3rem',
    wordWrap: 'break-word',
  },
  dashboardBar: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));
