import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Field, FormBuilder } from '../../lib/form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/Button';
import ButtonIcon from '../../components/ButtonIcon';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useInboxAction } from './actions';

const useStyles = makeStyles(theme => ({
  rejectButton: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialog: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  dialogActions: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  dialogParagraph: {
    marginBottom: theme.spacing(4),
  },
}));

export default function RejectButton(props) {
  const [postData, setPostData] = useState(null);
  const action = useInboxAction(props, 'reject', postData);
  const classes = useStyles();
  const { t } = useTranslation();
  const fields = [
    {
      label: t('label.reason'),
      key: 'reason',
      required: true,
      type: 'text',
    },
  ];
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (action.loaded && !action.error) {
      setOpen(false);
      window.location.reload();
    }
  }, [action.loaded, action.error]);

  // in order to access form html element and trigger submit
  return (
    <>
      <ButtonIcon
        className={clsx(classes.button, classes.rejectButton)}
        Icon={CloseIcon}
        label="Απόρριψη"
        onClick={() => setOpen(true)}
      />
      <Dialog
        className={classes.dialog}
        open={open}
        aria-labelledby="form-dialog-title"
      >
        <FormBuilder
          fields={fields}
          onSubmit={data => {
            setPostData(data);
            action.fetch();
          }}
        >
          <DialogTitle id="form-dialog-title">Απόρριψη Δήλωσης</DialogTitle>
          <DialogContent className={classes.dialog}>
            <DialogContentText className={classes.dialogParagraph}>
              Παρακαλούμε αναφέρετε το λόγο που απορρίπτετε την αίτηση, ώστε να
              ενημερωθεί ο πολίτης για τον λόγο της απόρριψης.
            </DialogContentText>
            <Field
              name="reason"
              error={action.error && action.error.error}
              autofocus={true}
              multiline
              rowsMax="4"
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button onClick={() => setOpen(false)} color="secondary">
              Ακύρωση
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Αποστολή
            </Button>
          </DialogActions>
        </FormBuilder>
      </Dialog>
    </>
  );
}

RejectButton.propTypes = {
  onSuccess: PropTypes.func,
  inboxItem: PropTypes.object,
};
