import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    margin: theme.spacing(1, 2),
    padding: theme.spacing(0.5, 1.5),
    fontSize: theme.typography.htmlFontSize,
    "& + .MuiServiceBadge-root": {
      marginLeft: '-10px',
    },
  },
  alt: {
    backgroundColor: theme.palette.warning.main,
  }
}), {
  name: 'MuiServiceBadge',
});

export default function ServiceBadge({alt, label}) {
  const classes = useStyles();
  return <div className={clsx(classes.root, {
    [classes.alt]: alt, 
  })}>{label}</div>;
}
