import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    nested: {
      paddingLeft: theme.spacing(2),
    },
    nestedDouble: {
      paddingLeft: theme.spacing(4),
    },
    Link: {
      textDecoration: 'none',
      color:
        theme.palette.type === 'dark'
          ? theme.palette.common.white
          : theme.palette.text.primary,
    },
  };
});

export default function NavMenuItem({ label, ...props }) {
  const { path } = props;
  const classes = useStyles();
  const location = useLocation();
  return (
    <Link to={path}>
      <ListItem
        dense
        className={clsx(
          props.nested && classes.nested,
          props.nested && props.root && classes.nestedDouble
        )}
        button
        selected={location.pathname.includes(path)}
      >
        <ListItemText>
          <a className={classes.Link}>{label}</a>
        </ListItemText>
      </ListItem>
    </Link>
  );
}
NavMenuItem.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  nested: PropTypes.bool,
  root: PropTypes.bool,
};
NavMenuItem.defaultProps = {
  path: '',
  label: '',
  nested: false,
  root: false,
};
