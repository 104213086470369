import React, { useMemo, createContext, useContext, useCallback } from 'react';
import { validateAFM } from '@lytrax/afm';
import PropTypes from 'prop-types';
import { useForm as useHookForm, Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Input, { FileInput } from '../components/Input';
import Select from '../components/Select';
import RadioButtonsGroup from '../components/RadioGroup';
import { SimpleAutocomplete as Autocomplete } from '../components/Autocomplete';
import { NormalText, CardTitle } from '../components/Typography';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import * as gPhoneNumber from 'google-libphonenumber';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { useState } from 'react';
import Markdown from 'react-markdown';

type FormField = {
  label: string,
  editable: boolean,
  value: any,
  hint: string,
  helptext: string,
  layout: Object,
  validator: any,
  component: any,
  type: string,
  fieldType: string,
  key: string,
  validate: ?Function,
  required: boolean,
  error: ?Object,
};

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4),
  },
  field: {
    marginTop: theme.spacing(1),
  },
  fieldLabel: {
    fontWeight: 500,
  },
  hint: {
    color: theme.palette.grey['700'],
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },
  fieldContainer: {
    marginBottom: theme.spacing(1),
  },
  md: {
    '& p': {
      margin: 0,
    },
  },
  errorContainer: {
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.error.main}`,
    paddingLeft: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bolder',
    margin: theme.spacing(2, 0),
  },
}));

const FIELD_COMPONENTS = {
  text: Input,
  string: Input,
  attachment: FileInput,
  file: FileInput,
  buttonsGroup: RadioButtonsGroup,
  autocomplete: Autocomplete,
  select: Select,
};

const YUP_TYPE_MAP = {
  string: yup.string,
  text: yup.string,
  file: yup.mixed,
  attachment: yup.mixed,
  int: () =>
    yup
      .number()
      .typeError('form.validation.number')
      .positive('form.validation.number'),
  email: () => yup.string().email('form.validation.email'),
};

const AFM_VALIDATOR = {
  name: 'afm-validator',
  message: 'form.validation.afm',
  test: value => {
    if (value) {
      return validateAFM(value);
    }
    return true;
  },
};

function validateMobile(value) {
  const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
  const TYPES = gPhoneNumber.PhoneNumberType;
  const origValue = value;

  try {
    let phone;
    try {
      phone = phoneUtil.parse(value);
    } catch (err) {
      try {
        if (value.startsWith('00')) {
          value = value.slice(2);
          value = '+' + value;
        }
        phone = phoneUtil.parse(value);
      } catch (err) {
        value = '+30' + origValue;
        phone = phoneUtil.parse(value);
      }
    }
    const type = phoneUtil.getNumberType(phone);
    if (type === TYPES.MOBILE || type === TYPES.FIXED_LINE_OR_MOBILE) {
      return phoneUtil.isValidNumber(phone);
    }

    const countryCode = phoneUtil.getRegionCodeForNumber(
      phoneUtil.parse(value)
    );

    if (countryCode !== 'GR') {
      return false;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
}

const MOBILE_PHONE_VALIDATOR = {
  name: 'mobile-phone-validator',
  message: value => {
    const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
    if (value.value.startsWith('+')) {
      if (
        phoneUtil.getRegionCodeForNumber(phoneUtil.parse(value.value)) !== 'GR'
      ) {
        return 'form.validation.non_greek_number';
      } else {
        return 'form.validation.mobile_phone';
      }
    } else {
      return 'form.validation.mobile_phone';
    }
  },
  test: value => {
    if (value) {
      return validateMobile(value);
    }

    return true;
  },
};

const TYPES_VALIDATORS = {
  afm: [AFM_VALIDATOR],
  mobile_phone: [MOBILE_PHONE_VALIDATOR],
};

// Create a yup validation schema from given fields input
export function useValidationSchema(fields) {
  return useMemo(() => {
    const fieldSchemas = {};
    fields.forEach(field => {
      let yupField =
        YUP_TYPE_MAP[field.fieldType] ||
        YUP_TYPE_MAP[field.type] ||
        YUP_TYPE_MAP.string;
      yupField = yupField();
      if (field.required) {
        yupField = yupField.required('form.validation.required');
      }
      let validators =
        TYPES_VALIDATORS[field.fieldType] || TYPES_VALIDATORS[field.type] || [];
      if (field.validators) {
        validators = validators.concat(validators);
      }
      validators.forEach(validator => {
        yupField = yupField.test(validator);
      });
      fieldSchemas[field.key] = yupField;
    });
    return yup.object().shape(fieldSchemas);
  }, [fields]);
}

const useExampleStyles = makeStyles(theme => ({
  link: {
    background: 'none',
    border: 'none',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '1.2rem',
  },
  pre: {
    whiteSpace: 'pre-line',
    marginTop: -theme.spacing(1) + 'px',
  },
}));

export function Example({ title, label, children }) {
  const [open, setOpen] = useState(false);
  const classes = useExampleStyles();
  function handleClickClose() {
    setOpen(false);
  }
  return (
    <>
      <button
        type="button"
        className={classes.link}
        onClick={e => {
          e.preventDefault();
          setOpen(true);
        }}
      >
        {label}
      </button>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <pre className={classes.pre}>{children}</pre>
        </DialogContent>
      </Dialog>
    </>
  );
}

export const MDHelper = props => {
  const classes = useStyles();
  return (
    <div className={classes.md}>
      <Markdown
        {...props}
        renderers={{
          // eslint-disable-next-line react/display-name
          link: props => (
            <a href={props.href} rel="noreferrer noopener" target="_blank">
              {props.children}
            </a>
          ),
        }}
      />
    </div>
  );
};

// Form Builder
export function FieldBase({
  field,
  value,
  register,
  setValue,
  error,
  gridProps,
  children,
  component,
  control,
  ...props
}: {
  field: FormField,
}) {
  const classes = useStyles();
  const Component = useMemo(() => {
    return (
      component ||
      field.component ||
      FIELD_COMPONENTS[field.fieldType] ||
      FIELD_COMPONENTS[field.type] ||
      FIELD_COMPONENTS.string
    );
  }, [component, field.component, field.type, field.fieldType]);

  const layout = useMemo(() => {
    return field.layout || {};
  }, [field.layout]);

  const { t } = useTranslation();
  const registerOptions = useMemo(() => {
    return { required: !!field.required };
  }, [field.required]);

  const helperText = useMemo(() => {
    if (!field.hint_md && !field.hint && !field.example) {
      return;
    }
    let node = '';
    if (typeof field.helptext === 'string') {
      node = t(field.helptext);
    }
    // react node
    const hint = field.hint_md ? (
      <MDHelper source={field.hint_md} />
    ) : (
      field.hint
    );
    node = hint;
    if (field.example) {
      return (
        <>
          {node}{' '}
          <Example label={t('hint.example')} title={t('hint.example')}>
            <MDHelper source={field.example} />
            {field.example}
          </Example>
        </>
      );
    }
    return node;
  }, [field.hint, field.hint_md, field.example]);

  const fieldProps = useMemo(() => {
    return field._fieldProps || {};
  }, [field._fieldProps]);

  return (
    <Grid
      className={classes.fieldContainer}
      item
      xs={layout.xs || 12}
      sm={layout.sm || 12}
      {...(gridProps || {})}
    >
      <div className={error && classes.errorContainer}>
        <label htmlFor={field.name}>
          <NormalText className={classes.fieldLabel}>{field.label}</NormalText>
        </label>
        {helperText && (
          <span className={classes.hint}>
            <NormalText>{helperText}</NormalText>
          </span>
        )}
        {error && (
          <span>
            <NormalText className={classes.error}>{error.message}</NormalText>
          </span>
        )}
        <Controller
          as={Component}
          control={control}
          type={field.type}
          name={field.key}
          helperText={error && t(error.message)}
          error={!!error}
          defaultValue={value}
          rules={registerOptions}
          disabled={field.editable === false}
          options={field.options}
          placeholder={field.placeholder}
          autoComplete={field.autocomplete}
          {...props}
          {...fieldProps}
        />
      </div>
    </Grid>
  );
}
FieldBase.propTypes = {
  value: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  error: PropTypes.object,
  control: PropTypes.any,
  gridProps: PropTypes.object,
  component: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function Fieldset({ children }) {
  return (
    <Grid container spacing={1}>
      {children}
    </Grid>
  );
}
Fieldset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function FieldsetLabel({ children }) {
  return (
    <Grid item xs={12}>
      <CardTitle style={{ paddingLeft: '4px' }}>{children}</CardTitle>
    </Grid>
  );
}
FieldsetLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function FormBase({ onSubmit, children }) {
  return (
    <form onSubmit={onSubmit}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </form>
  );
}
FormBase.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const FormContext = createContext(null);

export function FormBuilder({
  fields,
  authorizedFields,
  initial,
  onSubmit,
  formRef,
  children,
  errors,
}) {
  const allFields = useMemo(() => {
    return fields.concat(authorizedFields || []);
  }, [fields, authorizedFields]);
  const schema = useValidationSchema(allFields);
  const form = useHookForm({
    validationSchema: schema,
  });
  const fieldsMap = useMemo(() => {
    const map = {};
    fields.forEach(f => {
      map[f.key] = f;
    });
    authorizedFields.forEach(f => {
      map[f.key] = f;
    });
    return map;
  }, [fields, form.errors]);

  if (formRef) {
    formRef.current = form;
  }

  initial = initial || {};
  const fieldErrors = useMemo(() => {
    return errors || {};
  }, [errors]);

  const ctx = {
    fields,
    onSubmit,
    fieldsMap,
    initial,
    fieldErrors, // user provided errors
    ...form,
  };

  const handleSubmit = useCallback(
    data => {
      const isEmptyString = v => {
        return typeof v === 'string' && v.length === 0;
      };
      const newData = {};
      // non edited values are set as "" by react-hook-forms (thats the html element value)
      // we filter those for which no initial value is provided in order to not include
      // these in consequent API calls post data.
      Object.keys(data).forEach(k => {
        if (isEmptyString(data[k]) && initial[k] === undefined) {
          return;
        }
        newData[k] = data[k];
      });
      onSubmit(newData);
    },
    [onSubmit]
  );

  return (
    <FormContext.Provider value={ctx}>
      <FormBase onSubmit={form.handleSubmit(handleSubmit)}>{children}</FormBase>
    </FormContext.Provider>
  );
}
FormBuilder.propTypes = {
  fields: PropTypes.array,
  authorizedFields: PropTypes.array,
  initial: PropTypes.object,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
FormBuilder.defaultProps = {
  fields: [],
  authorizedFields: [],
  initial: {},
};

export function Field({ name, children, error, ...props }) {
  const classes = useStyles();
  const {
    fieldsMap,
    initial,
    register,
    errors,
    fieldErrors,
    setValue,
    control,
  } = useContext(FormContext);
  const field = useMemo(() => fieldsMap[name], [fieldsMap, name]);
  if (!field && !children) {
    throw Error(`Field ${name} cannot be found in `, fieldsMap);
  }
  if (!field && children) {
    return children(register, errors, setValue);
  }
  return (
    <FieldBase
      error={error || errors[field.key] || fieldErrors[field.key]}
      className={classes.field}
      field={field}
      value={initial[name]}
      setValue={setValue}
      register={register}
      control={control}
      {...props}
    >
      {children}
    </FieldBase>
  );
}
Field.propTypes = {
  name: PropTypes.string,
  error: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
