import FullWidthLayout from '../../layouts/FullWidthLayout';
import BasicLayout from '../../layouts/BasicLayout';
import ValidateCode from './ValidateCode';
import ScanCode from './ScanCode';

export default function useQuestions() {
  return [
    {
      qid: 'validate',
      layout: FullWidthLayout,
      component: ValidateCode,
    },
    {
      qid: 'scan',
      layout: BasicLayout,
      component: ScanCode,
    },
  ];
}
