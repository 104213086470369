import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import './i18n';

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

window.APP_CONFIG = window.APP_CONFIG || {};

const CONFIG_URL = process.env.REACT_APP_CONFIG_URL;

const boot = () => {
  const config = require('./lib/config').getConfig();
  if (config.SENTRY_DSN) {
    try {
      Sentry.init({ dsn: config.SENTRY_DSN });
    } catch(err) {
      console.error(err);
    }
  }
  ReactDOM.render(<App />, document.querySelector('#root'));
};

if (CONFIG_URL) {
  const cfg = fetch(process.env.REACT_APP_CONFIG_URL);
  cfg
    .then(data => {
      return data.json().then(config => {
        window.APP_CONFIG = { ...window.APP_CONFIG, ...config };
        boot();
      });
    })
    .catch(err => {
      console.error(err);
      boot();
    });
} else {
  boot();
}
