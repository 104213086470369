import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core';
import { useRequireAdminUser } from '../../../../lib/auth';
import { useResourceMany } from '../../../../lib/api';
import MyRequestsTable from '../../components/MyRequestsTable';

export default function UserPermissions() {
  const user = useRequireAdminUser();
  const myRequests = useResourceMany(`my-permission-requests`);
  console.log('req', myRequests);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <MyRequestsTable myRequests={myRequests} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
