import React from 'react';
import { Route, Routes, useRouteMatch } from 'react-router-dom';
// import TemplatePageStart from './TemplatePage';
import TemplatePage from './TemplatePage';

export default function CreateRouter() {
  // const { path } = useRouteMatch();
  return (
    <Routes>
      {/* <Route
        exact
        path={`${path}/:refname`}
        component={TemplatePage}
      /> */}
      <Route path=":refname" element={<TemplatePage />} />
    </Routes>
  );
}
