import React from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import { useTranslation } from 'react-i18next';
import { MDHelper } from '../../lib/form';
import { useLocation } from 'react-router';
import Button from '../../components/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Paragraph } from '../../components/Typography';
import Quote from '../../components/Quote';

function Main({ prefix }) {
  const { t } = useTranslation();
  const nextUrl = '/api/login';
  return (
    <>
      <Quote warning>{t('info.no-mobile.warning')}</Quote>
      <Paragraph>{t('info.no-mobile.text')}</Paragraph>
      <br />
      <Button
        href={nextUrl}
        component='a'
        style={{
          marginBottom: 32,
        }}
      >
        {t('info.no-mobile.button')}
        <ChevronRightIcon />
      </Button>
    </>
  );
}

export default function InfoPage({ infoKey, ...props }) {
  const { t } = useTranslation();
  const ns = new URLSearchParams(useLocation().search).get('msg');
  const prefix = 'info.' + infoKey + (ns && ns.length ? '.' + ns : '');
  return (
    <BasicLayout
      title={t(prefix + '.title')}
      topPadding
      main={<Main prefix={prefix} />}
    />
  );
}
