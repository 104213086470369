import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useResource } from '../lib/api';
import { Title, Paragraph } from '../components/Typography';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Header from '../layouts/Header';
import Top from '../components/Top';
import Loader from '../components/Loader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(0, 6, 4),
    marginTop: theme.spacing(6),
    '@media (max-width: 767px)': {
      padding: theme.spacing(0, 2),
    },
  },
  value: {
    textAlign: 'left',
  },
  title: {
    textAlign: 'left',
    fontSize: '20px',
  },
}));

function FullWidthTabs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  const stats = useResource('stats');
  const issued_keys = Object.keys(stats.data.issued);
  const editing_keys = Object.keys(stats.data.editing);
  const requested_keys = Object.keys(stats.data.requested);
  const revoked_keys = Object.keys(stats.data.revoked);

  return (
    <>
      <Title size="xl">Στατιστικά</Title>
      <AppBar position="static" color="default">
        <Tabs
          fullWidth
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t('stats.issued')} />
          <Tab label={t('stats.editing')} />
          <Tab label={t('stats.requested')} />
          <Tab label={t('stats.revoked')} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        fullWidth
        axis={'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <GridList cellHeight={150} cols={4}>
            {issued_keys.map(key => (
              <GridListTile>
                <Paragraph className={classes.title}>{key}</Paragraph>
                <Title className={classes.value} size="xl">
                  {stats.data.issued[key]}
                </Title>
              </GridListTile>
            ))}
          </GridList>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GridList cellHeight={150} cols={4}>
            {editing_keys.map(key => (
              <GridListTile>
                <Paragraph className={classes.title} size="md">
                  {key}
                </Paragraph>
                <Title className={classes.value} size="xl">
                  {stats.data.editing[key]}
                </Title>
              </GridListTile>
            ))}
          </GridList>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GridList cellHeight={150} cols={4}>
            {requested_keys.map(key => (
              <GridListTile>
                <Paragraph className={classes.title} size="sm">
                  {key}
                </Paragraph>
                <Title className={classes.value} size="xl">
                  {stats.data.requested[key]}
                </Title>
              </GridListTile>
            ))}
          </GridList>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GridList cellHeight={150} cols={4}>
            {revoked_keys.map(key => (
              <GridListTile>
                <Paragraph className={classes.title} size="md">
                  {key}
                </Paragraph>
                <Title className={classes.value} size="xl">
                  {stats.data.revoked[key]}
                </Title>
              </GridListTile>
            ))}
          </GridList>
        </TabPanel>
      </SwipeableViews>
    </>
  );
}

export default function Stats(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    title,
    captionRight,
    captionLeft,
    noTop,
    topPadding,
    isLoggedIn,
    name,
  } = props;

  return (
    <>
      <Box display="flex" displayPrint={true} flex={1} flexGrow={0}>
        <Header isLoggedIn={isLoggedIn} name={name} />
      </Box>
      <Container className={classes.mainContainer}>
        <Grid container spacing={10}>
          <Grid item md={12} xs={12}>
            <Suspense fallback={<Loader />}>
              <Box display="flex" displayPrint={true}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {!noTop && (
                      <Top
                        size="xl"
                        className={topPadding && classes.title}
                        title={title}
                        captionRight={captionRight}
                        captionLeft={captionLeft}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Suspense fallback={<Loader />}>{<FullWidthTabs />}</Suspense>
            </Suspense>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

Stats.propTypes = {
  title: PropTypes.string,
  captionRight: PropTypes.string,
  captionLeft: PropTypes.string,
  noTop: PropTypes.bool,
  topPadding: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  name: PropTypes.bool,
};
