import React from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { useTranslation } from 'react-i18next';
import { Title, Paragraph } from '../components/Typography';

function Main() {
  return (
    <>
      <Paragraph>wrong_page.paragraph1</Paragraph>
      <Paragraph>wrong_page.paragraph2</Paragraph>
    </>
  );
}

export default function WrongPage() {
  const { t } = useTranslation();
  return (
    <BasicLayout title={t('wrong_page.title')} topPadding main={<Main />} />
  );
}
