import { useMemo } from 'react';

// resolve config variable from the window context
function resolveKey(key, dflt) {
  const env = process.env[`REACT_APP_${key}`];
  const win = (window.APP_CONFIG || {})[key];
  return env === undefined ? (win === undefined ? dflt : win) : env;
}

// build config
export function getConfig() {
  // construct API_URL based defaults
  function url(path) {
    return API_URL + path;
  }

  // resolve keys based on process.env or window.APP_CONFIG;
  const API_URL = resolveKey('API_URL', '/api');
  const AUTH_USER_DATA_URL = url('/users/me');
  const AUTH_LOGIN_URL = url('/gsis_login/');
  const AUTH_TOKEN_URL = url('/token/');
  const DEBUG = resolveKey('DEBUG', false);

  const computed = {
    // use window.APP_CONFIG as is
    ...(window.APP_CONFIG || {}),
    API_URL,
    AUTH_USER_DATA_URL,
    AUTH_LOGIN_URL,
    AUTH_TOKEN_URL,
    DEBUG,
  };
  // for developers to track resulted config
  window.APP_CONFIG_COMPUTED = computed;
  return computed;
}

// react-like interface
export default function useConfig() {
  return useMemo(() => {
    return getConfig();
  }, []);
}

// return all flags from configuration
export function useFlags() {
  const config = useConfig();
  return useMemo(() => {
    return config.flags || {};
  }, [config, config.flags]);
}

// return specific flag if set, return false otherwise
export function useFlag(key) {
  const flags = useFlags();
  return useMemo(() => {
    return (flags && flags[key]) || false;
  }, [flags]);
}
