import React from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import { useTranslation } from 'react-i18next';
import { MDHelper } from '../../lib/form';
import { useLocation } from 'react-router';

function Main({ prefix }) {
  const { t } = useTranslation();
  return <MDHelper source={t(prefix + '.content')} />;
}

export default function InfoPage({ infoKey, ...props }) {
  const { t } = useTranslation();
  const ns = new URLSearchParams(useLocation().search).get('msg');
  const prefix = 'info.' + infoKey + (ns && ns.length ? '.' + ns : '');
  return (
    <BasicLayout
      title={t(prefix + '.title')}
      topPadding
      main={<Main prefix={prefix} />}
    />
  );
}
