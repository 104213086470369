// @flow
import type { Node } from 'react';
import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useMemo,
} from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMessages } from '../utils/messages';
import { useTranslation } from 'react-i18next';
import { extractMobileInfo } from '../models/user';
import { useCallback } from 'react';

type UserID = string | Number;
type UserType = Object;
type TokenType = string | Object;
type CodeType = string | Object;

type AuthConfig = {
  userDataURL: string,
  loginURL: string,
  tokenURL: string,
};

type AuthState = {
  authenticated: boolean,
  processing: boolean,
  user: UserType,
  userId: ?UserID,
  token: ?TokenType,
  code: ?CodeType,
  login: (id: UserID, user: UserType) => void,
  authenticate: (token: TokenType) => void,
  resolveTokenFromCode: (code: CodeType) => void,
  logout: (next: ?string) => void,
  loginPath: ?string,
  error: ?any,
};

type ContextState = {
  id: ?UserID,
  user: ?UserType,
  token: ?TokenType,
  processing: boolean,
  config: AuthConfig,
  error: ?Error,
  code: CodeType,
};

type ContextValue = {
  state: ContextState,
  dispatch: (action: any) => void,
};

const DEFAULT_STATE = {
  user: null,
  token: null,
  code: null,
  id: null,
  processing: false,
  error: null,
};

const AuthContext = createContext<ContextValue>({
  state: { config: {}, ...DEFAULT_STATE },
  dispatch: (action: any) => undefined,
});

function readSession(): any | ContextState {
  let s = DEFAULT_STATE;
  try {
    if (window.localStorage.getItem('auth-session')) {
      s = JSON.parse(window.localStorage.getItem('auth-session'));

      // only recover user related state
      return {
        token: s.token,
        authenticated: s.authenticated,
        user: s.user,
        id: s.id,
      };
    }
  } catch (err) {
    console.error(err);
  }
  return s;
}

function updateSession(state: ContextState): ContextState {
  let s = '{}';
  try {
    s = JSON.stringify(state);
  } catch (err) {
    console.error(err);
  }
  window.localStorage.setItem('auth-session', s);
  return state;
}

const authReducer = (state: ContextState, action) => {
  const update = updateSession;
  switch (action.type) {
    case 'login':
      return update({
        ...state,
        user: action.user,
        id: action.id,
        processing: false,
        error: null,
        code: null,
      });
    case 'code':
      return update({
        ...state,
        code: action.code,
        token: null,
        processing: false,
        error: null,
      });
    case 'token':
      return update({
        ...state,
        token: action.token,
        processing: false,
        error: null,
        code: null,
      });
    case 'logout':
      return update({
        ...state,
        user: null,
        token: null,
        id: null,
        processing: false,
        code: null,
        error: null,
      });
    case 'authenticating':
      return update({ ...state, processing: true, error: null });
    case 'authenticated':
      return update({ ...state, processing: false, error: null });
    case 'error':
      return update({
        ...state,
        code: null,
        processing: false,
        error: action.error,
        user: null,
        token: null,
        id: false,
      });
    default:
      console.error(action);
      throw Error('Invalid Auth action.');
  }
};

export function AuthProvider({
  token = null,
  config = {},
  children,
}: {
  token: ?string,
  children: Node,
  config: AuthConfig,
}) {
  const [state, dispatch] = useReducer(
    authReducer,
    { ...DEFAULT_STATE, config },
    state => {
      return { ...state, ...readSession() };
    }
  );
  const data = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}

export async function getProfile(url, token) {
  try {
    const data = await window.fetch(url, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (data.status === 404 || data.status === 401) {
      return [null, data.status];
    } else {
      const user = await data.json();
      if (!user.userDisplayName) {
        if (user.firstname && user.surname) {
          user.userDisplayName = `${user.firstname} ${user.surname}`;
        } else if (user.email) {
          user.userDisplayName = user.email;
        } else if (user.username) {
          user.userDisplayName = user.username;
        } else {
          user.userDisplayName = user.id;
        }
      }
      return [user, null];
    }
  } catch(err) {
    return [null, err];
  }

}
export default function useAuth(): AuthState {
  const { state, dispatch } = useContext(AuthContext);

  const loginPath = state.config.loginURL || '/login';
  const navigate = useNavigate();

  function login(id: UserID, data: UserType) {
    dispatch({ type: 'login', user: data, id });
  }

  function logout(next: ?string) {
    navigate(next || '/', { replace: true });
    dispatch({ type: 'logout', next });
  }

  function authenticate(token: TokenType) {
    dispatch({ type: 'token', token });
  }

  function resolveTokenFromCode(code: CodeType) {
    dispatch({ type: 'code', code });
  }

  async function handleCode(state, dispatch) {
    const code = state.code;
    dispatch({ type: 'authenticating' });
    try {
      const resp = await window.fetch(state.config.tokenURL + '?code=' + code);
      const token = await resp.json();
      authenticate(token.token);
    } catch (err) {
      dispatch({ type: 'error', error: err });
    }
  }

  async function handleToken(state, dispatch) {
    if (state.token) {
      const token = state.token || '';
      if (state.config.userDataURL) {
        dispatch({ type: 'authenticating' });
        const [user, err] = await getProfile(state.config.userDataURL, token);
        if (err) {
          dispatch({ type: 'error', error: err });
        } else {
          login(user.id || token, user);
        }
      }
    } else {
      if (state.user) {
        logout();
      }
    }
  }

  useEffect(() => {
    if (!state.processing) {
      state.processing = true;
      handleToken(state, dispatch);
    }
  }, [state.token]);

  useEffect(() => {
    if (state.code && !state.processing) {
      // modify state outside the reducer to prevent other useAuth on the same update
      // from handling the same code
      state.processing = true;
      handleCode(state, dispatch);
    }
  }, [state.code, state.processing]);

  const refresh = useCallback(() => {
    handleToken(state, dispatch);
  }, [state, dispatch]);

  return {
    authenticated: !!state.user,
    processing: state.processing,
    user: state.user,
    userId: state.id,
    token: state.token,
    code: state.code,
    error: state.error,
    refresh,
    login,
    logout,
    authenticate,
    resolveTokenFromCode,
    loginPath,
    config: state.config,
  };
}

export function useToken(): TokenType {
  return useAuth().token;
}

export function useUser(): UserType {
  return useAuth().user;
}

export function useRequireUser(skipConsent: boolean = false): UserType {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const mobile = useMemo(() => {
    return extractMobileInfo(auth.user || {});
  }, [auth.user]);

  useEffect(() => {
    if (!auth.token || (!auth.processing && !auth.authenticated)) {
      const next = location.pathname + location.search;
      const path = '/login';
      let newpath = path + '?next=' + next + '&';
      if (next.startsWith('/dashboard')) {
        newpath = newpath + 'admin=1';
      }
      navigate(newpath, { replace: true });
    }
  }, [auth.token, auth.authenticated, auth.processing]);

  useEffect(() => {
    const next = location.pathname + location.search;
    if (!skipConsent && mobile.ask && auth.authenticated) {
      navigate('/consent?next=' + next, { replace: true });
    }
  }, [skipConsent, mobile.ask, auth.authenticated]);

  return useMemo(() => {
    return auth.user || {};
  }, [auth.user]);
}

export function useRequireSimpleUser(): UserType {
  const user = useRequireUser();
  const auth = useAuth();
  const navigate = useNavigate();
  const showMessage = useMessages();
  const { t } = useTranslation();
  useEffect(() => {
    if (
      auth.authenticated &&
      user &&
      user.is_admin
    ) {
      // TODO: show permission denied notification
      showMessage(t('permission_denied'), 'warning');
      navigate('/', { replace: true });
    }
  }, [user]);
  return user || {};
}

export function useRequireAdminUser(): UserType {
  const user = useRequireUser();
  const auth = useAuth();
  const navigate = useNavigate();
  const showMessage = useMessages();
  const { t } = useTranslation();
  useEffect(() => {
    if (
      auth.authenticated &&
      user &&
      !user.is_admin 
    ) {
      // TODO: show permission denied notification
      showMessage(t('permission_denied'), 'warning');
      navigate('/', { replace: true });
    }
  }, [user]);
  return user || {};
}

export function TokenLogin() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = qs.parse(location.search.slice(1));
  const next =
    params.next || window.localStorage.getItem('dilosi-login-next') || '/';

  useEffect(() => {
    const hash = location.hash || '#';
    const code = hash.slice(1);
    if (!code) {
      navigate('/', { replace: true });
    } else {
      auth.resolveTokenFromCode(code);
    }
  }, [location.hash]);

  useEffect(() => {
    if (auth.authenticated) {
      window.localStorage.removeItem('dilosi-login-next');
      navigate(next || '/', { replace: true });
    }
    if (auth.error) {
      console.error(auth.error);
      window.localStorage.removeItem('dilosi-login-next');
      navigate('/', { replace: true });
    }
  }, [auth.authenticated, auth.error, auth.code]);
  return [];
}
