import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useParams, useNavigate } from 'react-router-dom';
import Top from '../components/Top';
import { Paragraph } from '../components/Typography';
import RadioGroup from '../components/RadioGroup';
import { ContinueButton } from '../components/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  denseTop: {
    marginTop: theme.spacing(4),
  },
  expanded: {
    padding: theme.spacing(3),
    marginTop: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
    borderLeft: `4px dotted ${theme.palette.primary.light}`,
  },
  dense: {
    paddingTop: theme.spacing(1),
  },
}));

export default function Question(props) {
  const classes = useStyles();
  const { selectedAnswer } = useParams();
  const [currentAnswer, setCurrentAnswer] = useState('');
  const answerIsNested = useMemo(() => {
    const currentQuestion = props.questions.filter(
      q => q.value === currentAnswer
    )[0];
    return currentQuestion ? !!currentQuestion.expandable : false;
  }, [currentAnswer]);
  const navigate = useNavigate();
  const { expanded, expandable, dense } = props;
  const { t } = useTranslation();
  const handleChange = (event, question) => {
    const { value } = event.currentTarget;
    if (question.expandable) {
      navigate(`/cause-of-use/${value}`);
    } else if (!expanded) {
      navigate('/cause-of-use');
    }
    setCurrentAnswer(value);
  };

  return (
    <div className={clsx(expanded && classes.expanded, dense && classes.dense)}>
      <Top
        captionLeft={props.captionLeft}
        captionRight={props.captionRight}
        hasBackButton={!expanded}
        expanded={props.expanded}
        className={dense && classes.denseTop}
        dense={dense}
        title={t(props.title)}
        size={expanded ? 'sm' : 'xl'}
      />
      {props.caption && <Paragraph>{props.caption}</Paragraph>}
      <RadioGroup
        dense={dense}
        value={currentAnswer || selectedAnswer}
        expandable={expandable}
        onChange={handleChange}
        shrunk={!expanded}
        options={props.questions}
      />
      <ContinueButton
        to={currentAnswer}
        disabled={answerIsNested || currentAnswer === ''}
      />
    </div>
  );
}

Question.propTypes = {
  dense: PropTypes.bool,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  title: PropTypes.string,
  caption: PropTypes.string,
  captionLeft: PropTypes.string,
  captionRight: PropTypes.string,
  questions: PropTypes.array.isRequired,
};
Question.defaultProps = {
  expanded: false,
  expandable: false,
};
