import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import BasicLayout from '../../layouts/BasicLayout';
import { ContinueButton } from '../../components/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Paragraph } from '../../components/Typography';
import user from '../../data/user';
import { useNavigate, useParams } from 'react-router-dom';
import { useResource } from '../../lib/api';
import { useRequireUser } from '../../lib/auth';
import {
  useContactFormFields,
  useUpdateDeclaration,
} from '../../models/declaration';
import { FormBuilder, Fieldset, Field } from '../../lib/form';
import Quote from '../../components/Quote';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
}));

const Main = ({ classes, t }) => {
  useRequireUser();
  const navigate = useNavigate();
  const { id } = useParams();
  const declaration = useResource('my-declarations', id);
  const [fields, initial] = useContactFormFields(declaration.data);
  const [formData, setFormData] = useState(null);
  const update = useUpdateDeclaration(id, formData);

  // form data received, form has been submitted, initiate update request
  useEffect(() => {
    if (update.dataSet) {
      update.fetch();
    }
  }, [update.dataSet]);

  useEffect(() => {
    if (update.loaded && !update.error) {
      navigate('/dilosi/' + id + '/text');
    }
  }, [update.loaded]);

  return (
    <Fragment>
      <Helmet>
        <title>{t('title.window_title')}</title>
      </Helmet>
      <Paragraph>{t('form_editable.contact_text')}</Paragraph>
      <FormBuilder
        fields={fields}
        initial={initial}
        onSubmit={data => {
          setFormData(data);
        }}
      >
        <Fieldset>
          {fields.map(f => (
            <Field name={f.key} key={f.key} />
          ))}
        </Fieldset>
        <Box className={classes.buttonContainer}>
          <ContinueButton type="submit" />
        </Box>
      </FormBuilder>
    </Fragment>
  );
};
Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default function FormEditable(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BasicLayout
      title={t('form_editable.contact_title')}
      captionLeft={t('form_editable.caption_left')}
      hasBackButton
      isLoggedIn={true}
      name={`${user.firstName} ${user.lastName}`}
      main={<Main classes={classes} t={t} />}
    />
  );
}
