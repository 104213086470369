import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Title from '../../components/Title';
import { Paragraph } from 'components/Typography';
import { useRequireAdminUser } from 'lib/auth';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  icons: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  paragraph: {
    padding: theme.spacing(2),
  },
}));

export default function TemplatesList() {
  const classes = useStyles();
  const admin = useRequireAdminUser();
  const manageableEntities = useMemo(() => {
    return Object.keys(admin.entities || []).map(id => {
      return {
        id,
        ...admin.entities[id],
      };
    });
  }, [admin.entities]);
  return (
    <>
      <Title size="lg">Οι Θυρίδες μου</Title>
      {manageableEntities.length ? (
        <TableContainer component={Paper} elevation={0} square>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Όνομα</TableCell>
                <TableCell>Χειριστής</TableCell>
                <TableCell>Διαχειριστής</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manageableEntities.map(row => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    <Link to={`/dashboard/entities/show/${row.id}/`}>
                      {row.entity}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {row.roles.includes('clerk') ? (
                      <CheckIcon className={classes.icons} />
                    ) : (
                      <ClearIcon className={classes.icons} />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.roles.includes('head') ? (
                      <CheckIcon className={classes.icons} />
                    ) : (
                      <ClearIcon className={classes.icons} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <div className={classes.container}>
            <Paragraph className={classes.paragraph}>
              Δεν έχετε ακόμα πρόσβαση σε κάποια θυρίδα.
            </Paragraph>
          </div>
          <div>
            {' '}
            <div>
              <Link
                to={`../../entities/requests/list`}
                className={classes.paragraph}
              >
                Aιτηθείτε νέα πρόσβαση σε θυρίδα
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}
