import React, { Fragment, Suspense, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Top from '../../components/Top';
import Button from '../../components/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Title, Paragraph } from '../../components/Typography';
import { useMobileConsent, useMobile } from '../../models/user';
import { useMessages } from '../../utils/messages';
import { FormBuilder, Field } from '../../lib/form';
import BasicLayout from '../../layouts/BasicLayout';
import useAuth from '../../lib/auth';
import Quote from '../../components/Quote';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  paragraph: {
    paddingBottom: theme.spacing(2),
  },
  fieldLabel: {
    fontWeight: 500,
  },
  fieldLabelWrapper: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
}));

const Main = ({ classes, t, ...props }) => {
  const query = new URLSearchParams(useLocation().search);
  const next = query.get('next') || '/';
  const showMessage = useMessages();
  const navigate = useNavigate();

  const [mobileError, setMobileError] = useState();

  function handleError(errorObject) {
    let msg = 'Παρουσιάστηκε σφάλμα';
    if (errorObject && errorObject.error && errorObject.error.message) {
      msg = 'api.' + errorObject.error.message;
    }
    if (msg === 'api.attribute.not.validated') {
      msg = 'mobile_consent.error';
      setMobileError({ message: t(msg) });
      return;
    }
    showMessage(t(msg), 'error');
  }

  const mobile = useMobile();
  const { refresh } = useAuth();
  const askMobileCertified = mobile.askMobileCertified ?? true;
  const askMobileUseNNCOnboarding = mobile.askMobileUseNNCOnboarding ?? true;
  const mobileCertifiedDisplay = mobile.mobileCertifiedDisplay;
  const [mobileCheckCertified, setMobileCheckCertified] = useState();
  const [mobileUseNNCOnboarding, setMobileUseNNCOnboarding] = useState();
  const msgVars = useMemo(
    () => ({
      mobile: mobileCertifiedDisplay,
    }),
    [mobileCertifiedDisplay]
  );

  const initial = useMemo(() => {
    return {
      mobile: mobile.mobileDeclared,
    };
  });

  const options = useMemo(() => {
    return [
      {
        text: {
          primary: t('mobile_consent.options.yes.title'),
          secondary: t('mobile_consent.options.yes.text', msgVars),
        },
        value: 'yes',
      },
      {
        text: {
          primary: 'mobile_consent.options.no.title',
          secondary: 'mobile_consent.options.no.text',
        },
        value: 'no',
      },
    ];
  }, []);

  const fields = useMemo(() => {
    const hint = (
      <div style={{marginBottom: '12px'}}> 
        <div style={{marginBottom: '12px'}}>{t('mobile_consent.consent.help', msgVars)}</div>
        <Quote>{t('mobile_consent.consent.spoiler', msgVars)}</Quote>
      </div>
    )
    const mobileField = {
      key: 'mobile',
      name: 'mobile',
      type: 'mobile_phone',
      required: askMobileCertified,
      placeholder: mobile.mobileCertifiedDisplay,

      label: t('mobile_consent.mobile.label', msgVars),
      hint_md: t('mobile_consent.mobile.help', msgVars),
    };
    const consentField = {
      key: 'consent',
      name: 'consent',
      type: 'buttonsGroup',
      required: askMobileUseNNCOnboarding,
      label: t('mobile_consent.consent.label', msgVars),
      hint: hint,
      options,
    };
    const fields = [];
    if (mobile.askMobileCertified) {
      fields.push(mobileField);
    }
    if (mobile.askMobileUseNNCOnboarding) {
      fields.push(consentField);
    }
    return fields;
  }, [mobile]);

  useEffect(() => {
    if (!mobile.ask) {
      navigate(next, { replace: true });
    }
  }, [mobile.ask]);

  function doConsent(data) {
    setMobileCheckCertified(data.mobile);
    setMobileUseNNCOnboarding(data.consent === 'yes' || false);
    consent();
  }

  // proxy mobile state to question input via MobileContext :omg:
  const { consent, consentLoading } = useMobileConsent(
    mobileCheckCertified,
    mobileUseNNCOnboarding,
    () => {
      // consent process completed
      // continue with declaration creation, once created user will be
      // redirected to the next view
      // TODO: reload user profile
      refresh();
    },
    handleError
  );

  return (
    <Fragment>
      <Helmet>
        <title>{t('title.window_title')}</title>
      </Helmet>
      <Paragraph
        style={{ marginBottom: '24px' }}
        i18n={false}
        className={classes.paragraph}
      >
        {t(`mobile_consent.intro_text`)}
      </Paragraph>

      <FormBuilder initial={initial} fields={fields} onSubmit={doConsent}>
        {fields.map(f => (
          <Field
            key={f.key}
            name={f.name}
            error={f.key === 'mobile' ? mobileError : undefined}
            onKeyPress={() => {
              setMobileError(null);
            }}
          />
        ))}

        <Box className={classes.buttonContainer}>
          <Button type="submit" disabled={consentLoading}>
            {t('button.continue')}
          </Button>
        </Box>
      </FormBuilder>
    </Fragment>
  );
};

Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
  captionRight: PropTypes.string,
  captionLeft: PropTypes.string,
  title: PropTypes.string,
};

const Side = ({ t }) => (
  <div
    style={{
      paddingLeft: 6,
      paddingRight: 6,
    }}
  >
    <Title size="sm">{t('form_taxisnet.change_contact_title')}</Title>
    <Paragraph>{t('form_taxisnet.chanage_contact_text')}</Paragraph>
  </div>
);

Side.propTypes = {
  t: PropTypes.func.isRequired,
};

export function Page(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <Main classes={classes} t={t} {...props} />;
}

export default function MobileConsent(props) {
  const { t } = useTranslation();
  return (
    <BasicLayout
      main={<Page />}
      topPadding
      title={t('mobile_consent.title')}
      isLoggedIn={true}
    />
  );
}
