import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BasicLayout from '../../layouts/BasicLayout';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Top from '../Top';
import RadioGroup from '../RadioGroup';
import { ContinueButton } from '../Button';
import { Paragraph } from '../Typography';
import FieldsBuilder from './FieldsBuilder';

import NotFound from '../../pages/NotFound';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const QuestionsBuilder = props => {
  const classes = useStyles();
  const { currentQuestion } = props;
  const [serializedData, setSerializedData] = useState({});
  const handleDataChange = event => {
    setSerializedData({
      ...serializedData,
      [event.target.name]: event.target.value,
    });
  };
  const handleTextChange = text => {
    setSerializedData({
      ...serializedData,
      text,
    });
  };
  const handleFieldsChange = fields => {
    setSerializedData({
      ...serializedData,
      fields,
    });
  };

  return (
    <div className={classes.root}>
      {currentQuestion.component ? (
        <currentQuestion.component {...currentQuestion} />
      ) : (
        <>
          <Top
            captionLeft={currentQuestion.captionLeft}
            hasBackButton
            title={currentQuestion.title}
            size="xl"
          />
          {currentQuestion.caption && (
            <Paragraph>{currentQuestion.caption}</Paragraph>
          )}
          {currentQuestion.options && (
            <RadioGroup
              {...currentQuestion}
              value={serializedData[currentQuestion.name]}
              onChange={handleDataChange}
            />
          )}
          {currentQuestion.inputs &&
            currentQuestion.inputs.map((input, index) => (
              <TextField
                key={index}
                onChange={handleDataChange}
                className={classes.input}
                variant="outlined"
                fullWidth
                {...input}
              />
            ))}

          {currentQuestion.name === 'fields' && (
            <FieldsBuilder
              serializedData={serializedData}
              onChange={handleFieldsChange}
            />
          )}
          {currentQuestion.children && (
            <currentQuestion.children
              serializedData={serializedData}
              onChange={handleTextChange}
            />
          )}
          {currentQuestion.then && (
            <ContinueButton
              disabled={currentQuestion.shouldProceed(serializedData)}
              to={currentQuestion.then(serializedData)}
            />
          )}
        </>
      )}
    </div>
  );
};
QuestionsBuilder.propTypes = {
  currentQuestion: PropTypes.object,
  questions: PropTypes.object,
  then: PropTypes.func,
  shouldProceed: PropTypes.func,
};
export default function QuestionsBuilders(props) {
  const { qid } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState({});
  useEffect(() => {
    setCurrentQuestion(props.questions.find(question => question.qid === qid));
  }, [qid]);
  const Layout = currentQuestion && (currentQuestion.layout || BasicLayout);
  return (
    <>
      {currentQuestion ? (
        <Layout
          main={
            <QuestionsBuilder
              currentQuestion={currentQuestion}
              questions={props.questions}
            />
          }
          topPadding
          isLoggedIn={false}
        />
      ) : (
        <NotFound />
      )}
    </>
  );
}

QuestionsBuilders.propTypes = {
  questions: PropTypes.object,
  find: PropTypes.func,
};
