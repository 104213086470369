import { useMemo, useEffect } from 'react';
import { useResourceAction } from '../../lib/api';

export function useInboxAction(props, myActionType, data) {
  const myActionData = useMemo(() => data || {}, [data]);
  const url = props.isAdmin
    ? `entity/${props.inboxItem.entity}/inbox/${props.inboxItem.id}/${myActionType}`
    : `my-inbox/${props.inboxItem.id}/${myActionType}`;
  const myAction = useResourceAction(url, undefined, 'POST', myActionData);

  useEffect(() => {
    if (myAction.error) {
      alert(myAction.error);
    }
  }, [myAction.error]);

  return myAction;
}
