import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Loader from '../../../components/Loader';
import Header from '../components/Header';
import { useRequireAdminUser } from '../../../lib/auth';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
    paddingBottom: '155px', // this is the height of the footer
  },
  toolbar: theme.mixins.toolbar,
  sidebar: {
    marginLeft: '-12px',
    borderRight: '1px solid #efefef',
    background: '#fff',
    padding: '24px 0 48px 0 !important',
  }
}));

export default function DashboardLayout(props) {
  const classes = useStyles();
  const user = useRequireAdminUser();
  const entity = user.entities && Object.getOwnPropertyNames(user.entities)[0];
  return (
    <div className={classes.root}>
      <Header />
      <Suspense fallback={<Loader />}>
        <Container maxWidth={1280}>
          <div className={classes.toolbar} />
          <Grid container xs={12} spacing={4} style={{height: '100%'}}>
            <Grid item sm={3} className={classes.sidebar}>
              {props.sidebar}
            </Grid>
            <Grid item sm={9}>
              {props.children}
            </Grid>
          </Grid>
        </Container>
      </Suspense>
    </div>
  );
}

DashboardLayout.propTypes = {
  sidebar: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
