import React, { Fragment, Suspense, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import KeyValueList from '../../layouts/KeyValueList';
import Top from '../../components/Top';
import Button from '../../components/Button';
import Spoiler from '../../components/Spoiler';
import Quote from '../../components/Quote';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Title, Paragraph } from '../../components/Typography';
import { usePersonalDetails, useMobile } from '../../models/user';
import { useResource } from '../../lib/api';
import { useMessages } from '../../utils/messages';
import { useCreateDeclaration } from '../../models/declaration';
import useAuth, { useRequireUser } from '../../lib/auth';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  paragraph: {
    paddingBottom: theme.spacing(2),
  },
}));

const MobileClaimError = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  function handleClick() {
    logout();
    window.localStorage.setItem('dilosi-login-next', location.pathname + location.search);
    window.location = '/api/login';
  }
  return (
    <Quote error>
      <Title size="small">{t('info.no-mobile.title')}</Title>
      <Paragraph>{t('mobile_claim_error.warning')}</Paragraph>
      <Paragraph>{t('mobile_claim_error.text')}</Paragraph>
      <Button size="small" onClick={handleClick}>{t('mobile_claim_error.link')}</Button>
    </Quote>
  );
}
const Main = ({ classes, t, ...props }) => {
  useRequireUser();
  const query = new URLSearchParams(useLocation().search);
  const tplRef = query.get('refname');
  const template = useResource('templates', tplRef);
  const showMessage = useMessages();
  const profileData = usePersonalDetails();
  const mobile = useMobile();
  const [mobileClaimError, setMobileClaimError] = useState(false);

  const navigate = useNavigate();
  const { create, loading } = useCreateDeclaration(
    template.data,
    data => {
      navigate('/dilosi/' + data.id + '/edit');
    },
    error => {
      let msg = 'Παρουσιάστηκε σφάλμα';
      if (error && error.error && error.error.message) {
        msg = 'api.' + error.error.message;
      }
      if (msg === 'api.missing.required.claims') {
        setMobileClaimError(true);
      } else {
        setMobileClaimError(false);
        showMessage(t(msg), 'error');
      }
    }
  );

  const templateType = template.data.template_type.refname;
  const content = templateType === 'solemn_declaration' ? 'decl' : 'auth';

  const onClick = useCallback(() => {
    create();
  }, [mobile]);

  return (
    <Fragment>
      <Helmet>
        <title>{t('title.window_title')}</title>
      </Helmet>
      <Top
        captionLeft={props.captionLeft}
        captionRight={props.captionRight}
        hasBackButton
        title={props.title}
        size="xl"
      />

      <Paragraph i18n={false} className={classes.paragraph}>
        {t(`form_taxisnet.${content}_template_text`, { ...template.data })}
      </Paragraph>
      <Paragraph className={classes.paragraph}>
        {t(`form_taxisnet.${content}_contact_text`)}
      </Paragraph>

      <KeyValueList data={profileData} />
      <Spoiler
        label={t('form_editable.wrong_details.label')}
        explanation={
          <>
            {t('form_editable.wrong_details.explanation')}{' '}
            <a href="https://www1.gsis.gr/taxisnet/mytaxisnet">TaxisNet</a>
          </>
        }
      />
      {mobileClaimError && <MobileClaimError />}
      <Box className={classes.buttonContainer}>
        <Button disabled={loading || mobileClaimError} onClick={onClick}>
          Επιβεβαίωση
        </Button>
      </Box>
    </Fragment>
  );
};
Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
  captionRight: PropTypes.string,
  captionLeft: PropTypes.string,
  title: PropTypes.string,
};

const Side = ({ t }) => (
  <div
    style={{
      paddingLeft: 6,
      paddingRight: 6,
    }}
  >
    <Title size="sm">{t('form_taxisnet.change_contact_title')}</Title>
    <Paragraph>{t('form_taxisnet.chanage_contact_text')}</Paragraph>
  </div>
);

Side.propTypes = {
  t: PropTypes.func.isRequired,
};

export function Page(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <Main classes={classes} t={t} {...props} />;
}
export default function FormTaxisNet(props) {
  return (
    <Suspense fallback={<div />}>
      <Page {...props} />
    </Suspense>
  );
}
