export default function useQuestions() {
  return [
    {
      qid: 'start',
      name: 'template_type',
      title: 'cause_of_use.title',
      captionLeft: 'question_layout.captionLeft',
      options: [
        {
          text: {
            primary: 'cause_of_use.create.title',
            secondary: 'cause_of_use.create.subtitle',
          },
          value: '/login',
        },
        // {
        //   text: {
        //     primary: 'Δημιουργία νέας μεταβίβασης',
        //     secondary: '',
        //   },
        //   value: '/show/q/validate',
        // },
        // {
        //   text: {
        //     primary: 'cause_of_use.show_inbox.title',
        //     secondary: 'cause_of_use.show_inbox.subtitle',
        //   },
        //   value: '/vault',
        // },
      ],
      shouldProceed: serializedData => {
        return !serializedData.template_type;
      },
      then: serializedData => {
        return serializedData.template_type;
      },
    },
  ];
}
