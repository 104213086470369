import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useAuth from '../lib/auth';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';

const ITEM_HEIGHT = 48;

export default function LongMenu({ user, classes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth();
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            padding: '4px',
          },
        }}
      >
        <Hidden mdUp>
          <MenuItem styles={{ backgroundColor: '#000' }}>
            <b>{user.userDisplayName}</b>
          </MenuItem>
          <Divider component="li" />
        </Hidden>

        <MenuItem
          onClick={() => {
            logout('/');
          }}
        >
          {t('logout.exit')}
        </MenuItem>
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  userDisplayName: PropTypes.string,
};
