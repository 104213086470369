import { useMemo } from 'react';
import { useResourceAction, useResourceMany } from '../../../lib/api';

export function useNewRequest(data) {
  const requestData = useMemo(() => data, [data]);
  const request = useResourceAction(
    `my-permission-requests`,
    undefined,
    'POST',
    requestData
  );
  return request;
}
