import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import logo from './coa.svg';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/ButtonBase';
import { Title, NormalText } from '../../../components/Typography/';
import useAuth, { useUser } from '../../../lib/auth';
import UserActions from '../../../components/UserActions';

const useStyles = makeStyles(theme => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.black,
  },
  container: {
    padding: theme.spacing(0, 2),
  },
  pill: {
    backgroundColor: theme.palette.primary.light,
    margin: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  coloredBar: {
    height: 0,
    borderTop: `8px solid ${theme.palette.primary.light}`,
    position: 'absolite',
    marginBottom: -theme.spacing(1),
  },
  coloredBarContainer: {
    padding: theme.spacing(0, 6),
  },
  '@media (max-width: 767px)': {
    coloredBarContainer: {
      padding: theme.spacing(0, 2),
    },
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  title: {
    lineHeight: '2em',
    marginBottom: 0,
    marginRight: theme.spacing(2),
  },
  subtitle: {
    marginBottom: 0,
  },
  '@media screen and (min-width: 864px)': {
    subtitle: {
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
    },
  },
  user: {
    flexGrow: 1,
    textAlign: 'right',
  },
  main: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { authenticated } = useAuth();
  const user = useUser();

  return (
    <Fragment>
      <MuiAppBar className={classes.appbar}>
        <Toolbar variant="dense">
          <Grid container spacing={1}>
            <Grid item className={classes.main}>
              <img src={logo} alt="Ethnosimo" className={classes.logo} />
              <Title size="sm" className={classes.title}>
                GOV.GR
              </Title>
              <MuiButton disableRipple component={Link} to="/">
                <Title size="sm" className={classes.subtitle} noWrap>
                  Δηλώσεις
                </Title>
              </MuiButton>
              <span className={classes.pill}>Διαχειριστικό</span>
            </Grid>
            {authenticated ? (
              <Fragment>
                <Grid item className={classes.userContainer}>
                  <NormalText className={classes.user} noWrap>
                    Έχετε συνδεθεί ως {user.userDisplayName}
                  </NormalText>
                  <UserActions user={user} />
                </Grid>
              </Fragment>
            ) : (
              ''
            )}
          </Grid>
        </Toolbar>
      </MuiAppBar>
    </Fragment>
  );
}
