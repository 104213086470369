import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  panelContainer: {
    textAlign: 'center',
    backgroundColor: '#00703c',
    color: 'white',
    padding: theme.spacing(4, 2),
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  rightItem: {
    backgroundColor: '#00703c',
    color: 'white',
    padding: 0,
    fontWeight: 500,
    flex: 'none',
  },
}));

export default function Panel(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.panelContainer}>
      {props.children}
    </Grid>
  );
}
Panel.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};
