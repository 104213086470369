import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Title } from '../../../components/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dashboardTitle: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  noMargin: {
    margin: 0,
  },
}));
export default function DashboardTitle({ children, ...props }) {
  const classes = useStyles();
  return (
    <Title
      {...props}
      className={clsx(
        props.className,
        props.noMargin && classes.noMargin,
        classes.dashboardTitle
      )}
    >
      {children}
    </Title>
  );
}

DashboardTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.any,
  noMargin: PropTypes.any,
};
