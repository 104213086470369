import React from 'react';
import KeyValueList from '../../layouts/KeyValueList';
import { Title } from '../../components/Typography';
import { useRequireUser } from '../../lib/auth';
import { usePersonalDetails } from '../../models/user';

export default function PersonalDetails() {
  useRequireUser();
  const profileData = usePersonalDetails();
  return (
    <>
      <Title size="xl">vault.personal-details.title</Title>
      <KeyValueList data={profileData} />
    </>
  );
}
