import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EditDeclarationPage from './EditDeclarationPage';
import TextDeclarationPage from './TextDeclarationPage';
import PreviewDeclarationPage from './PreviewDeclarationPage';
import RecipientPage from './RecipientPage';
import CustomRecipientPage from './CustomRecipientPage';
import SMSConfirmationPage from './SMSConfirmationPage';
import FinalDeclarationPage from './FinalDeclarationPage';
import NotFound from '../NotFound';

export default function ShowRouter() {
  return (
    <Routes>
      <Route path="/:id/edit" element={<EditDeclarationPage />} />
      <Route path="/:id/text" element={<TextDeclarationPage />} />
      <Route path="/:id/recipient" exact element={<RecipientPage />} />
      <Route
        exact
        path="/:id/recipient/custom"
        element={<CustomRecipientPage />}
      />
      <Route path="/:id/preview" element={<PreviewDeclarationPage />} />
      <Route path="/:id/sms" element={<SMSConfirmationPage />} />
      <Route path="/:ref/done" element={<FinalDeclarationPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
