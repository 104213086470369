import React from 'react';
import BaseText from './BaseText';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => {
  return {
    paragraph: {
      // here be thy styles
      margin: theme.spacing(0, 0, 2),
    },
  };
});

export default function Paragraph(props) {
  const classes = useStyles();
  return (
    <Typography className={classes.paragraph} variant="body1" {...props}>
      <BaseText {...props} />
    </Typography>
  );
}
Paragraph.propTypes = {
  i18n: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
