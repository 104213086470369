// @flow
import React, { useMemo } from 'react';
import HighLight from '../components/HighLight';

type Token = {
  value: string,
  type: 'text' | 'var',
};

type Tokens = Array<Token>;

/*
 * With the function tokenize we extract text that is in brackets inside a
 * bigger text. Example:
 *
 * Input: "This is an {example}..."
 * Output:
 * [
 *  {
 *     value="This is an ",
 *     type="text"
 *  },
 *  {
 *     value="example",
 *     type="var"
 *  },
 *  {
 *     value="...",
 *     type="text"
 *  }
 * ]
 */

export function tokenize(s: string): Tokens {
  const tokens = [];
  let mem = '';
  const characters = s.split('');

  characters.forEach((char, i) => {
    if (char === '\n') {
      tokens.push({ type: 'text', value: mem });
      tokens.push({ value: '\n', type: 'new_line' });
      mem = '';
    } else if (char === '}') {
      // keep text that is inside brackets in an object with type "var"
      tokens.push({ type: 'var', value: mem });
      mem = '';
    } else if (char === '{' || i === characters.length - 1) {
      // keep text that is outside brackets in an object with type "text"
      tokens.push({ type: 'text', value: mem });
      mem = '';
    } else {
      mem += char;
    }
  });
  return tokens;
}

type Field = {
  editable: boolean,
  key: string,
  hint: string,
  label: string,
  source: string,
  type: string,
};

type TextToDOMProps = {
  text: string,
  fields: [Field],
  focused: Array<string>,
};

type El = React.DOM;

export function TextToDOM({ text, fields, focused = [] }: TextToDOMProps): El {
  const data = tokenize(text).map(token => {
    let value = token.value;
    const key = token.value;
    const field = fields.find(field => token.value === field.key);

    if (token.type === 'new_line') {
      return <br />;
    }
    if (token.type === 'text') {
      return value;
    }
    if (field) {
      value = field.label || field.hint || value;
    }
    const focus = focused.includes(key);
    const typeHL = focus ? 'warning' : 'light';

    return <HighLight key={key} text={value} type={typeHL} />;
  });

  /*
   * data is an array. Each element in data array could be:
   *   - string
   *   - Highlight component
   */
  return <>{[data]}</>;
}

export function normalizeGreek(text) {
  text = text
    .replace(/Ά|Α|ά/g, 'α')
    .replace(/Έ|Ε|έ/g, 'ε')
    .replace(/Ή|Η|ή/g, 'η')
    .replace(/Ί|Ϊ|Ι|ί|ΐ|ϊ/g, 'ι')
    .replace(/Ό|Ο|ό/g, 'ο')
    .replace(/Ύ|Ϋ|Υ|ύ|ΰ|ϋ/g, 'υ')
    .replace(/Ώ|Ω|ώ/g, 'ω')
    .replace(/Σ|ς/g, 'σ');
  return text;
}

export function fullTextSearch(data: Object, search: ?string): boolean {
  if (!search) {
    return true;
  }
  const q = normalizeGreek(
    search
      .normalize('NFC')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  );
  const index = Object.keys(data)
    .map(k => {
      return data[k] ? data[k].toString().toLowerCase() : '';
    })
    .join('');

  return normalizeGreek(
    index.normalize('NFC').replace(/[\u0300-\u036f]/g, '')
  ).includes(q);
}

export function useSearchTemplates(
  templates: Array<Object>,
  search: ?string = null
) {
  return useMemo(() => {
    return templates.filter(t => fullTextSearch(t, search));
  }, [templates, search]);
}

export function useSuggested(initial) {
  return useMemo(() => {
    try {
      const data = JSON.parse(window.localStorage.getItem('dilosi-suggested'));
      const init = { ...initial };
      Object.keys(data).forEach(key => {
        if (init[key] === undefined) {
          init[key] = data[key];
        }
      });
      return init;
    } catch (err) {
      return initial;
    }
  }, [initial]);
}

export function clearSuggested() {
  window.localStorage.removeItem('dilosi-suggested');
}

export function storeSuggested(data) {
  try {
    const json = JSON.stringify(data);
    window.localStorage.setItem('dilosi-suggested', json);
    return data;
  } catch (err) {
    return null;
  }
}
