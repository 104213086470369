import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function BaseText({ children, i18n }) {
  const { t } = useTranslation();
  const content = i18n && isString(children) ? t(children) || children : children;
  return <>{content}</>;
}
BaseText.propTypes = {
  i18n: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
BaseText.defaultProps = {
  i18n: true,
};
