import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import Button from './Button';
import { Paragraph } from './Typography';
import { useResourceMany } from '../lib/api';
import {
  useSearchTemplates,
  TextToDOM,
  clearSuggested,
} from '../models/template';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  list: {
    margin: theme.spacing(2, 0),
  },
  card: {
    borderRadius: 0,
    border: '1px solid lightgrey',
    marginBottom: -1,
  },
  cardTitle: {
    padding: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  cardHeader: {},
  cardHeaderExpanded: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  cardExpanded: {
    margin: theme.spacing(2, 0),
  },
  title: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  label: {
    marginRight: theme.spacing(1),
    display: 'inline',
    fontWeight: theme.typography.fontWeightMedium,
  },
  value: {
    display: 'inline',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  openInNew: {
    fontSize: 14,
    position: 'relative',
    bottom: 10,
    left: 5,
  },
  dummyText: {
    background: theme.palette.grey['300'],
    padding: theme.spacing(2),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));

const DotSeparator = () => ' • ';
const Text = props => <Typography variant="body2">{props.children}</Typography>;
Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const Label = ({ label, className }) => {
  return <>{label}: </>;
};
Label.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

const Value = ({ value }) => {
  return <>{value}</>;
};
Value.propTypes = {
  value: PropTypes.string,
};
const Item = ({ label, value }) => {
  const classes = useStyles();

  return (
    <span className={classes.item}>
      <Label label={label} />
      <Value value={value} />
    </span>
  );
};
Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const TemplatesListItem = ({ row }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { t } = useTranslation();

  const handleExpandClick = event => {
    event.preventDefault();
    setExpanded(!expanded);
  };
  const url = `/create/q/confirm-personal-info?refname=${row.refname}`;
  const windowTitle =
    row.templateType === 'solemn_declaration' ? 'decl' : 'auth';
  return (
    <Card
      className={clsx(classes.card, expanded && classes.cardExpanded)}
      key={row.code}
      data-testid="card"
    >
      <CardHeader
        onClick={handleExpandClick}
        style={{ cursor: 'pointer' }}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        className={clsx(
          classes.cardHeader,
          expanded && classes.cardHeaderExpanded
        )}
        titleTypographyProps={{ variant: 'body1' }}
        title={
          <>
            <Typography
              variant="h6"
              data-testid="TemplTitle"
              className={classes.title}
              style={{ cursor: 'pointer' }}
              onClick={handleExpandClick}
            >
              {row.shortname}
            </Typography>
            <div data-testid="TemplDetails">
              <Text>
                <Item label={t('templates.code')} value={row.refname} />
                <DotSeparator />
                <Item
                  label={t('templates.foreas')}
                  value={row.recipient_hint}
                />
              </Text>
            </div>
          </>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.template} data-testid="TemplContent">
          <Paragraph>{t('templates.text')}</Paragraph>
          <div className={classes.dummyText}>
            <TextToDOM fields={row.fields} text={row.text} />
          </div>
        </CardContent>
        <CardContent className={classes.actions}>
          <Button to={`${url}`} component={Link}>
            {t('button.select')}
          </Button>
        </CardContent>
      </Collapse>
    </Card>
  );
};
TemplatesListItem.propTypes = {
  row: PropTypes.object,
};

export default function TemplatesList({ search }) {
  useEffect(() => {
    clearSuggested();
  }, []);
  const query = new URLSearchParams(useLocation().search);
  const classes = useStyles();
  const templates = useResourceMany('templates', {
    type: query.get('template_type'),
  });
  const data = useSearchTemplates(templates.data, search);
  return (
    <div className={classes.list}>
      {data.map((row, index) => (
        <TemplatesListItem key={index} row={row} />
      ))}
    </div>
  );
}
TemplatesList.propTypes = {
  search: PropTypes.string,
};
