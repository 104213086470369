// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Box from '@material-ui/core/Box';

type Props = {
  label: string,
  Icon: React.DOM,
};

const useStyles = makeStyles(theme => ({
  textInButton: {
    whiteSpace: 'nowrap',
  },
}));

export default function ButtonIcon(props: Props) {
  const classes = useStyles();
  const { Icon, label } = props;
  return (
    <Button {...props}>
      <Box marginRight={1} display="flex">
        <Icon />
      </Box>
      <div className={classes.textInButton}>{label}</div>
    </Button>
  );
}
