import React, { Fragment, Suspense } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Top from '../components/Top';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ApiErrors } from '../lib/api';
import Loader from '../components/Loader';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(0, 6, 4),
    '@media (max-width: 767px)': {
      padding: theme.spacing(0, 2),
    },
  },
  sideContainer: {
    paddingTop: theme.spacing(6),
  },
  title: {
    margin: theme.spacing(6, 0),
  },
}));

export default function BasicLayout(props) {
  const classes = useStyles();
  const {
    hasBackButton,
    title,
    captionRight,
    captionLeft,
    main,
    side,
    printOnlyContent,
    noTop,
    topPadding,
    rtl,
    isLoggedIn,
    name,
  } = props;

  const displayPrint = printOnlyContent ? 'none' : 'flex';
  return (
    <>
      <Box display="flex" displayPrint={displayPrint} flex={1} flexGrow={0}>
        <Header isLoggedIn={isLoggedIn} name={name} />
      </Box>
      <Container className={classes.mainContainer}>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <Suspense fallback={<Loader />}>
              <Box display="flex" displayPrint={displayPrint}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {!noTop && (
                      <Top
                        size="xl"
                        className={topPadding && classes.title}
                        hasBackButton={hasBackButton}
                        title={title}
                        captionRight={captionRight}
                        captionLeft={captionLeft}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Suspense fallback={<Loader />}>
                {main}
              </Suspense>
            </Suspense>
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={clsx(topPadding && classes.sideContainer)}>
              {side}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
BasicLayout.propTypes = {
  hasBackButton: PropTypes.bool,
  backButtonDisabled: PropTypes.bool,
  title: PropTypes.string,
  captionRight: PropTypes.string,
  captionLeft: PropTypes.string,
  main: PropTypes.node.isRequired,
  side: PropTypes.node,
  printOnlyContent: PropTypes.bool,
  noTop: PropTypes.bool,
  topPadding: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  name: PropTypes.bool,
};
