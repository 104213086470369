import Templates from '../Templates';
import { useLocation } from 'react-router-dom';
import ConfirmPersonalInfo from './ConfirmPersonalInfo';

export default function useQuestions() {
  const query = new URLSearchParams(useLocation().search);
  const context = query.get('template_type');
  return [
    {
      name: 'templates',
      qid: 'templates',
      title: `templates_page.${context}.title`,
      captionLeft: 'question_layout.captionLeft',
      component: Templates,
    },
    {
      name: 'check_refname',
      qid: 'check_refname',
      title: 'create_page.check_refname.title',
      caption: 'create_page.check_refname.caption',
      captionLeft: 'question_layout.captionLeft',
      options: [
        {
          text: {
            primary: 'create_page.check_refname.yes.title',
            secondary: 'create_page.check_refname.yes.subtitle',
          },
          value: '/create/q/refname?template_type=solemn_declaration',
        },
        {
          text: {
            primary: 'create_page.check_refname.no.title',
            secondary: 'create_page.check_refname.no.subtitle',
          },
          value: '/create/q/template_type',
        },
      ],
      shouldProceed: serializedData => {
        return !serializedData.check_refname;
      },
      then: (location, serializedData) => {
        return serializedData.check_refname;
      },
    },
    {
      name: 'refname',
      qid: 'refname',
      title: 'create_page.refname.title',
      caption: 'create_page.refname.caption',
      inputs: [
        {
          name: 'refname',
          label: 'Refname',
          helperText: 'to refname einai to refname tou protupou epexigisi',
          autoFocus: true,
        },
      ],

      shouldProceed: serializedData => {
        return !serializedData.refname;
      },
      then: (location, serializedData) => {
        return `/create/q/confirm-personal-info?refname=${serializedData.refname}`;
      },
    },
    {
      name: 'template_type',
      qid: 'template_type',
      title: 'create_page.template_type.title',
      captionLeft: 'question_layout.captionLeft',
      options: [
        {
          text: {
            primary: 'create_page.template_type.solemn_declaration.title',
            secondary: 'create_page.template_type.solemn_declaration.subtitle',
          },
          value: '/create/q/templates?template_type=solemn_declaration',
        },
        {
          text: {
            primary: 'create_page.template_type.authorization.title',
            secondary: 'create_page.template_type.authorization.subtitle',
          },
          value: '/create/q/templates?template_type=authorization',
        },
        {
          text: {
            primary: 'create_page.template_type.dunno.title',
            secondary: 'create_page.template_type.dunno.subtitle',
          },
          value: '/create/q/templates',
        },
      ],
      shouldProceed: serializedData => {
        return !serializedData.template_type;
      },
      then: (location, serializedData) => {
        return serializedData.template_type;
      },
    },
    {
      name: 'template',
      qid: 'confirm-personal-info',
      title: 'form_taxisnet.contact_title',
      captionLeft: 'form_taxisnet.caption_left',
      component: ConfirmPersonalInfo,
    }
  ];
}
