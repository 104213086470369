import { useMemo, useEffect } from 'react';
import { useRequireUser } from '../lib/auth';
import { useResourceAction } from '../lib/api';

// return user data and parse verified values
export function useProfile(user) {
  const fields = Object.keys(user);
  const fieldsMap = {};
  return useMemo(() => {
    fields.forEach(f => {
      const field = { issuer: null, value: user[f] };
      let name = f;
      if (f.includes('/')) {
        const parts = f.split('/');
        field.issuer = parts[0];
        name = parts[1];
      }
      fieldsMap[name] = field;
    });
    return fieldsMap;
  }, [user]);
}

const VALID_PERSONAL_DETAILS_FIELDS = [
  'firstname',
  'surname',
  'afm',
  'birth_year',
  'tel',
  'mobile_certified_display',
  'mobile_consent_display',
  'birth_date',
  'email',
];

export function usePersonalDetails() {
  const user = useRequireUser();
  const profile = useProfile(user);
  return useMemo(() => {
    const data = {};
    Object.keys(profile).forEach(k => {
      if (!VALID_PERSONAL_DETAILS_FIELDS.includes(k)) {
        return;
      }
      data[k] = profile[k].value;
    });
    return data;
  }, [profile]);
}

export const useMobileConsent = (
  mobileCheckCertified,
  mobileUseNNCOnboarding,
  onSuccess,
  onError
) => {
  const data = useMemo(() => {
    const data = { consents: {} };
    if (mobileUseNNCOnboarding !== undefined) {
      data.consents.mobile_use_nnc_onboarding = mobileUseNNCOnboarding;
    }
    if (mobileCheckCertified !== undefined) {
      data.consents.mobile_check_certified = mobileCheckCertified;
    }
    return data;
  }, [mobileCheckCertified, mobileUseNNCOnboarding]);

  const { fetch: consent, loaded, loading, error } = useResourceAction(
    'profile',
    null,
    'POST',
    data
  );

  useEffect(() => {
    if (loaded && !error) {
      onSuccess();
    }
    if (error) {
      onError(error);
    }
  }, [loaded]);

  return {
    loading,
    error,
    consent,
  };
};

export const useMobile = user => {
  const authUser = useRequireUser(true);
  user = user || authUser;
  return useMemo(() => {
    return extractMobileInfo(user)
  }, [user]);
};

export const extractMobileInfo = (user) => {
  const info = {
    askMobileCertified: user.ask_mobile_certified ?? true,
    askMobileUseNNCOnboarding: user.ask_mobile_use_nnc_onboarding ?? true,
    mobileCertifiedDisplay:
      user.mobile_certified ?? user.mobile_certified_display,
    mobileConsentDisplay: user.mobile_consent_display,
    mobileDeclared: user.mobile_declared,
  };
  info.ask = !info.mobileConsentDisplay && (info.askMobileCertified || info.askMobileUseNNCOnboarding);
  return info;
}