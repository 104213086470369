import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core';
import StaffTable from '../../components/StaffTable';
import { useRequireAdminUser } from '../../../../lib/auth';
import { useResourceMany } from '../../../../lib/api';
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function ActiveUsers() {
  const classes = useStyles();
  const user = useRequireAdminUser();
  const { entityId } = useParams();
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <StaffTable entityId={entityId} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
