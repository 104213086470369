import { useMemo } from 'react';
import { useResourceAction, useResourceMany } from '../../../lib/api';

export function useUpdateEntity(entityId, data) {
  const entityData = useMemo(() => data, [data]);
  const update = useResourceAction(
    `manageable-entities/${entityId}`,
    undefined,
    'PATCH',
    entityData
  );
  return update;
}

export function useUpdateRoles(id, data) {
  const roleData = useMemo(() => data, [data]);
  const update = useResourceAction(
    `staff-roles/${id}`,
    undefined,
    'PATCH',
    roleData
  );
  return update;
}

export function useAddOfficialUser(data) {
  const userData = useMemo(() => data, [data]);
  const create = useResourceAction(`staff-roles`, undefined, 'POST', userData);
  return create;
}

export function useFilterOfficialUser(data, filterOption) {
  const userData = useMemo(() => data, [data]);
  const filter = useResourceMany('staff-roles', {
    username: userData,
    origin: filterOption,
  });
  return filter;
}

export function useAcceptUser(entityId, actionId, data) {
  const userData = useMemo(() => data, [data]);
  const accept = useResourceAction(
    `entities/${entityId}/pending-permissions/${actionId}/accept`,
    undefined,
    'POST',
    userData
  );

  return accept;
}

export function useRejectUser(entityId, actionId, data) {
  const userData = useMemo(() => data, [data]);
  const reject = useResourceAction(
    `entities/${entityId}/pending-permissions/${actionId}/reject`,
    undefined,
    'POST',
    userData
  );

  return reject;
}

export function useDeleteUserRole(entityId, permId, data) {
  const userData = useMemo(() => data, [data]);
  const deleteRole = useResourceAction(
    `entities/${entityId}/active-permissions/${permId}/deactivate`,
    undefined,
    'POST',
    userData
  );

  return deleteRole;
}

export function useEnrollUser(entityId, data) {
  const userData = useMemo(() => data, [data]);
  const enroll = useResourceAction(
    `entities/${entityId}/enroll`,
    undefined,
    'POST',
    userData
  );

  return enroll;
}
