import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../layouts/BasicLayout';
import { Paragraph, Title, NormalText } from '../components/Typography';
import {FormControl,FormLabel,RadioGroup,Radio,FormControlLabel} from '@material-ui/core';
import Button from '../components/Button';
import { useUser } from '../lib/auth';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Main = ({ t }) => {
  const user = useUser();
  const [value, setValue] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  
  return (
    <div>
        <FormControl component="fieldset">
                <Button
                    to={"/print/"+value}
                    component={Link}
                    style={{
                    marginBottom: 32,
                    }}
                    disabled = {value == ""}
                >
                    {"Εκτύπωση άδειας"}
                    <ChevronRightIcon />
                </Button>
        </FormControl>
    </div>
  );
};
Main.propTypes = {
  t: PropTypes.func.isRequired,
};

export default function PrintLicense() {
    const { plate_id } = useParams();
    const { t } = useTranslation();
    return (
        <BasicLayout
            main={<Main t={t} />}
            topPadding
            title={plate_id}
            hasBackButton
            isLoggedIn={false}
        />
    );
}
