import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateQuestionPage from './CreateQuestionPage';
import NotFound from '../NotFound';

export default function CreateRouter() {
  return (
    <Routes>
      <Route path="q/:qid" element={<CreateQuestionPage />} />
      <Route path="" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
