import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import Cookies from 'universal-cookie';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.cookie.main,
    padding: theme.spacing(2, 3, 4),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  rejectButton: {
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  link: {
    marginTop: theme.spacing(2),
    fontSize: '0.85rem',
    color: theme.palette.footer.contrastText,
    '& a': {
      color: theme.palette.footer.link,
    },
  },
}));

export default function SimpleSnackbar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const [open, setOpen] = React.useState(true);

  const handleAccept = () => {
    cookies.set('dilosi_cookie', 'Accept_cookies', { path: '/' });
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const action = (
    <>
      <Button
        className={clsx(classes.button, classes.rejectButton)}
        color="secondary"
        variant="contained"
        size="small"
        onClick={handleClose}
      >
        {t('cookies.reject')}
      </Button>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        size="small"
        onClick={handleAccept}
      >
        {t('cookies.accept')}
      </Button>
    </>
  );
  const message = (
    <>
      {t('cookies.message')}
      <Typography variant="body2" className={classes.link}>
        <a href="/terms">{t('cookies.terms')}</a>
      </Typography>
    </>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
      >
        <SnackbarContent
          classes={{ root: classes.root }}
          message={message}
          action={action}
        />
      </Snackbar>
    </div>
  );
}
