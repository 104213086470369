// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  children: React.DOM,
  className?: string,
};

type Output = React.DOM;

export default function CardTitle(props: Props): Output {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.root, props.className)}
      variant="h6"
      component="h4"
      {...props}
    >
      {props.children}
    </Typography>
  );
}
