import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../layouts/BasicLayout';
import { Paragraph, Title, NormalText } from '../components/Typography';
import {Select,MenuItem,InputLabel,FormControl,FormLabel,RadioGroup,Radio,FormControlLabel} from '@material-ui/core';
import Button from '../components/Button';
import { useUser,useRequireUser } from '../lib/auth';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

const Main = ({ t,plate_id }) => {
  const user = useUser();
  const [periferia, setPeriferia] = React.useState("");
  const classes = useStyles();
  const handleChange = event => {
    setPeriferia(event.target.value);
  };
  
  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="periferia_label">Περιφέρεια</InputLabel>
        <Select
          labelId="periferia_labe"
          id="periferia_labe_select"
          value={periferia}
          onChange={handleChange}
        >
          <MenuItem value={10}>Περιφέρεια 1</MenuItem>
          <MenuItem value={20}>Περιφέρεια 2</MenuItem>
          <MenuItem value={30}>Περιφέρεια 3</MenuItem>
        </Select>
        <Button
                    to={"/print/"+plate_id}
                    component={Link}
                    style={{
                    marginBottom: 32,
                    }}
                    disabled = {periferia == ""}
                >
                    {"Προβολή άδειας"}
                    <ChevronRightIcon />
                </Button>
      </FormControl>
    </div>
  );
};
Main.propTypes = {
  t: PropTypes.func.isRequired,
};

export default function SelectPeriferia() {
  const { t } = useTranslation();
  const { plate_id } = useParams();
  return (
    <BasicLayout
      main={<Main plate_id={plate_id} t={t} />}
      topPadding
      title="Επιλέξτε περιφέρεια"
      isLoggedIn={true}
      hasBackButton
    />
  );
}
