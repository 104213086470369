import React, { Fragment, useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import BasicLayout from '../../layouts/BasicLayout';
import { ContinueButton } from '../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { NormalText, Paragraph } from '../../components/Typography';
import user from '../../data/user';
import { TextToDOM, useSuggested } from '../../models/template';
import { useResource } from '../../lib/api';
import { useRequireUser } from '../../lib/auth';
import {
  useUpdateDeclaration,
  useTextFormFields,
} from '../../models/declaration';
import { FormBuilder, Fieldset, FieldsetLabel, Field } from '../../lib/form';
import RecipientsAutocomplete from '../../components/RecipientsAutocomplete';
import Markdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
  footNotes: {
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
}));

const DummyText = ({ t, template_type, fields, text, focused }) => {
  return (
    <Box lineHeight="1.7" paddingLeft={1} marginTop={2} marginBottom={2}>
      <NormalText>
        <Markdown source={template_type.preamble} escapeHtml={false} />
      </NormalText>
      <NormalText>{TextToDOM({ fields, text, focused })}</NormalText>
    </Box>
  );
};
DummyText.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  template_type: PropTypes.object.isRequired,
  text: PropTypes.string,
  focused: PropTypes.bool,
};

const Main = ({ classes, t }) => {
  useRequireUser();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const { id } = useParams();
  const { invalidate, data: declaration } = useResource('my-declarations', id);
  const template = useResource('all-templates', declaration.template_id).data;

  const [fields, authorizedFields, initial] = useTextFormFields(declaration);
  const initialData = useSuggested(initial);
  const [formData, setFormData] = useState(null);
  const [focused, setFocused] = useState([]);
  const update = useUpdateDeclaration(id, formData);
  const templateType = template.template_type.refname;

  function setFocus(key) {
    setFocused([key]);
  }

  function unsetFocus(key) {
    const newFocused = focused.filter(k => k !== key);
    setFocused(newFocused);
  }

  useEffect(() => {
    if (update.dataSet) {
      invalidate();
      update.fetch();
    }
  }, [update.dataSet]);

  useEffect(() => {
    if (update.error) {
      // TODO: handle error
    } else if (update.loaded || templateType === 'authorization') {
      navigate('/dilosi/' + id + '/preview');
    }
  }, [templateType, update.loaded, update.error]);

  const [recipient, setRecipient] = useState({
    name: declaration.recipient_name,
    id: declaration.recipient_id,
  });
  const [recipientError, setRecipientError] = useState(null);

  const parseFormData = data => {
    if (templateType === 'solemn_declaration') {
      data = { ...data, recipient: { id: recipient.id } };
    }
    return data;
  };
  const recipientRef = useRef();

  return (
    <Fragment>
      <Helmet>
        <title>{t('title.window_title')}</title>
      </Helmet>
      <FormBuilder
        initial={initialData}
        onSubmit={data => {
          const dataToSubmit = parseFormData(data);
          if (templateType === 'solemn_declaration') {
            if (recipient && recipient.id) {
              setFormData(dataToSubmit);
            } else {
              setRecipientError(t('form.validation.required'));
              recipientRef.current.focus();
            }
          } else {
            setFormData(dataToSubmit);
          }
        }}
      >
        <Fieldset>
          {templateType === 'solemn_declaration' && (
            <>
              <RecipientsAutocomplete
                inputRef={recipientRef}
                recipient={recipient}
                template_id={template.id}
                onChange={(_, val) => {
                  setRecipientError(null);
                  setRecipient(val);
                }}
                error={recipientError}
              />
            </>
          )}
        </Fieldset>
        <Box className={classes.buttonContainer}>
          <ContinueButton type="submit" />
        </Box>
      </FormBuilder>
    </Fragment>
  );
};
Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default function FormEditable(props) {
  const classes = useStyles();
  const { id } = useParams();
  const declaration = useResource('my-declarations', id);
  const template = useResource('all-templates', declaration.data.template_id);
  const { t } = useTranslation();
  return (
    <BasicLayout
      title={t(`recipient_page.title`)}
      captionRight={t('recipient_page.captionRight')}
      captionLeft={template.data.shortname}
      hasBackButton
      isLoggedIn={true}
      name={`${user.firstName} ${user.lastName}`}
      main={<Main classes={classes} t={t} />}
    />
  );
}
