import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import './App.css';
import App from './layouts/App';
import AdeiaCovid19StartPage from './pages/home/AdeiaCovid19StartPage';
import HomeStartPage from './pages/home/HomeStartPage';
import SelectCarPage from './pages/SelectCar';
import SelectPeriferiaPage from './pages/SelectPeriferia';
import PrintLicense from './pages/PrintCurrentLicense';
import HomeQuestionPage from './pages/home/HomeQuestionPage';
import CreateRouter from './pages/create';
import ShowRouter from './pages/show';
import DeclarationRouter from './pages/declaration';
import VaultRouter from './pages/vault';
import NotFound from './pages/NotFound';
import UserLogin from './pages/UserLogin';
import ServiceTerms from './pages/ServiceTerms';
import Stats from './pages/Stats';
import { TokenLogin } from './lib/auth';
import Loader from './components/Loader';
import theme from './themes/govgr';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import './i18n';
import Cookies from 'universal-cookie';
import Cookie from './components/Cookie';
import { ApiErrors, APIError } from './lib/api';
import DashboardRouter from './pages/Dashboard';
import { useFlags } from './lib/config';
import InfoPage from './pages/info/Info';
import NoMobile from './pages/info/NoMobile';
import MobileConsent from './pages/vault/MobileConsent';
import WrongPage from './pages/WrongPage';
import TemplateRouter from './pages/template';

export const HandleError = ({ error }) => {
  if (error instanceof APIError) {
    error = error.error;
    if (error.code === 404) {
      return <WrongPage />;
    }
  }
  return <WrongPage />;
};

const useStyles = makeStyles({
  success: { backgroundColor: theme.palette.success.main },
  error: { backgroundColor: theme.palette.error.main },
  warning: { backgroundColor: theme.palette.warning.main },
  info: { backgroundColor: theme.palette.info.main },
  fontSize: { fontSize: '1rem' },
});

export default function MainApp() {
  const classes = useStyles();
  const cookies = new Cookies();
  const flags = useFlags();
  return (
    <SnackbarProvider
      classes={{
        base: classes.fontSize,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router>
          <Suspense fallback={<Loader />}>
            <App direction="column">
              <ApiErrors fallback={HandleError}>
                {cookies.get('dilosi_cookie') ? '' : <Cookie />}
                <Routes>
                  <Route path="/" element={<HomeStartPage />} />
                  <Route path="/q/:qid" element={<HomeQuestionPage />} />
                  <Route path="/terms" element={<ServiceTerms />} />
                  <Route path="/select_car" element={<SelectCarPage />} />
                  <Route path="/select_periferia/:plate_id" element={<SelectPeriferiaPage />} />
                  <Route path="/print/:plate_id" element={<PrintLicense />} />
                  <Route path="/login" element={<UserLogin />} />
                  <Route path="/login/token" element={<TokenLogin />} />
                  <Route path="/dilosi/*" element={<DeclarationRouter />} />
                  <Route path="/create/*" element={<CreateRouter />} />
                  <Route path="/show/*" element={<ShowRouter />} />
                  <Route path="/vault/*" element={<VaultRouter />} />
                  <Route path="/consent" element={<MobileConsent />} />
                  {flags.DASHBOARD_ENABLED && (
                    <Route path="/dashboard/*" element={<DashboardRouter />} />
                  )}
                  <Route
                    path="/adeia-covid19"
                    element={<AdeiaCovid19StartPage />}
                  />
                  <Route path="/stats" element={<Stats />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<NotFound />} />
                  <Route
                    path="/info/eggrafi-kep"
                    element={<InfoPage infoKey="kep" />}
                  />
                  <Route
                    path="/info/no-mobile"
                    element={<NoMobile infoKey="no-mobile" />}
                  />
                  <Route
                    path="/info/eody-komy"
                    element={<InfoPage infoKey="eody-komy" />}
                  />
                  <Route path="templates/*" element={<TemplateRouter />} />
                </Routes>
              </ApiErrors>
            </App>
          </Suspense>
        </Router>
      </ThemeProvider>
    </SnackbarProvider>
  );
}
