import React, { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import BasicLayout from '../../layouts/BasicLayout';
import { ContinueButton } from '../../components/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Paragraph } from '../../components/Typography';
import user from '../../data/user';
import { useNavigate, useParams } from 'react-router-dom';
import { useResource } from '../../lib/api';
import { useRequireUser } from '../../lib/auth';
import {
  useContactFormFields,
  useUpdateDeclaration,
  useRecipient,
} from '../../models/declaration';
import { FormBuilder, Fieldset, Field } from '../../lib/form';
const useStyles = makeStyles(theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  hint: {
    marginBottom: theme.spacing(2),
  },
}));

const Main = ({ classes, t, declaration }) => {
  useRequireUser();
  const navigate = useNavigate();
  const { id } = useParams();
  const fields = [
    {
      label: t('custom_recipient_page.labels.recipient_name'),
      key: 'name',
      editable: true,
      validate: null,
      required: true,
      hint: null,
      helptext: null,
      type: 'string',
      layout: {},
    },
    // {
    //   label: t('custom_recipient_page.labels.recipient_email'),
    //   key: 'email',
    //   editable: true,
    //   validate: null,
    //   required: false,
    //   hint: null,
    //   helptext: null,
    //   type: 'email',
    //   layout: {},
    // },
  ];
  const [formData, setFormData] = useState(null);
  const parseFormData = formData => {
    if (formData) {
      return { recipient: formData };
    } else {
      return formData;
    }
  };
  const update = useUpdateDeclaration(id, parseFormData(formData));
  const recipient = useRecipient(declaration.data);
  // form data received, form has been submitted, initiate update request
  useEffect(() => {
    if (update.dataSet) {
      update.fetch();
    }
  }, [update.dataSet]);

  useEffect(() => {
    if (update.loaded && !update.error) {
      navigate('/dilosi/' + id + '/preview');
    }
  }, [update.loaded]);

  return (
    <Fragment>
      <Helmet>
        <title>{t('title.window_title')}</title>
      </Helmet>
      <Paragraph>{t('custom_recipient_page.description')}</Paragraph>
      <FormBuilder
        fields={fields}
        initial={recipient}
        onSubmit={data => {
          setFormData(data);
        }}
      >
        <Fieldset>
          {fields.map(f => (
            <Field name={f.key} key={f.key} />
          ))}
        </Fieldset>
        <Box className={classes.buttonContainer}>
          <ContinueButton type="submit" />
        </Box>
      </FormBuilder>
    </Fragment>
  );
};
Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default function FormEditable(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const declaration = useResource('my-declarations', id);
  const template = useResource('all-templates', declaration.data.template_id);

  return (
    <BasicLayout
      title={t('custom_recipient_page.title')}
      captionRight={t('recipient_page.captionRight')}
      captionLeft={template.data.shortname}
      hasBackButton
      isLoggedIn={true}
      name={`${user.firstName} ${user.lastName}`}
      main={<Main declaration={declaration} classes={classes} t={t} />}
    />
  );
}
