import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const useSimpleStyles = makeStyles(theme => ({
  root: {
    fontWeightMedium: 'bolder',
    textTransform: 'none',
  },
}));

const useResponsiveStyles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function Button(props) {
  const { xsFullWidth, ...restProps } = props;
  const classesSimple = useSimpleStyles();
  const classesResponsive = useResponsiveStyles();
  const classes = xsFullWidth ? classesResponsive : classesSimple;
  const component = props.type === 'submit' || !props.to ? 'button' : Link;

  return (
    <MuiButton
      component={component}
      data-testid="button"
      classes={classes}
      {...restProps}
    >
      {props.children}
    </MuiButton>
  );
}
Button.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  xsFullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'large',
  xsFullWidth: true,
};

export function ContinueButton(props) {
  const { t } = useTranslation();

  return <Button {...props}>{t('button.continue')}</Button>;
}
ContinueButton.propTypes = {
  type: PropTypes.node,
  to: PropTypes.any,
};
