import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../layouts/BasicLayout';
import { Paragraph, Title, NormalText } from '../components/Typography';
import {FormControl,FormLabel,RadioGroup,Radio,FormControlLabel} from '@material-ui/core';
import Button from '../components/Button';
import { useUser,useRequireUser } from '../lib/auth';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';

const Main = ({ t }) => {
  const user = useUser();
  const [value, setValue] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
        <FormControl component="fieldset">
                <RadioGroup aria-label="pinakida" name="pinakida" value={value} onChange={handleChange}>
                    <FormControlLabel value="XTB-1951" control={<Radio />} label="XTB-1951" />
                    <FormControlLabel value="TKN-9153" control={<Radio />} label="TKN-9153" />
                    <FormControlLabel value="ΖΧΙ-6581" control={<Radio style={{ color:"black !important" }}/>} label="ΖΧΙ-6581" />
                    <FormControlLabel value="ΔΟΚ-1234" disabled control={<Radio />} label="ΔΟΚ-1234" />
                </RadioGroup>
                <Button
                    to={"/select_periferia/"+value}
                    component={Link}
                    style={{
                    marginBottom: 32,
                    }}
                    disabled = {value == ""}
                >
                    {"Επιλογή περιφέρειας"}
                    <ChevronRightIcon />
                </Button>
        </FormControl>
    </div>
  );
};
Main.propTypes = {
  t: PropTypes.func.isRequired,
};

export default function SelectCar() {
  const { t } = useTranslation();
  return (
    <BasicLayout
      main={<Main t={t} />}
      topPadding
      title="Επιλέξτε το όχημα σας"
      isLoggedIn={true}
      hasBackButton
    />
  );
}
