import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 14,
    textTransform: 'capitalize',
    marginTop: theme.spacing(1),
    borderRadius: 0,
    borderBottom: '1px solid ',
  },
}));

export default function BackButton(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Button
      variant="text"
      onClick={() => navigate(-1)}
      color="default"
      className={classes.root}
      startIcon={<ArrowLeftIcon />}
      {...props}
    >
      {t('button.back')}
    </Button>
  );
}
