import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/Button';
import NormalText from '../../../components/Typography/NormalText';
import { useNewRequest } from '../models/requests';
import { useMessages } from '../../../utils/messages';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    paddingBottom: 0,
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(3, 0, 3),
  },
  buttonContainer: {
    paddingTop: theme.spacing(3),
  },
  input: {
    margin: theme.spacing(2, 0, 2),
  },
  formLabel: {
    fontWeight: '500',
  },
}));

export default function CreateNewRequest(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getMyRequests } = props;
  const { register, handleSubmit, errors } = useForm({});
  const showMessage = useMessages();
  const [open, setOpen] = useState(false);
  const [newRequest, setNewRequest] = useState();

  const onSubmit = data => {
    setOpen(false);
    const request = {
      entity_name: data.entityName.toUpperCase(),
      role: data.role,
    };
    setNewRequest(request);
    requestNewPermission();
  };

  const {
    fetch: requestNewPermission,
    error,
    loading,
    loaded,
    data: result,
  } = useNewRequest(newRequest);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const resetSearchModal = () => {
    setNewRequest(null);
  };

  useEffect(() => {
    if (error) {
      if (error.error.message === 'already.enrolled') {
        showMessage(
          'Είστε ήδη εγγεγραμμένοι με αυτό τον ρόλο στη συγκεκριμένη θυρίδα',
          'error'
        );
      } else if (error.error.message === 'not.valid.entity') {
        showMessage('Λάθος όνομα θυρίδας', 'error');
      } else if (error.error.message === 'request.exists') {
        showMessage('Το αίτημα σας υπάρχει ήδη', 'error');
      } else {
        showMessage('Σφάλμα', 'error');
      }
      handleClickClose();
      resetSearchModal();
    } else if (loaded) {
      handleClickClose();
      showMessage('Το αίτημά σας έχει αποσταλεί επιτυχώς', 'success');
      resetSearchModal();
      getMyRequests();
    }
  }, [error, loaded]);

  return (
    <>
      <Button size="md" onClick={handleClickOpen}>
        Νέο Αίτημα
      </Button>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          Νέο Αίτημα Πρόσβασης σε Θυρίδα
        </DialogTitle>
        <DialogContent>
          <div className={classes.card}>
            <Typography className={classes.text}>
              Για να αιτηθείτε την εγγραφή σας σε μία θυρίδα, συμπληρώστε τα
              παρακάτω στοιχεία:
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth component="fieldset">
                <NormalText className={classes.formLabel}>
                  Όνομα θυρίδας
                </NormalText>
                <TextField
                  id="entityName"
                  name="entityName"
                  variant="outlined"
                  className={classes.input}
                  fullWidth
                  inputRef={register({
                    required: 'true',
                  })}
                />
                <NormalText className={classes.formLabel}>Ρόλος</NormalText>
                <RadioGroup aria-label="role" name="role" defaultValue="clerk">
                  <FormControlLabel
                    color="default"
                    value="clerk"
                    inputRef={register}
                    control={<Radio color="primary" />}
                    label="Χειριστής"
                  />
                  <FormControlLabel
                    color="default"
                    value="head"
                    control={<Radio color="primary" />}
                    label="Διαχειριστής"
                    inputRef={register}
                  />
                </RadioGroup>
                <DialogActions>
                  <Button
                    className={classes.button}
                    color="secondary"
                    onClick={() => setOpen(false)}
                  >
                    Ακύρωση
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.button}
                  >
                    Ολοκλήρωση
                  </Button>
                </DialogActions>
              </FormControl>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
