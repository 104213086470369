import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  return {
    nested: {
      paddingLeft: theme.spacing(2),
    },
    primary: {
      fontWeight: 'bolder',
    },
  };
});
