import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useDeclarationByInboxId,
  useTemplate,
} from '../../../../models/declaration';
import DeclarationForm from '../../../../components/Declaration/DeclarationForm';
import DeclarationInboxActions from '../../../../components/DeclarationActions';
import { DashboardBar } from '../../../show/Sidebar';
import { Grid } from '@material-ui/core';
import { SavePDF } from '../../../../components/DeclarationActions/SaveEmailRevoke';

export default function DeclarationShow(props) {
  const { entityId, inboxId } = useParams();
  const { data: inbox, fetch } = useDeclarationByInboxId(entityId, inboxId);
  const declaration = inbox.declaration;
  const { data: template } = useTemplate(declaration.template_id);

  return (
    <>
      <DashboardBar
        cols={true}
        declaration={declaration}
        template={template}
        isOwner={false}
        isAdmin={false}
        inboxItem={inbox}
      />
      <DeclarationForm
        declaration={declaration}
        template={template}
        inbox={inbox}
        isOwner={false}
        isAddmin={true}
      />
      <DeclarationInboxActions template={template} declaration={declaration} />
    </>
  );
}
