import Hidden from '@material-ui/core/Hidden';
import React, { Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/ButtonBase';
import ServiceBadge from './ServiceBadge';
import { Title, NormalText } from '../components/Typography/';
import useAuth, { useUser } from '../lib/auth';
import UserActions from '../components/UserActions';
import { useTranslation } from 'react-i18next';
import { useFlags } from '../lib/config';

const useStyles = makeStyles(theme => ({
  appbar: {
    // backgroundColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.black,
  },
  coloredBar: {
    background: theme.palette.primary.light,
    width: theme.header.border.width,
    height: theme.header.border.height,
    marginBottom: '-' + theme.header.border.height,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    marginBottom: 0,
  },
  '@media screen and (min-width: 864px)': {
    subtitle: {
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
    },
  },
  user: {
    flexGrow: 1,
    textAlign: 'right',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolBar: {
    minHeight: theme.header.height,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const { authenticated } = useAuth();
  const user = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const Logo = theme.header.logo.component;
  const flags = useFlags();

  return (
    <Fragment>
      <MuiAppBar className={classes.appbar} position="static">
        <Container>
          <Toolbar variant="dense" disableGutters className={classes.toolBar}>
            <Grid container spacing={1}>
              <Grid item className={classes.main}>
                <div className={classes.logo}>
                  <Logo />
                </div>
                <MuiButton disableRipple href="/">
                  <Title size="sm" className={classes.subtitle} noWrap>
                    {t('header_appbar.title')}
                    <ServiceBadge label="BETA" />
                    {flags.DEVEL && <ServiceBadge alt label="TEST" />}
                  </Title>
                </MuiButton>
              </Grid>
              {authenticated ? (
                <Fragment>
                  <Grid
                    item
                    className={classes.userContainer}
                    data-testid="loggedUser"
                  >
                    <Hidden smDown>
                      <NormalText className={classes.user} noWrap>
                        {t('header_appbar.logged_user')} {user.userDisplayName}
                      </NormalText>
                    </Hidden>
                    <UserActions user={user} classes={classes} />
                  </Grid>
                </Fragment>
              ) : (
                ''
              )}
            </Grid>
          </Toolbar>
        </Container>
        <div className={classes.coloredBar} />
      </MuiAppBar>
    </Fragment>
  );
}
