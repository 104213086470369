import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BackButton from './BackButton';
import { Caption, Title } from './Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dense: {
    margin: theme.spacing(0),
  },
}));

export default function Top(props) {
  const {
    hasBackButton,
    backButtonDisabled,
    title,
    captionRight,
    captionLeft,
  } = props;
  const classes = useStyles();
  return (
    <Fragment>
      {hasBackButton && <BackButton disabled={backButtonDisabled || false} />}
      {captionLeft || captionRight ? (
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          minHeight={19}
        >
          {captionLeft && <Caption>{captionLeft}</Caption>}
          {captionRight && <Caption>{captionRight}</Caption>}
        </Box>
      ) : (
        ''
      )}
      {title && (
        <Title
          className={clsx(props.className, props.dense && classes.dense)}
          size={props.size}
        >
          {title}
        </Title>
      )}
    </Fragment>
  );
}
Top.propTypes = {
  hasBackButton: PropTypes.bool,
  backButtonDisabled: PropTypes.bool,
  className: PropTypes.string,
  dense: PropTypes.bool,
  title: PropTypes.string,
  captionRight: PropTypes.string,
  captionLeft: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
};
