import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import BasicLayout from '../layouts/BasicLayout';
import { Paragraph, Title } from '../components/Typography';
import { MDHelper } from '../lib/form';
import ButtonIcon from '../components/ButtonIcon';
import ButtonsContainer from '../components/ButtonsContainer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';
import useAuth from '../lib/auth';
import Quote from '../components/Quote';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import useConfig, { useFlags } from '../lib/config';
import Box from '@material-ui/core/Box';
import el from 'locales/el';

const useStyles = makeStyles(theme => ({
  secondParagraph: {
    padding: theme.spacing(0, 0, 4),
    marginTop: theme.spacing(9),
  },
  warning: {
    margin: theme.spacing(4) + 'px 0',
    '& ul': {
      marginBottom: 0,
      marginTop: theme.spacing(2),
    },
  },
  warningTitle: {
    marginTop: 0,
  },
  errorMessage: {
    margin: theme.spacing(2),
  },

  box: {
    borderColor: theme.palette.error.main,
    marginBottom: theme.spacing(4),
  },
}));

const MobileWarning = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.warning}>
      <Quote warning>
        <Title className={classes.warningTitle} size="small">
          {t('user_login.mobile_warning.title')}
        </Title>{' '}
        <MDHelper source={t('user_login.mobile_warning.content')} />
      </Quote>
    </div>
  );
};

const CitizenUsers = ({ demoLogin, next }) => {
  const { t } = useTranslation();
  return (
    <>
      <MenuItem
        onClick={() => demoLogin({ username: 'govgr/126635512' })}
        data-testid="demo-menekses"
      >
        {t('user_login.menekses')}
      </MenuItem>
      <MenuItem onClick={() => demoLogin({ username: 'govgr/8754983494' })}>
        {t('user_login.floupi')}
      </MenuItem>
      <MenuItem onClick={() => demoLogin({ username: 'govgr/11098765' })}>
        Κωνσταντίνα Παπακωνσταντίνου
      </MenuItem>
      <MenuItem onClick={() => demoLogin({ username: 'govgr/987654321' })}>
        Ελευθέριος Ελευθερίου
      </MenuItem>
    </>
  );
};

const ADMIN_USERS = [
  {
    username: 'admin/136235515',
    name: 'Ηλέκτρα Αποστόλου',
  },
  {
    username: 'admin/1283764123',
    name: 'Μιχάλης Αντωνάτος',
  },
  {
    username: 'admin/7654983494',
    name: 'Καλλιόπη Παπαδοπούλου',
  },
  {
    username: 'admin/1024983494',
    name: 'Άγγελος Αγγέλου',
  },
  {
    username: 'admin/8789083494',
    name: 'Ευαγγελία Χριστοφίδη',
  },
  {
    username: 'admin/1111111111',
    name: 'Τάκης Τσούκαλος',
  },
];

const AdminUsers = ({ demoLogin }) => {
  return (
    <>
      {ADMIN_USERS.map(({ username, name }) => (
        <MenuItem onClick={() => demoLogin({ username })}>{name}</MenuItem>
      ))}
    </>
  );
};

const DemoUsers = ({ admin }) => {
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const auth = useAuth();
  const demoLogin = useCallback(data => {
    window.localStorage.setItem('dilosi-login-next', next);
    window.setTimeout(() => {
      if (data.username) {
        window.location.href =
          auth.config.loginURL + '?username=' + data.username;
      }
      if (data.token) {
        auth.authenticate(data.token);
      }
    }, 1);
  }, []);

  const location = useLocation();
  const params = qs.parse(location.search.slice(1));
  const next = params.next || '/';

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {t('user_login.demo_user')}
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            width: 280,
            padding: '4px',
          },
        }}
      >
        {admin ? (
          <AdminUsers next={next} demoLogin={demoLogin} />
        ) : (
          <CitizenUsers next={next} demoLogin={demoLogin} />
        )}
      </Menu>
    </div>
  );
};

const Main = ({ classes, t, admin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const params = qs.parse(location.search.slice(1));
  const next = params.next;
  const flags = useFlags();
  const config = useConfig();
  const ADMIN_LOGIN_URL = config.ADMIN_LOGIN_URL || '/api/gsis_admin_login';
  const USER_LOGIN_URL = config.USER_LOGIN_URL || '/api/login';
  const queryString = require('query-string');

  const showMobileWarning = true;
  useEffect(() => {
    if (auth.authenticated) {
      navigate(next, { replace: true });
    }
  }, [auth.authenticated]);

  const errorMessage = queryString.parse(location.search).error_code;

  const ErrorWarning = props => {
    const { errorMessage } = props;

    let msg;
    const firstLevel = errorMessage.split('.')[0];
    const secondLevel = errorMessage.split('.')[1];
    const checkPath = el.api_login.errors;

    if (firstLevel in checkPath) {
      if (typeof checkPath[firstLevel] === 'string') {
        msg = t('api_login.errors.' + firstLevel);
      } else if (
        secondLevel !== 'undefined' &&
        secondLevel in checkPath[firstLevel]
      ) {
        msg = t('api_login.errors.' + firstLevel + '.' + secondLevel);
      } else if ('error' in checkPath[firstLevel]) {
        msg = t('api_login.errors.' + firstLevel + '.error');
      }
    }

    if (!msg) {
      msg = t('api_login.errors.error');
    }

    return (
      <Box className={classes.box} border={7}>
        <Title className={classes.errorMessage} size="md">
          Υπάρχει κάποιο πρόβλημα
        </Title>
        <Paragraph className={classes.errorMessage}>{msg}</Paragraph>
      </Box>
    );
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{t('title.window_title')}</title>
        </Helmet>
        {errorMessage && <ErrorWarning errorMessage={errorMessage} />}
        <Paragraph>{t('user_login.decl_subtitle')}</Paragraph>
        {showMobileWarning && <MobileWarning />}
        <ButtonsContainer>
          <ButtonIcon
            component={Link}
            to="."
            onClick={() => {
              window.localStorage.setItem('dilosi-login-next', next);
              window.setTimeout(() => {
                if (admin) {
                  window.location.href = ADMIN_LOGIN_URL;
                } else {
                  window.location.href = USER_LOGIN_URL;
                }
              }, 1);
            }}
            label={t('user_login.connect')}
            Icon={ExitToAppIcon}
          />
          {flags.DEVEL && <DemoUsers admin={admin} />}
        </ButtonsContainer>
      </div>
    </>
  );
};
Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default function LoginForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { search } = useLocation();
  let admin = search.includes('admin=');

  const flags = useFlags();
  // enforce non-admin login if dashboard is disabled
  if (admin && !flags.DASHBOARD_ENABLED) {
    admin = false;
  }

  return (
    <BasicLayout
      title={admin ? t('user_login.title_admin') : t('user_login.title')}
      hasBackButton
      isLoggedIn={false}
      main={<Main admin={admin} classes={classes} t={t} />}
    />
  );
}
