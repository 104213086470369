import React, { useEffect } from 'react';
import { Title } from '../../components/Typography';
import { useNavigate } from 'react-router-dom';
import QrReader from 'react-qr-reader';

export default function Main() {
  const navigate = useNavigate();
  const handleError = err => {
    console.error(err);
  };
  const handleScan = data => data && window.location.replace(data);

  useEffect(() => {
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        {
          video: true,
        },
        function(localMediaStream) {},
        function(err) {
          alert(
            'The following error occurred when trying to access the camera: ' +
              err
          );
        }
      );
    } else {
      alert('Sorry, browser does not support camera access');
    }
  }, []);

  return (
    <div>
      <Title size="xl">Σαρώστε το QR Code</Title>
      <QrReader
        style={{ maxWidth: 300 }}
        delay={100}
        onError={handleError}
        onScan={handleScan}
      />
    </div>
  );
}
