import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    fontSize: 19,
  },
  key: {
    fontWeight: 500,
  },
  value: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  hint: {
    fontSize: 12,
  },
  dividerWrapper: {
    padding: theme.spacing(1, 0),
  },
}));

export default function KeyValueList({ className, data, keys, hint, style }) {
  const classes = useStyles();
  const { t } = useTranslation();
  keys = keys || Object.keys(data);

  return (
    <Grid container className={clsx(className, classes.root)} style={style}>
      {keys.map((key, index) => (
        <Grid item container key={index} data-testid="user">
          <Grid item xs={12} sm={6} className={classes.key}>
            {t(`label.${key}`)}
            {hint ? <span> *</span> : []}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.value}>
            <span>{data[key]}</span>
          </Grid>
          <Grid xs={12} item className={classes.dividerWrapper}>
            <Divider />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

KeyValueList.propTypes = {
  data: PropTypes.object,
  keys: PropTypes.object,
  hint: PropTypes.bool,
  style: PropTypes.node,
  className: PropTypes.string,
};

export function LabelValueList({ data, keys, style, className }) {
  const classes = useStyles();
  const sortedData = [];
  keys.map(key => {
    data.filter(item => {
      if (item.key === key) {
        sortedData.push(item);
      }
    });
  });
  return (
    <Grid container className={clsx(className, classes.root)} style={style}>
      {sortedData.map((item, index) => {
        const { label, value, origin } = item;
        const originNotUser = origin !== 'user';
        return (
          <Grid item container key={index}>
            <Grid item xs={12} sm={6} className={classes.key}>
              {item.key === 'solemn:recipient' ? (
                <>
                  {label}
                  {<sup>(1)</sup>}
                </>
              ) : item.key === 'solemn:birth_date' ? (
                <>
                  {label}
                  {<sup>(2)</sup>}
                </>
              ) : (
                <>
                  {label}
                  {originNotUser ? <span> *</span> : []}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.value}>
              <span>{value}</span>
            </Grid>
            <Grid xs={12} item className={classes.dividerWrapper}>
              <Divider />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

LabelValueList.propTypes = {
  data: PropTypes.object,
  keys: PropTypes.object,
  style: PropTypes.node,
  className: PropTypes.string,
};
