import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { without } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  addField: {
    marginLeft: theme.spacing(2),
  },
  fields: {
    marginBottom: theme.spacing(4),
  },
  fieldItem: {
    width: '100%',
    borderLeft: `4px dotted ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  fieldInput: {
    marginBottom: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(3.5),
  },
}));
// RAW DATA
const fieldTypes = [
  { value: 'date', label: 'Ημερομηνία' },
  { value: 'number', label: 'Αριθμός' },
  { value: 'text', label: 'Κείμενο' },
];

const EMPTY_FIELD = { label: '', key: '', hint: '', external_immutable: false };
const FieldItem = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { field } = props;

  const handleClick = () => {
    setOpen(!open);
  };
  const handleExternalImmutableChange = event => {
    props.onChange(props.index, {
      ...field,
      [event.target.name]: event.target.checked,
    });
  };
  const handleInputChange = event => {
    props.onChange(props.index, {
      ...field,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <ListItem button onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText
          primary={field.label || `Ανώνυμο Πεδίο ${props.index + 1}`}
        />
        {open ? (
          <>
            <IconButton
              onClick={event => {
                event.stopPropagation();
                props.onRemove(props.field);
              }}
              size="small"
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        ) : (
          ''
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem className={classes.nested}>
            <div className={classes.fieldItem}>
              <div className={classes.fieldInput}>
                <TextField
                  fullWidth
                  onChange={handleInputChange}
                  name="label"
                  value={field.label}
                  label="Label"
                  variant="filled"
                />
              </div>
              <div className={classes.fieldInput}>
                <TextField
                  fullWidth
                  onChange={handleInputChange}
                  name="key"
                  value={field.key}
                  label="Key"
                  variant="filled"
                />
              </div>
              <div className={classes.fieldInput}>
                <TextField
                  fullWidth
                  onChange={handleInputChange}
                  name="hint"
                  value={field.hint}
                  label="Hint"
                  variant="filled"
                />
              </div>
              <div className={classes.fieldInput}>
                <TextField
                  select
                  label="Τύπος Πεδίου"
                  fullWidth
                  name="type"
                  value={field.type || ''}
                  onChange={handleInputChange}
                  variant="filled"
                >
                  {fieldTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className={classes.fieldInput}>
                <FormControlLabel
                  name="external_immutable"
                  control={
                    <Switch
                      checked={field.external_immutable}
                      onChange={handleExternalImmutableChange}
                      color="primary"
                    />
                  }
                  label="External Immutable"
                />
              </div>
            </div>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

FieldItem.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  index: PropTypes.number,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  key: PropTypes.object,
  hint: PropTypes.string,
  type: PropTypes.string,
  external_immutable: PropTypes.object,
};
export default function FieldsBuilder(props) {
  const classes = useStyles();
  // const [fields, setFields] = useState(
  //   props.serializedData.fields || [EMPTY_FIELD]
  // );
  const fields = props.serializedData.fields || [EMPTY_FIELD];
  const addField = () => {
    const newFields = [...fields, EMPTY_FIELD];
    props.onChange(newFields);
  };
  const handleRemoveField = field => {
    const newFields = without(fields, field);
    props.onChange(newFields);
  };
  const handleFieldChange = (index, field) => {
    const newFields = fields;
    newFields[index] = field;
    props.onChange(newFields);
  };

  return (
    <div className={classes.fields}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {fields.map((field, index) => (
          <FieldItem
            onChange={handleFieldChange}
            onRemove={handleRemoveField}
            key={index}
            index={index}
            field={field}
          />
        ))}
      </List>
      <IconButton className={classes.addField} size="small" onClick={addField}>
        <AddIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
}

FieldsBuilder.propTypes = {
  serializedData: PropTypes.object,
  onChange: PropTypes.func,
};
