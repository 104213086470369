import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import BasicLayout from '../../layouts/BasicLayout';
import { ContinueButton } from '../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Title, Paragraph } from '../../components/Typography';
import user from '../../data/user';
import { TextToDOM, useSuggested } from '../../models/template';
import { useResource, useApiFormErrors } from '../../lib/api';
import { useRequireUser } from '../../lib/auth';
import {
  useUpdateDeclaration,
  useTextFormFields,
} from '../../models/declaration';
import { FormBuilder, Fieldset, FieldsetLabel, Field } from '../../lib/form';
import Markdown from 'react-markdown';
import Loader from '../../components/Loader';

const useStyles = makeStyles(theme => ({
  footNotes: {
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  dummyText: {
    background: theme.palette.grey['300'],
    padding: theme.spacing(1, 3),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  markdown: {
    '& > p': {
      margin: theme.spacing(2, 0),
    },
  },
}));

const DummyText = ({ t, classes, template_type, fields, text, focused }) => {
  return (
    <Box
      className={classes.dummyText}
      lineHeight="1.7"
      paddingLeft={1}
      marginTop={2}
      marginBottom={2}
    >
      <Paragraph>
        <Markdown
          className={classes.markdown}
          source={template_type.preamble}
          escapeHtml={false}
        />
      </Paragraph>
      <Paragraph>{TextToDOM({ fields, text, focused })}</Paragraph>
    </Box>
  );
};
DummyText.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  template_type: PropTypes.object.isRequired,
  text: PropTypes.string,
  classes: PropTypes.object,
  focused: PropTypes.bool,
};

function AuthorizedFields(props) {
  const { t } = useTranslation();
  if (!props.show) {
    return null;
  }
  return (
    <Fieldset>
      <FieldsetLabel>
        {t('form_declaration_content.authorized_user')}
      </FieldsetLabel>
      {props.fields.map(f => (
        <Field name={f.key} key={f.key} />
      ))}
    </Fieldset>
  );
}

AuthorizedFields.propTypes = {
  fields: PropTypes.object.isRequired,
  show: PropTypes.any,
};

const Main = ({ classes, template, t }) => {
  useRequireUser();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const { id } = useParams();
  const { invalidate, data: declaration } = useResource('my-declarations', id);

  const [fields, authorizedFields, initial] = useTextFormFields(declaration);
  const initialData = useSuggested(initial);
  const [formData, setFormData] = useState(null);
  const [focused, setFocused] = useState([]);
  const update = useUpdateDeclaration(id, formData);
  const templateType = template.template_type.refname;

  function setFocus(key) {
    setFocused([key]);
  }

  function unsetFocus(key) {
    const newFocused = focused.filter(k => k !== key);
    setFocused(newFocused);
  }

  useEffect(() => {
    if (update.dataSet) {
      invalidate();
      update.fetch();
    }
  }, [update.dataSet]);

  const isAuth = templateType === 'authorization';

  useEffect(() => {
    if (update.error) {
      // TODO: handle error
    } else if (
      update.loaded &&
      template.recipient_id !== null &&
      template.recipient_policy === 'owner'
    ) {
      navigate('/dilosi/' + id + '/preview');
    } else if (update.loaded && isAuth) {
      navigate('/dilosi/' + id + '/preview');
    } else if (update.loaded) {
      // TODO: FORCE REDIRECT TO CUSTOM RECIPIENT FOR INITIAL ROLL OUT ONLY
      navigate('/dilosi/' + id + '/recipient/custom');
    }
  }, [template, update.loaded, update.error]);

  const errors = useApiFormErrors(update.error);

  return (
    <Fragment>
      <Helmet>
        <title>{t('title.window_title')}</title>
      </Helmet>
      <FormBuilder
        errors={errors}
        fields={fields}
        authorizedFields={authorizedFields}
        initial={initialData}
        onSubmit={data => {
          if (!isAuth) {
            if (
              template.recipeint_id !== null &&
              template.recipient_policy === 'owner'
            ) {
              data.recipient = { id: template.recipient_id };
            }
          }
          setFormData(data);
        }}
      >
        <Box className={classes.footNotes}>
          <Title size="md">{t(`fieldset.label.text`)}</Title>
          <DummyText
            classes={classes}
            t={t}
            fields={template.fields}
            template_type={template.template_type}
            text={template.text}
            focused={focused}
          />
        </Box>
        <Box className={classes.footNotes}>
          <Title size="md">form_declaration_content.fields</Title>
          <Fieldset>
            {fields.map(f => (
              <Field
                multiline={f.key === 'free_text'}
                onMouseLeave={() => unsetFocus(f.key)}
                onMouseEnter={() => setFocus(f.key)}
                name={f.key}
                key={f.key}
              />
            ))}
          </Fieldset>
        </Box>
        {templateType === 'authorization' && (
          <AuthorizedFields
            show={true}
            fields={authorizedFields}
            setFocus={setFocus}
            unsetFocus={unsetFocus}
          />
        )}

        <Box className={classes.buttonContainer}>
          {update.loading && <Loader />}
          <ContinueButton
            style={{ marginTop: '12px' }}
            type="submit"
            disabled={update.loading}
          />
        </Box>
      </FormBuilder>
    </Fragment>
  );
};
Main.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
};

export default function FormEditable(props) {
  const classes = useStyles();
  const { id } = useParams();
  const declaration = useResource('my-declarations', id);
  const { data: template } = useResource(
    'templates',
    declaration.data.template_id
  );
  const { t } = useTranslation();
  return (
    <BasicLayout
      title={t(`form_declaration_content.header`)}
      captionRight={t('form_declaration_content.caption_right')}
      captionLeft={template.shortname}
      hasBackButton
      isLoggedIn={true}
      name={`${user.firstName} ${user.lastName}`}
      main={<Main template={template} classes={classes} t={t} />}
    />
  );
}
