import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavMenu } from './NavMenu';
import { useRequireAdminUser } from '../../../lib/auth';
import { Link, useParams } from 'react-router-dom';
import { Box, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  requestsButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default function SidebarMenu() {
  const user = useRequireAdminUser();
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams();
  const entityId = params['*'].split('/')[2];
  const entity = user.entities && user.entities[entityId];
  const declarationsLinks = entity
    ? [
        {
          path: `/dashboard/entities/show/${entityId}/inbox/list/all/`,
          label: 'Όλα',
        },
      ]
    : [];

  const entitiesLinks = [
    {
      path: `/dashboard/entities/show/${entityId}/users/active`,
      label: 'Χρήστες',
    },
    {
      path: `/dashboard/entities/show/${entityId}/users/pending`,
      label: 'Αιτήματα',
    },
  ];

  const myEntities = [
    { path: '/dashboard/entities/list', label: 'Οι Θυρίδες μου' },
    { path: `/dashboard/entities/requests/list`, label: 'Αιτήματα Πρόσβασης' },
  ];

  return (
    <>
      <div>
        {entity ? (
          <>
            <Box
              className={classes.requestsButton}
              component="h2"
              margin="0"
              color={theme.palette.primary.main}
            >
              {entity.entity}
            </Box>
            <Box className={classes.requestsButton} component="h4" margin="10">
              <Link to="/dashboard/entities/list">Αλλαγή Θυρίδας</Link>
            </Box>

            {entity.roles.includes('clerk') && (
              <NavMenu title="Θυρίδα" open links={declarationsLinks} />
            )}
            {entity.roles.includes('head') && (
              <NavMenu title="Πρόσβαση" open links={entitiesLinks} />
            )}
          </>
        ) : (
          <NavMenu title="Θυρίδες" open links={myEntities} />
        )}
      </div>
    </>
  );
}
