import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { fullTextSearch } from '../models/template';
import { useState } from 'react';

export default function AutocompleteField(props) {
  const { inputRef, placeholder, name } = props;
  return (
    <Grid
      item
      xs={props.layout.xs || 12}
      sm={props.layout.sm || 12}
      {...props.gridProps}
    >
      <Autocomplete
        renderInput={params => (
          <TextField
            {...params}
            margin="normal"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            inputRef={inputRef}
            placeholder={placeholder}
            name={name}
            error={props.error}
            helperText={
              props.error ? props.error : props.helperText || params.helperText
            }
          />
        )}
        {...props}
      />
    </Grid>
  );
}

AutocompleteField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  layout: PropTypes.object,
  gridProps: PropTypes.object,
  inputRef: PropTypes.shape({ current: PropTypes.any }),
  error: PropTypes.string,
  helperText: PropTypes.string,
};

AutocompleteField.defaultProps = {
  layout: {},
  gridProps: {},
};

export function SimpleAutocomplete({ onChange, defaultValue, ...props }) {
  const [inputValue, setInputValue] = useState();
  function _onChange(evt, option) {
    setInputValue(undefined);
    onChange(option === null ? undefined : option.value);
  }
  function getOptionSelected(option1, option2) {
    if (option2 === null) {
      return false;
    }
    return option1.value === option2.value || option1.value === option2;
  }
  function getOptionLabel(option) {
    return option === null ? option : option.label || option;
  }
  function filter(options, params) {
    return options.filter(option => fullTextSearch(option, params.inputValue));
  }
  function filterOptions(options, params) {
    const filtered = filter(options, params);
    if (props.freeSolo && params.inputValue && filtered.length === 0) {
      setInputValue(params.inputValue);
      filtered.push({
        value: params.inputValue,
        label: params.inputValue,
      });
    }
    return filtered;
  }

  function handleKey(evt) {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      evt.stopPropagation();
    }
  }

  function onBlur(evt) {
    if (inputValue && props.freeSolo) {
      _onChange(evt, {
        value: inputValue,
        label: inputValue,
      });
    }
    props.onBlur && props.onBlur(evt);
  }

  defaultValue = defaultValue ?? null;
  const value = props.value ?? null;
  return (
    <Autocomplete
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      {...props}
      value={value}
      onChange={_onChange}
      defaultValue={defaultValue}
      onBlur={onBlur}
      renderInput={params => {
        return (
          <TextField
            {...params}
            margin="normal"
            variant="outlined"
            onKeyPress={handleKey}
            label={props.label}
            name={props.name}
          />
        );
      }}
    />
  );
}
