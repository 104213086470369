import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import Button from '../../components/Button';
import ButtonIcon from '../../components/ButtonIcon';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useInboxAction } from './actions';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialog: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  dialogActions: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  dialogParagraph: {
    marginBottom: theme.spacing(4),
  },
}));

export default function AcceptButton(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetch: accept, loaded, error } = useInboxAction(props, 'accept');
  const [open, setOpen] = useState(false);
  const [, setConfirm] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleClickConfirm = () => {
    setConfirm(true);
    setOpen(false);
    accept();
  };

  useEffect(() => {
    if (loaded && !error) {
      window.location.reload();
    }
  }, [loaded, error]);

  return (
    <>
      <ButtonIcon
        className={classes.button}
        xsFullWidth
        Icon={CheckIcon}
        label={t('declaration_actions.accept_button')}
        onClick={handleClickOpen}
      />
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('declaration_actions.accept_button')}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText className={classes.dialogParagraph}>
            {t('declaration_actions.accept_dialog_title')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClickClose} color="secondary">
            Ακύρωση
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickConfirm}
          >
            {t('declaration_actions.accept_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AcceptButton.propTypes = {
  onSubmit: PropTypes.func,
};
