import React, { createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useImmerReducer } from 'use-immer';
import { useAPI } from '../../../lib/api';

const defaultState = [];

const getStaffUsers = async function(dispatch, api, entityId) {
  const { token } = api;
  const response = await fetch(
    `${api.config.baseURL}/entities/${entityId}/active-permissions/`,
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );
  const json = await response.json();
  dispatch({ type: 'GET', payload: json.data });
};

const reducer = (draftState, action) => {
  switch (action.type) {
    case 'GET':
      return action.payload;
    default:
      return draftState;
  }
};

export const Context = createContext(defaultState);

export const useActions = dispatch => {
  const api = useAPI();
  return {
    getStaffUsers: useCallback(entityId =>
      getStaffUsers(dispatch, api, entityId)
    ),
  };
};

export default function useStaffUserStore() {
  return useContext(Context);
}
export function StaffUserStore({ children }) {
  const [state, dispatch] = useImmerReducer(reducer, defaultState);
  const actions = useActions(dispatch);
  return (
    <Context.Provider value={[state, actions, dispatch]}>
      {children}
    </Context.Provider>
  );
}
StaffUserStore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
