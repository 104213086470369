// @flow
import React, { useState, useEffect, useMemo } from 'react';
import Autocomplete from '../components/Autocomplete';
import { useLocation } from 'react-router-dom';
import Spoiler from '../components/Spoiler';
import { useResourceMany } from '../lib/api';
import { useTranslation } from 'react-i18next';

type Props = {
  category: string,
  recipient: any,
  errors: any,
  onChange: any,
  name: String,
  error: any,
  template_id: any,
};

export default function RecipientsAutocomplete(props: Props) {
  const { error, name, errors, recipient, template_id } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const url = '';

  // resolve the default input value
  const [search, setSearch] = useState('');

  // useResourceMany with no consume and no suspense
  const query = { template: template_id, search };
  const { data, fetch, loading } = useResourceMany(
    'recipients',
    query,
    null,
    null,
    false,
    false
  );
  // search characters over which we hit the backend
  const triggerLimit = 3;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const search = recipient ? recipient.name : '';
    setSearch(search);
  }, [recipient]);

  const placeholder = `${t('autocomplete.placeholder')}`;

  const trigger = useMemo(() => {
    return search && search.length >= triggerLimit;
  }, [search]);
  useEffect(() => {
    if (open && trigger) {
      fetch();
    }
  }, [trigger, search, open]);

  const noText = useMemo(() => {
    return trigger
      ? t('label.recipients_none_found')
      : t('label.recipients_min_input');
  }, [search]);

  const [options, setOptions] = useState([recipient]);
  useEffect(() => {
    const dflt = recipient && recipient.id ? [] : [];
    const opts = trigger ? data || dflt : dflt;
    setOptions(opts);
  }, [data, trigger, recipient]);

  return (
    <>
      <Spoiler label={t('recipient_page.not_found')} to="custom" />
      <Autocomplete
        options={options}
        loading={loading}
        noOptionsText={noText}
        filterOptions={(options, _) => options}
        getOptionLabel={option => option.name}
        errors={errors}
        error={error}
        name={name}
        placeholder={placeholder}
        disableClearable={true}
        value={recipient}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={props.onChange}
        onInputChange={(_, val) => {
          setSearch(val && val.length ? val : '');
        }}
        {...props}
      />
    </>
  );
}
