import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRequireUser } from '../../lib/auth';
import { Paragraph } from '../Typography';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

export default function AcceptReject({ inboxItem }) {
  const { t } = useTranslation();
  const user = useRequireUser();
  const { entityId } = useParams();
  const entities = user.entities || [];

  const isAdmin = Object.keys(entities).includes(entityId);
  const isPending =
    inboxItem.inbox_status === 'pending' &&
    (user.id === inboxItem.user || isAdmin);
  const buttonProps = { isAdmin, inboxItem };
  const showReject = false;
  return (
    <>
      {isPending && (
        <>
          <Paragraph>{t('declaration_actions.next_steps_accept')}</Paragraph>
          <>
            <AcceptButton {...buttonProps} />
            {showReject && <RejectButton {...buttonProps} />}
          </>
        </>
      )}
    </>
  );
}

AcceptReject.propTypes = {
  inboxItem: PropTypes.object.isRequired,
};

AcceptReject.defaultProps = {
  inboxItem: {},
};
