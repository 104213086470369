import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { NotFoundInner } from 'pages/NotFound';
import ActiveUsers from './ActiveUsers';
import PendingUsers from './PendingUsers';
import DeclarationsList from 'pages/Dashboard/pages/Declarations/list';
import DeclarationShow from 'pages/Dashboard/pages/Declarations/show';
import { useRequireAdminUser } from 'lib/auth';

const RedirectToDefaultStatus = props => {
  const navigate = useNavigate();
  const user = useRequireAdminUser();
  const { entityId } = useParams();
  const entity = user.entities && user.entities[entityId];
  if (!entity) {
    navigate('/', { replace: true });
  }
  const url = entity.roles.includes('clerk')
    ? `./inbox/list/all`
    : entity.roles.includes('head') && './users/active';
  useEffect(() => {
    navigate(url, { replace: true });
  });
  return null;
};

export default function Entity() {
  return (
    <Routes>
      <Route exact path="" element={<RedirectToDefaultStatus />} />
      <Route path="users/active" element={<ActiveUsers />} />
      <Route path="users/pending" element={<PendingUsers />} />
      <Route exact path="inbox/list" element={<RedirectToDefaultStatus />} />
      <Route exact path="inbox/list/:status" element={<DeclarationsList />} />
      <Route exact path="inbox/show/:inboxId" element={<DeclarationShow />} />
      <Route path="*" element={<NotFoundInner />} />
    </Routes>
  );
}
