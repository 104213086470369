import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from './styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export default function Subheader(props) {
  const classes = useStyles();
  return (
    <Fragment>
      {props.title ? (
        <ListItem button onClick={props.onClick}>
          <ListItemText
            classes={{ primary: classes.primary }}
            className={clsx(props.nested && classes.nested)}
          >
            {props.title}
          </ListItemText>
          {props.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      ) : (
        ''
      )}
    </Fragment>
  );
}
Subheader.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  nested: PropTypes.bool,
  onClick: PropTypes.func,
};
