// @flow
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BaseText from './BaseText';

type Props = {
  children: React.DOM,
};

type Output = React.DOM;

export default function NormalText(props: Props): Output {
  return (
    <Typography variant="body1" {...props}>
      <BaseText {...props} />
    </Typography>
  );
}
NormalText.propTypes = {
  i18n: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
