import React, { Fragment, useEffect, useMemo } from 'react';
import { find } from 'lodash';
import BasicLayout from '../../layouts/BasicLayout';
import { LabelValueList } from '../../layouts/KeyValueList';
import Button from '../../components/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Title, Paragraph } from '../../components/Typography';
import user from '../../data/user';
import Divider from '@material-ui/core/Divider';
import { useNavigate, useParams } from 'react-router-dom';
import { useResource } from '../../lib/api';
import { useRequireUser } from '../../lib/auth';
import {
  useDeclarationRequestIssue,
  useFieldsToText,
  useTextFormFields,
  useAuthorizationTexts,
} from '../../models/declaration';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  declarationContainer: {
    border: '1px solid #000',
    padding: theme.spacing(4, 4, 2, 4),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    letterSpacing: '0.15em',
  },
  secondaryTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    letterSpacing: '0.15em',
  },
}));

const DeclarationPreview = () => {
  useRequireUser();
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const declarationId = useParams().id;
  const declaration = useResource('my-declarations', declarationId);
  const template = useResource('all-templates', declaration.data.template_id);
  // Get all data related with a field from template and declaration
  const fields = declaration.data.fields.map(field => ({
    ...template.data.fields[field.field_name],
    ...field,
  }));

  const fieldsForList = useMemo(() => {
    return fields
      .filter(field => field.key.startsWith('solemn:'))
      .map(field => {
        const templateField = find(
          template.data.template_type.fields,
          f => f.key === field.key
        );
        let { value } = field;
        if (field.key.includes('recipient')) {
          value = JSON.parse(value).name;
        }
        if (field.type === 'attachment' && value) {
          value = JSON.parse(value).file_id ? 'file.set' : 'file.not_set';
        }
        return { ...field, value, label: templateField.label };
      });
  }, [fields]);

  const fieldsForListKeys = [
    'solemn:recipient',
    'solemn:firstname',
    'solemn:surname',
    'solemn:afm',
    'solemn:email',
    'solemn:tel',
    'solemn:father_fullname',
    'solemn:mother_fullname',
    'solemn:birth_date',
    'solemn:birth_place',
    'solemn:adt',
    'solemn:residence',
    'solemn:street',
    'solemn:street_number',
    'solemn:tk',
  ];
  const [, , fieldsForText] = useTextFormFields(declaration.data);
  const declarationText = useFieldsToText(fieldsForText, template.data.text);
  const {
    fetch: issueRequest,
    loading,
    loaded,
    error,
  } = useDeclarationRequestIssue(declarationId);

  useEffect(() => {
    if (declaration.data.state === 'requested') {
      navigate('/dilosi/' + declarationId + '/sms');
    }
  }, [declaration.data]);

  useEffect(() => {
    if (loaded && !error) {
      navigate('/dilosi/' + declarationId + '/sms');
    }
  }, [loaded, error]);

  const recipient = [
    {
      label: t('label.recipient'),
      value: declaration.data.recipient_name,
      origin: 'user',
    },
  ];
  return (
    <Fragment>
      <div className={classes.declarationContainer}>
        <Title size="md" className={classes.title}>
          {t('declaration_final_preview.decl_title')}
        </Title>
        <Paragraph>
          {t('declaration.content could be checked')}
          <br />
          <br />
          <span style={{ fontSize: '0.8rem' }}>
            <i>
              <b>* </b>
              {t('hint.aade')}
            </i>
          </span>
        </Paragraph>
        <LabelValueList data={fieldsForList} keys={fieldsForListKeys} />
        <Paragraph
          style={{
            paddingTop: 24,
            paddingBottom: 16,
          }}
        >
          <Markdown
            source={template.data.template_type.preamble}
            escapeHtml={false}
          />
        </Paragraph>
        <Paragraph
          style={{
            paddingBottom: 24,
          }}
        >
          {declarationText}
        </Paragraph>
        <Divider />
        {template.data.template_type.footnotes.map((footnote, index) => (
          <Paragraph key={index}>{footnote}</Paragraph>
        ))}
      </div>
      <div />
      <Box className={classes.buttonContainer}>
        <Button disabled={loading} onClick={issueRequest}>
          {t('declaration_final_preview.publish')}
        </Button>
      </Box>
    </Fragment>
  );
};

export function AuthorizationView({ declaration, template, t }) {
  const classes = useStyles();
  const [declarationText, authorized, text] = useAuthorizationTexts(
    declaration,
    template
  );
  return (
    <div className={classes.declarationContainer}>
      <Title size="md" className={classes.title}>
        {t('declaration_final_preview.auth_title')}
      </Title>
      <Paragraph>{declarationText}</Paragraph>
      <Title size="md" className={classes.secondaryTitle}>
        {t('declaration_final_preview.auth_subtitle')}
      </Title>
      <Paragraph>{authorized}</Paragraph>
      <Paragraph
        style={{
          paddingBottom: 24,
          marginTop: 50,
        }}
      >
        {text}
      </Paragraph>
    </div>
  );
}

AuthorizationView.propTypes = {
  declaration: PropTypes.object,
  template: PropTypes.object,
  t: PropTypes.func.isRequired,
};

const AuthorizationPreview = ({ t }) => {
  useRequireUser();
  const classes = useStyles();
  const navigate = useNavigate();
  const declarationId = useParams().id;
  const declaration = useResource('my-declarations', declarationId);
  const template = useResource('all-templates', declaration.data.template_id);
  // Get all data related with a field from template and declaration
  // const fields = declaration.data.fields.map(field => ({
  //   ...template.data.fields[field.field_name],
  //   ...field,
  // }));
  // let fieldsForList = fields.filter(field =>
  //   field.field_name.startsWith('solemn:')
  // );
  // fieldsForList.unshift({
  //   field_name: 'recipient_name',
  //   label: 'Προς',
  //   value: declaration.data.recipient_name,
  //   origin: '',
  // });
  const {
    fetch: issueRequest,
    loading,
    loaded,
    error,
  } = useDeclarationRequestIssue(declarationId);

  useEffect(() => {
    if (declaration.data.state === 'requested') {
      navigate('/dilosi/' + declarationId + '/sms');
    }
  }, [declaration.data]);

  useEffect(() => {
    if (loaded && !error) {
      navigate('/dilosi/' + declarationId + '/sms');
    }
  }, [loaded, error]);

  return (
    <Fragment>
      <AuthorizationView declaration={declaration} template={template} t={t} />
      <div />
      <Box className={classes.buttonContainer}>
        <Button disabled={loading} onClick={issueRequest}>
          {t('declaration_final_preview.publish')}
        </Button>
      </Box>
    </Fragment>
  );
};

AuthorizationPreview.propTypes = {
  t: PropTypes.func.isRequired,
};

const Main = ({ template, t }) => {
  const component =
    template.data.template_type.refname === 'authorization' ? (
      <AuthorizationPreview t={t} />
    ) : (
      <DeclarationPreview />
    );
  return component;
};

export default function FormEditable(props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const declaration = useResource('my-declarations', id);
  const template = useResource('all-templates', declaration.data.template_id);
  const templateType = template.data.template_type.refname;
  const windowTitle =
    templateType === 'solemn_declaration'
      ? 'decl_page_title'
      : 'auth_page_title';
  return (
    <BasicLayout
      title={t('declaration_final_preview.' + windowTitle)}
      captionRight={t('declaration_final_preview.caption_right')}
      captionLeft={template.data.shortname}
      hasBackButton
      isLoggedIn={true}
      name={`${user.firstName} ${user.lastName}`}
      main={<Main template={template} declaration={declaration} t={t} />}
    />
  );
}
