import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BasicLayout from '../../layouts/BasicLayout';
import { useUser } from '../../lib/auth';
import { useParams } from 'react-router-dom';
import { useResource } from '../../lib/api';
import Sidebar from './Sidebar';
import { Title, Caption } from '../../components/Typography';
import DeclarationForm from '../../components/Declaration/DeclarationForm';
import { useIsUserOwner } from '../../models/declaration';

const Main = props => {
  return (
    <>
      <Caption>{props.template.shortname}</Caption>
      <Title size="xl">{'show.overview.title'}</Title>
      <DeclarationForm {...props} />
    </>
  );
};

Main.propTypes = {
  declaration: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};

export default function ShowDeclaration(props) {
  const user = useUser();

  const { ref } = useParams();
  const { data: declaration } = useResource('declarations', ref);
  const { data: template } = useResource('all-templates', declaration.template_id);
  const isCurrentUser = useIsUserOwner(declaration);
  const name = useMemo(() => {
    return user ? `${user.firstName} ${user.surname}` : null;
  }, [user]);

  const nextProps = { declaration, template };
  return (
    <BasicLayout
      title={false}
      captionRight={false}
      captionLeft={false}
      hasBackButton
      printOnlyContent
      isLoggedIn={true}
      name={name}
      main={<Main isOwner={isCurrentUser} {...nextProps} />}
      side={<Sidebar isOwner={isCurrentUser} {...nextProps} />}
    />
  );
}
