import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Question from '../components/Question';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  labels: {
    padding: theme.spacing(1, 0),
  },
  formControl: {
    margin: theme.spacing(0, 0, 2, 0),
    width: '100%',
  },
  dense: {
    margin: theme.spacing(1, 0),
  },
  formControlPrimaryLabel: {
    fontWeight: 'bolder',
    lineHeight: '1.5em',
  },
  formControlSecondaryLabel: {
    fontWeight: 'normal',
    color: theme.palette.grey['700'],
  },
  formControlLabelActive: {
    backgroundColor: theme.palette.grey['200'],
    border: `2px solid ${theme.palette.primary.main}`,
  },
  formControlLabel: {
    width: '100%',
    alignItems: 'flex-start',
    fontWeight: 'bolder',
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5, 0),
  },
  error: {
    borderLeft: '4px solid',
    borderLeftColor: theme.palette.error.main,
    '& .errorMsg': {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(1),
      fontSize: '0.9rem',
    },
  },
}));

const scrollToRef = ref => {
  // ref.current.scrollIntoView();
};
export function RadioButton({
  dense,
  currentValue,
  value,
  text,
  then,
  expandable,
  Content,
  Expand,
}) {
  const classes = useStyles();
  const myRef = useRef(null);
  const selected = value === currentValue;
  const noMoreQuestions = value === currentValue && !then;
  const moreQuestions = value === currentValue && typeof then !== 'undefined';
  const { t } = useTranslation();
  useLayoutEffect(() => {
    moreQuestions && scrollToRef(myRef);
  });
  return (
    <FormControlLabel
      className={clsx(
        classes.formControlLabel,
        dense && classes.dense,
        noMoreQuestions && classes.formControlLabelActive,
      )}
      ref={myRef}
      value={value}
      control={<Radio className={classes.control} color="primary" />}
      label={
        <div className={classes.labels}>
          <Typography
            className={classes.formControlPrimaryLabel}
            variant="subtitle1"
          >
            {t(text.primary)}
          </Typography>
          <Typography
            className={classes.formControlSecondaryLabel}
            variant="subtitle1"
          >
            {t(text.secondary)}
          </Typography>
          {moreQuestions ? (
            <Question dense expanded expandable={expandable} {...then} />
          ) : (
            ''
          )}
          {Content && <Content />}
          {selected && Expand && <Expand />}
        </div>
      }
    />
  );
}
RadioButton.propTypes = {
  dense: PropTypes.bool,
  expandable: PropTypes.bool,
  currentValue: PropTypes.node,
  value: PropTypes.node,
  then: PropTypes.shape({
    title: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
  }),
  text: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
  }),
};
RadioButton.defaultProps = {};

export default function RadioButtonsGroup(props) {
  const classes = useStyles();
  const currentValue = props.value;
  const handleChange = event => {
    const currentQuestion =
      props.options.find(q => q.value === event.currentTarget.value) || {};
    props.onChange(event, currentQuestion);
  };
  return (
    <div>
      <FormControl
        component="fieldset"
        className={clsx(
          classes.formControl,
          props.dense && classes.dense,
          props.error && classes.error
        )}
      >
        <RadioGroup
          aria-label="gender"
          name={props.name}
          value={currentValue}
          onChange={handleChange}
        >
          {props.options.map((props, index) => (
            <RadioButton key={index} {...props} currentValue={currentValue} />
          ))}
        </RadioGroup>
        {props.error && <span className="errorMsg">{props.helperText}</span>}
      </FormControl>
    </div>
  );
}
RadioButtonsGroup.propTypes = {
  dense: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
};
RadioButtonsGroup.defaultProps = {
  value: '',
  options: [],
};
