// @flow
import type { Node } from 'react';
import type { RequestInit } from 'jsdom';
import React, {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import LRU from 'lru-cache';
import { useTranslation } from 'react-i18next';

const CACHE = new LRU(100);
const TMP_CACHE = new LRU({ max: 100, maxAge: 100 });
const ERROR_CACHE = {};
const PROMISE_CACHE = {};

type RequestState = {
  data: ?any,
  loading: boolean,
  loaded: boolean,
  options: Object,
  error: ?any,
};

var hasCaptureStackTrace = 'captureStackTrace' in Error;

/*
  The type of `error` variable of the `useRequest` hook result.

  We expect a request to fail for at least one reason (`error` property). In
  case of multiple errors, developers may use `errors` property to construct
  a more verbose error report.
*/
export class APIError extends Error {
  error = null;
  errors = null;
  status = 200;

  constructor(errors, status, ...args) {
    super(...args);
    if (hasCaptureStackTrace) {
      // V8 specific method.
      Error.captureStackTrace(this, this.constructor);
    }
    this.error = (errors && errors.length && errors[0]) || errors;
    this.errors = errors;
    this.status = status;
  }
}

const INIT_STATE = {
  data: null,
  loading: false,
  loaded: false,
  error: null,
  options: {},
};

function requestReducer(state: RequestState, action) {
  switch (action.type) {
    case 'request':
      return { ...state, loading: true, loaded: false, error: null };
    case 'result':
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.data,
        error: null,
      };
    case 'options':
      return {
        ...state,
        options: action.options,
      };
    case 'error':
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      console.error(action);
      throw Error('Invalid Auth action.');
  }
}

function buildCacheKey(url, opts) {
  // TODO: smarter key generation (url params, headers, etc.)
  return (url || '') + '//' + (JSON.stringify(opts) || '');
}

export async function parseErrors(response: Response) {
  const errors = [];
  const error = {
    message: response.statusText,
    code: response.status,
    meta: {}, // generic error details (for debugging or fine-grained error display)
  };

  // do our best to resolve as more specific details for the error
  try {
    const json = await response.json();
    error.meta = json;
    // this is the way synnefo reports errors
    try {
      const apiErrors = Object.keys(json);
      errors.push(
        ...apiErrors.map(key => {
          return {
            ...json[key],
          };
        })
      );
    } catch (err) {
      console.error('Cannot parse json error', json);
    }
  } catch (err) {
    try {
      const text = await response.text();
      error.message = text;
      error.meta.statusText = response.statusText; // keep reference to statusText
    } catch (err) {}
  }
  return errors;
}

export function useRequest(
  url: string,
  options: ?RequestInit = {},
  consume: boolean = true,
  suspense: boolean = true,
  delay: number = 0, // for debug
  suppressErrors: boolean = false
) {
  // options for window.fetch
  const fetchOptions: RequestInit = useMemo(() => {
    return { method: 'GET', ...(options || {}) };
  }, [options]);

  // reuse cached value between requests (prevent action results)
  const reuse = fetchOptions.method === 'GET';
  const cacheKey = buildCacheKey(url, fetchOptions);

  const cachedValue = CACHE.get(cacheKey);
  // value in short-lived cache to prevent duplicate requests
  const tmpValue = TMP_CACHE.get(cacheKey);
  const error = ERROR_CACHE[cacheKey] || null;
  const promise = PROMISE_CACHE[cacheKey] || null;
  const data = tmpValue || cachedValue || null;
  const loaded = data ? true : !!error;
  const dataSet = useMemo(() => !!options.body, [options.body]);

  // consume state, for action requests
  const [doConsume, setDoConsume] = useState(consume);

  // reset doConsume state when url changes
  useEffect(() => {
    if (consume && !doConsume) {
      setDoConsume(true);
    }
  }, [url]);
  const rendered = useRef(true);
  const [state, dispatch] = useReducer(requestReducer, {
    ...INIT_STATE,
    data,
    loaded,
    error,
    options: fetchOptions,
    loading: false,
  });

  if (!reuse) {
    CACHE.del(cacheKey);
  }

  const makeRequest = useCallback(
    async function makeRequest(suspenseMode: boolean = false, url) {
      // there is no need to call dispatch in suspense-trigger mode
      const report =
        suspenseMode || !rendered.current ? action => {} : dispatch;

      const tmpValue = TMP_CACHE.get(cacheKey);
      if (tmpValue) {
        // no need for manual invalidation as we use lru-cache.
        return Promise.resolve(tmpValue);
      }

      async function doFetch() {
        try {
          const response = await window.fetch(url, state.options);
          if (!response.ok) {
            // status code not in 200-299 range
            const errors = await parseErrors(response);
            throw new APIError(errors, response.status);
          }
          const result = await response.json();

          return new Promise((resolve, reject) => {
            if (PROMISE_CACHE[cacheKey]) {
              delete PROMISE_CACHE[cacheKey];
            }
            CACHE.set(cacheKey, result);
            if (suspenseMode) {
              TMP_CACHE.set(cacheKey, result);
              resolve();
            } else if (rendered.current) {
              report({ type: 'result', data: result });
              resolve();
            }
          });
        } catch (err) {
          console.error(err);
          ERROR_CACHE[cacheKey] = err;
          report({ type: 'error', error: err });
        }
      }

      report({ type: 'request' });
      if (delay) {
        window.setTimeout(() => {
          doFetch();
        }, delay);
      } else {
        doFetch();
      }
    },
    [state, dispatch]
  );

  function invalidate() {
    CACHE.del(cacheKey);
  }

  function refetch() {
    // prevent refetch while a previous fetch is already in progress
    if (!doConsume) {
      // this will trigger a makeRequest call
      setDoConsume(true);
    }
  }

  // update state with new options
  useEffect(() => {
    dispatch({ type: 'options', options });
  }, [options]);

  useEffect(() => {
    if (doConsume && !state.loading && rendered.current) {
      setDoConsume(false);
      makeRequest(false, url);
    }
  }, [doConsume, state.loading, rendered.current]);

  useEffect(() => {
    return function cleanup() {
      rendered.current = false;
    };
  }, []);

  // make sure (???) we suspend only on first render
  if (
    consume &&
    suspense &&
    rendered.current &&
    !state.loading &&
    !state.loaded
  ) {
    // throw a Promise to activate suspense mode
    if (!state.error && data === null) {
      if (promise) {
        throw promise;
      }
      const _promise = makeRequest(true, url);
      PROMISE_CACHE[cacheKey] = _promise;
      throw _promise;
    }
  }

  if (state.error && !suppressErrors) {
    if (!state.error.status || state.error.status > 400) {
      console.error(state.error);
      throw state.error;
    }
    // TODO: handle errors logic
  }

  return {
    ...state,
    dataSet,
    fetch: refetch,
    invalidate,
    url,
  };
}

type APIConfig = {
  baseURL: string,
};

type APIContextData = {
  config: APIConfig,
  token: ?string,
};

const APIContext = createContext<APIContextData>({
  config: {},
  token: null,
});

export function APIProvider({
  token = null,
  config = {},
  children,
}: {
  token: ?string,
  children: Node,
  config: APIConfig,
}) {
  const data = useMemo(() => ({ token, config }), [token, config]);
  return <APIContext.Provider value={data}>{children}</APIContext.Provider>;
}

export function useAPI() {
  return useContext(APIContext);
}

function buildURL(
  base: string,
  resource: string,
  path: ?string | number,
  query: ?Object
) {
  let url = base;
  url += '/' + resource + '/';
  query = query || {};
  if (path) {
    url += path.toString() + '/';
  }
  if (Object.keys(query).length) {
    url += '?';
  }
  Object.keys(query).forEach(k => {
    url += encodeURIComponent(k) + '=' + encodeURIComponent(query[k] || '');
    url += '&';
  });
  return url;
}

export function useFetchOptions(
  name: string,
  id: ?string | number,
  query: ?Object,
  data: ?Object,
  options: ?RequestInit
): RequestInit {
  const {
    config: { baseURL },
    token,
  } = useAPI();

  const queryKey = JSON.stringify(query);
  const url = useMemo(() => buildURL(baseURL, name, id, query), [
    baseURL,
    name,
    queryKey,
    id,
    token,
  ]);

  const optionsKey = JSON.stringify(options);
  const isMultipart = data instanceof FormData;
  const dataKey = isMultipart ? data : JSON.stringify(data);
  const _options = useMemo(() => {
    const _options = options || {};
    const result = {};
    result.method = _options.method || (data ? 'POST' : 'GET');
    const headers = { ...(_options.headers || {}) };
    if (token) {
      headers.Authorization = `Token ${token}`;
    }
    if (data) {
      if (isMultipart) {
        result.body = data;
      } else {
        headers['content-type'] = 'application/json';
        result.body = JSON.stringify(data);
      }
    }
    result.headers = headers;
    return result;
  }, [optionsKey, dataKey]);
  return [url, _options];
}

// export function useCreate(resource: string, data: Object, ...args) {
//   return useAction(name, null, data);
// }

// export function useAction(resource: string, query: ?Object, data: ?Object, ...args) {
//   const [url, options] = useResourceParams(name, query);
//   return useRequest();
// }

const DEFAULT_PAGINATION_LIMIT = 1000;
const DEFAULT_LIMIT_OPTIONS = [10, 30, 60, 100];

export function useResourceMany(
  resource: string,
  query: ?Object,
  options: ?RequestInit,
  ...other: any[]
) {
  // allow developer to set a custom limit, fallback to a common value
  const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
  const [pageParams, setPageParams] = useState({ offset: '0', limit });

  // update options when limit is changed
  useEffect(() => {
    if (pageParams.limit !== limit) {
      setPageParams({ ...pageParams, limit: limit });
    }
  }, [limit]);

  // update user provided query with pagination params
  const q = useMemo(() => {
    return { ...pageParams, ...query };
  }, [query, pageParams]);

  const { data, ...rest } = useResourceManyBase(resource, q, options, ...other);

  // extract data/metadata from response
  const resp = data ? data.data : data;
  const meta = data ? data.meta : data;

  const totalPages = useMemo(() => data?.meta?.total || 1, [data]);
  const total = useMemo(() => data?.meta?.count || 0, [data]);
  const limitOptions = useMemo(() => DEFAULT_LIMIT_OPTIONS, []);

  const page = useMemo(() => {
    const offset = parseInt(pageParams.offset) || 0;
    const _limit = parseInt(pageParams.limit) || parseInt(limit);
    return offset / _limit;
  }, [pageParams]);

  // set params to specific page
  const setPage = useCallback(
    page => {
      if (page > totalPages) {
        page = totalPages;
      }
      if (page <= 0) {
        page = 0;
      }
      const _limit = limit || DEFAULT_PAGINATION_LIMIT;
      const offset = (_limit * page).toString();
      setPageParams({ offset, limit });
    },
    [setPageParams]
  );

  const hasNext = page < totalPages - 1;
  const hasPrevious = page > 0;
  return {
    data: resp,
    meta,
    page,
    limit,
    totalPages,
    total,
    limitOptions,
    setPage,
    setLimit,
    hasNext,
    hasPrevious,
    ...rest,
  };
}

export function useResourceManyBase(
  resource: string,
  query: ?Object,
  options: ?RequestInit,
  ...other: any[]
) {
  const [url, _options] = useFetchOptions(resource, null, query, null, options);
  return useRequest(url, _options, ...other);
}

export function useResource(
  resource: string,
  id: string | number,
  query: ?Object,
  options: ?RequestInit,
  ...other: any[]
) {
  const [url, _options] = useFetchOptions(resource, id, query, null, options);
  return useRequest(url, _options, ...other);
}

export function useResourceAction(
  resource: string,
  id: ?string | number,
  method: ?string = 'POST',
  data: ?Object = null,
  query: ?Object,
  options: ?RequestInit,
  ...other: any[]
) {
  const _options = useMemo(() => {
    return { method, ...(options || {}) };
  }, [options, method, id, resource]);
  const [url, __options] = useFetchOptions(resource, id, query, data, _options);
  return useRequest(url, __options, false, true, 0, true);
}

export class ApiErrors extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    if (this.state.hasError) {
      const Comp = this.props.fallback;
      return <Comp error={this.state.error} />;
    }
    return this.props.children;
  }
}

// convert a badrrequest with `invalid_field` keys to
// a key/error object for use in FormBuilder
export function useApiFormErrors(apiError: APIError) {
  const { t } = useTranslation();
  return useMemo(() => {
    const errors = {};
    // error is an APIError exception
    const error = apiError ? apiError.error?.data : {};
    if (error.invalid_fields) {
      error.invalid_fields.forEach(err => {
        let msg = 'form.field.invalid';
        if (err.expected_type) {
          msg = t(`form.field.expected.${err.expected_type}`);
        }
        errors[err.invalid_field] = {
          message: msg,
        };
      });
    }
    return errors;
  }, [apiError]);
}
