import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useResource } from '../../lib/api';
import { Title } from '../Typography';
import AcceptReject from './AcceptReject';
import SaveEmailRevoke from './SaveEmailRevoke';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(4, 0),
  },
  sidebarTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const DeclarationInboxActions = props => {
  const resourceUrl = props.entityId
    ? `entity/${props.entityId}/inbox/${props.inboxId}`
    : `my-inbox/${props.inboxId}`;

  // the true here is to suppress errors
  const resource = useResource(resourceUrl);
  const inboxItem = resource.data || {};
  const showSaveEmail =
    inboxItem.inbox_status === 'accepted' || props.isUserOwner;
  return (
    <Actions
      inboxItem={inboxItem}
      isUserOwner={false}
      isAdmin={true}
      showSaveEmail={false}
      {...props}
    />
  );
};

const Actions = ({
  inboxItem,
  showEmail,
  isUserOwner,
  isAdmin,
  template,
  declaration,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Title size="md" className={classes.sidebarTitle}>
        Τα επόμενα βήματα
      </Title>
      {(isAdmin || isUserOwner) && <AcceptReject inboxItem={inboxItem} />}
      <SaveEmailRevoke
        template={template}
        declaration={declaration}
        showRevoke={isUserOwner}
        showEmail={showEmail}
        isAdmin={isAdmin}
      />
    </div>
  );
};

Actions.propTypes = {
  inboxItem: PropTypes.object,
  showEmail: PropTypes.bool,
  isUserOwner: PropTypes.bool,
  template: PropTypes.object,
  declaration: PropTypes.object,
};

export default function DeclarationActions(props) {
  const { entityId, inboxId } = useParams();

  return (
    <>
      {inboxId || entityId ? (
        <DeclarationInboxActions
          {...props}
          isUserOwner={props.isOwner}
          showRevoke={props.isOwner}
          inboxId={inboxId}
          entityId={entityId}
        />
      ) : (
        <Actions
          isUserOwner={props.isOwner}
          isAdmin={props.isAdmin}
          showEmail={props.isOwner}
          {...props}
        />
      )}
    </>
  );
}

DeclarationActions.propTypes = {
  declaration: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};
