import React, { useMemo } from 'react';
import { HEALTH_DISTRICT_OPTIONS, DISTRICT_OPTIONS } from '../lib/data';

const REQUIRED = { required: true };

const DECLARATION_REQUIRED = {
  'solemn:email': REQUIRED,
  'solemn:firstname': REQUIRED,
  'solemn:surname': REQUIRED,
  'solemn:father_fullname': REQUIRED,
  'solemn:mother_fullname': REQUIRED,
  'solemn:birth_date': REQUIRED,
  'solemn:birth_place': REQUIRED,
  'solemn:adt': REQUIRED,
  'solemn:afm': REQUIRED,
  'solemn:tel': REQUIRED,
  'solemn:residence': REQUIRED,
  'solemn:street': REQUIRED,
  'solemn:street_number': REQUIRED,
  'solemn:tk': REQUIRED,
};

export const TEXT_FIELDS_OVERRIDES = {
  'ADEIA-COVID19': {
    apo: {
      layout: { sm: 6 },
    },
    eos: {
      layout: { sm: 6 },
    },
    kids: {
      example:
        '2 τέκνα, ετών 2, ετών 3, 42ος Βρεφονηπιακός Σταθμός Αθηνών\n2 τέκνα, ετών 2, ετών 3, 42ος Βρεφονηπιακός Σταθμός Αθηνών\n2 τέκνα, ετών 2, ετών 3, 42ος Βρεφονηπιακός Σταθμός Αθηνών',
    },
  },
  'EODY-1': {
    ...DECLARATION_REQUIRED,
    district: {
      fieldType: 'select',
      required: true,
      options: DISTRICT_OPTIONS,
    },
    documents: {
      required: true,
    },
  },
  'EODY-2': {
    ...DECLARATION_REQUIRED,
    district: {
      fieldType: 'select',
      required: true,
      options: DISTRICT_OPTIONS,
    },
    documents: {
      required: true,
    },
  },
  'EODY-3': {
    ...DECLARATION_REQUIRED,
    health_district: {
      fieldType: 'select',
      required: true,
      options: HEALTH_DISTRICT_OPTIONS,
    },
    documents: {
      required: true,
    },
  },
};

export function useTextFieldsOverrides(tpl) {
  return useMemo(() => {
    if (tpl.refname.startsWith('ADEIA-COVID19')) {
      return TEXT_FIELDS_OVERRIDES['ADEIA-COVID19'];
    }
    return TEXT_FIELDS_OVERRIDES[tpl.refname] || {};
  }, [tpl]);
}
