import React, { useMemo } from 'react';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const Select = React.forwardRef(function WrappedInput({ ...props }, ref) {
  const options = useMemo(() => {
    const groupsEnabled = !!(props.options && props.options)[0].group;
    const options = props.options || [];
    if (groupsEnabled) {
      return options.reduce(
        ([group, options], option) => {
          if (option.group !== group) {
            options.push({
              isGroup: true,
              label: option.group,
            });
          }
          options.push(option);
          return [option.group, options];
        },
        [null, []]
      )[1];
    } else {
      return options;
    }
  }, [props.required, props.options]);

  return (
    <FormControl error={props.error} style={{ display: 'flex', flex: 1 }}>
      <MUISelect
        variant="outlined"
        style={{ flex: 1, display: 'flex' }}
        ref={ref}
        {...props}
      >
        {options.map(item => {
          if (item.isGroup) {
            return (
              <ListSubheader key={item.value} style={{ position: 'relative' }}>
                {item.label}
              </ListSubheader>
            );
          } else {
            return (
              <MenuItem key={item.value} value={item.label}>
                {item.label}
              </MenuItem>
            );
          }
        })}
      </MUISelect>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
});

export default Select;
