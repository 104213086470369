import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { TablePagination } from '@material-ui/core';
import { useMessages } from '../../../utils/messages';
import CreateNewRequest from './NewRequest';
import useMyRequestsStore from '../store/requests';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  column: {
    width: '25%',
  },
}));

export default function MyRequestsTable(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [myRequests, { getMyRequests }] = useMyRequestsStore();
  useEffect(() => {
    getMyRequests();
  }, []);
  const {
    hasNext,
    hasPrevious,
    setLimit,
    setPage,
    total,
    limitOptions,
    limit,
    page,
  } = myRequests;

  const rows = myRequests
    .filter(myRequest => myRequest.claim.role !== 'author')
    .map(filteredRequests => {
      const lastName = filteredRequests.claim.person.surname;
      const firstName = filteredRequests.claim.person.firstname;
      const fullName = firstName.concat(' ', lastName);
      const afm = filteredRequests.claim.person.afm;
      const role = filteredRequests.claim.role;
      const requestedEntityName = filteredRequests.claim.entity_name;
      const status =
        filteredRequests.response === null
          ? 'Σε αναμονή'
          : filteredRequests.response === 'accept'
          ? 'Αποδεκτό'
          : filteredRequests.response === 'reject'
          ? 'Απορριφθέν'
          : 'Άγνωστο';
      return { fullName, afm, role, requestedEntityName, status };
    });
  return (
    <>
      <Title size="md">Τα αιτήματά μου για πρόσβαση σε θυρίδα</Title>
      <CreateNewRequest getMyRequests={getMyRequests} />
      {rows.length === 0 ? (
        <div className={classes.container}>
          Δεν έχετε υποβάλει αίτημα πρόσβασης σε θυρίδα
        </div>
      ) : (
        <TableContainer component={Paper} elevation={0} square>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Όνομα Θυρίδας</TableCell>
                <TableCell>Ρόλος</TableCell>
                <TableCell>ΑΦΜ</TableCell>
                <TableCell>Κατάσταση Αιτήματος</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.container}>
              {rows.lengtht === 0 ? (
                ''
              ) : (
                <>
                  {rows.map(row => (
                    <TableRow key={row.id}>
                      <TableCell className={classes.column}>
                        {row.requestedEntityName}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {t(`dashboard.roles.${row.role}`)}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {row.afm}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {row.status}
                      </TableCell>
                    </TableRow>
                  ))}{' '}
                </>
              )}
            </TableBody>
            {/* <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={limitOptions}
                  count={total}
                  page={page}
                  rowsPerPage={limit}
                  onChangeRowsPerPage={e => {
                    setLimit(e.target.value);
                  }}
                  onChangePage={(e, p) => {
                    setPage(p);
                  }}
                  backIconButtonProps={{ disabled: !hasPrevious }}
                  nextIconButtonProps={{ disabled: !hasNext }}
                />
              </TableRow>
            </TableFooter> */}
          </Table>
        </TableContainer>
      )}
    </>
  );
}
