import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BaseText from './BaseText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    xl: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.25rem',
      },
      margin: theme.spacing(0, 0, 6, 0),
      fontSize: '3rem',
    },
    lg: {
      margin: theme.spacing(0, 0, 5, 0),
      fontSize: '2.25rem',
    },
    md: {
      margin: theme.spacing(0, 0, 4, 0),
      fontSize: '1.5rem',
    },
    sm: {
      margin: theme.spacing(0, 0, 3, 0),
      fontSize: '1.2rem',
    },
    noMargin: {
      margin: 0,
    },
  };
});

const getComponentBySize = size => {
  switch (size) {
    case 'xl':
      return 'h1';
    case 'lg':
      return 'h2';
    case 'md':
      return 'h3';
    case 'sm':
      return 'h4';
    default:
      return 'h4';
  }
};

export default function Title(props) {
  const classes = useStyles();
  const Component = getComponentBySize(props.size);
  return (
    <Component
      className={clsx(
        props.className,
        classes[props.size],
        props.noMargin && classes.noMargin
      )}
    >
      <BaseText {...props} />
    </Component>
  );
}
Title.propTypes = {
  i18n: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
