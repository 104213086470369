import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserPermissions from './permissions';
import { MyRequestsStore } from '../../store/requests';

export default function TemplatesPage() {
  return (
    <MyRequestsStore>
      <Routes>
        <Route path="/list" element={<UserPermissions />} />
      </Routes>
    </MyRequestsStore>
  );
}
