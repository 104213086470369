import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FormHelperText from '@material-ui/core/FormHelperText';

const TYPES_MAP = {
  string: 'text',
  int: 'number',
  text: 'text',
  date: 'date',
  attachment: 'file',
  file: 'file',
};

const Input = React.forwardRef(function WrappedInput(
  {
    id,
    type,
    label,
    helperText,
    fullWidth = true,
    multiline,
    rows,
    className,
    ...props
  },
  ref
) {
  // if enforced to multiline use true. Derive from type otherwise.
  multiline = multiline === true || type === 'text';
  // use explicit rows value if set. Derive from multiline otherwise.
  rows = rows || (multiline ? 4 : 1);
  // translate dilosi type to HTML Input type
  const fieldType = TYPES_MAP[type || 'text'] || 'text';
  const labelProps = useMemo(() => {
    if (fieldType.startsWith('date')) {
      return { shrink: true };
    }
    return {};
  }, [fieldType]);

  return (
    <TextField
      id={id}
      type={fieldType}
      label={label}
      InputLabelProps={labelProps}
      helperText={helperText}
      className={className}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      margin="normal"
      variant="outlined"
      inputRef={ref}
      {...props}
    />
  );
});
Input.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
};

export default Input;

const useFileInputStyles = makeStyles(theme => {
  return {
    root: {
      '& .label': {
        fontWeight: 'bold',
      },
      marginTop: theme.spacing(2),
      border: '1px solid',
      borderColor: '#dddddd',
      margin: theme.spacing(0),
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    errorMsg: {
      color: theme.palette.error.main,
    },
    value: {},
    valueNotSet: {
      color: theme.palette.warning.main,
    },
    input: {
      color: 'transparent',
    },
    label: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '0.9em',
      marginLeft: '-150px',
    },
  };
});

export const FileInput = ({ value, onChange, ...props }) => {
  const { t } = useTranslation();
  const classes = useFileInputStyles();
  value = value || {
    fileName: null,
    file: null,
  };
  const fileSet = !!value.fileName;
  const fileName = value.fileName || 'file_upload.not_set';

  function onFileChange(evt, value) {
    const files = evt.target.files;
    const file = files.length ? files[0] : undefined;
    onChange({
      fileName: file ? file.name : undefined,
      file,
    });
  }

  return (
    <>
      <Grid
        container
        className={clsx(props.className, classes.root, {
          [classes.error]: props.error,
        })}
        spacing={2}
      >
        {fileName && (
          <Grid item xs={12}>
            <span className="label">{t('label.file')}: </span>
            <em>{t(fileName)}</em>
          </Grid>
        )}
        <Grid item xs={12}>
          <input
            {...props}
            id={props.name}
            className={classes.input}
            type="file"
            onChange={onFileChange}
          />
          <label
            htmlFor={props.name}
            className={clsx(classes.label, {
              [classes.labelNotSet]: fileSet,
            })}
          >
            {fileSet ? t('file_upload.replace') : t('file_upload.upload')}
          </label>
        </Grid>
      </Grid>
      {props.helperText && (
        <FormHelperText className={classes.errorMsg}>
          {props.helperText}
        </FormHelperText>
      )}
    </>
  );
};
