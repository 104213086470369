import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Redirect from '../../lib/Redirect';
import { Caption } from '../../components/Typography';
import BackButton from '../../components/BackButton';
import PersonalDetails from './PersonalDetails';
import MyInbox from './MyInbox';
import BasicLayout from '../../layouts/BasicLayout';
import { useRequireUser } from '../../lib/auth';
import Sidebar from './Sidebar';
import NotFound from '../NotFound';
import { MyInboxStore } from '../../store/myInbox';
import { OutboxStore } from '../../store/outbox';

const Main = () => {
  return (
    <>
      <BackButton />
      <Caption>vault.title</Caption>
      <OutboxStore>
        <MyInboxStore>
          <Routes>
            <Redirect from="" to="sent" />
            <Route path="personal-details" element={<PersonalDetails />} />
            <Route path=":inbox" element={<MyInbox />} />
            <Route path="" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MyInboxStore>
      </OutboxStore>
    </>
  );
};

export default function ShowRouter() {
  useRequireUser();
  return <BasicLayout main={<Main />} side={<Sidebar />} isLoggedIn={false} />;
}
