import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import govGRTheme from '../themes/govgr';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import Footer from './Footer';
import useAuth, { AuthProvider } from '../lib/auth';
import { APIProvider } from '../lib/api';

const useStyles = theme => ({
  '@global': {
    ul: {
      fontSize: theme.typography.body1.fontSize,
      margin: theme.spacing(0, 0, 4),
    },
    li: {
      margin: theme.spacing(0, 0, 1),
    },
    a: {
      textDecoration: 'underline',
    },
  },
  root: {
    flexDirection: 'column',
    flex: '1 0 auto',
    // height: '100%',
  },
});

export function Fonts() {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
      />
    </>
  );
}

let CONFIG = {};
try {
  CONFIG = require('../config.json');
} catch (err) {}
CONFIG.apiURL = CONFIG.apiURL || process.env.API_URL || '/api';

const AUTH_CONFIG = CONFIG.authConfig || {};
AUTH_CONFIG.userDataURL =
  AUTH_CONFIG.userDataURL || CONFIG.apiURL + '/users/me/';
AUTH_CONFIG.loginURL = AUTH_CONFIG.loginURL || CONFIG.apiURL + '/gsis_login/';
AUTH_CONFIG.tokenURL = AUTH_CONFIG.tokenURL || CONFIG.apiURL + '/token/';

function AuthAPI({ children }) {
  const { token } = useAuth();
  return (
    <APIProvider token={token} config={{ baseURL: CONFIG.apiURL }}>
      {children}
    </APIProvider>
  );
}
AuthAPI.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function App({ theme, children, Fonts, direction, ...props }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <AuthProvider config={AUTH_CONFIG}>
      <AuthAPI>
        <Fonts />
        <CssBaseline />
        <AppInner direction={direction} {...props}>
          {children}
        </AppInner>
      </AuthAPI>
    </AuthProvider>
  );
}

App.defaultProps = {
  theme: govGRTheme,
  Fonts: Fonts,
  direction: 'row',
};

App.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  Fonts: PropTypes.elementType,
  direction: PropTypes.string,
  classes: PropTypes.object,
};

// inner component in order to make theme available
// in use*Styles hooks
const AppInnerBase = function({ children, direction, classes, ...props }) {
  return (
    <Fragment>
      <Grid
        id="content"
        container
        direction={direction}
        className={clsx(classes.root, props.className)}
        {...props}
      >
        {children}
      </Grid>
      <Footer id="footer">hello</Footer>
    </Fragment>
  );
};
AppInnerBase.defaultProps = {
  direction: 'row',
};

AppInnerBase.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  direction: PropTypes.string,
  className: PropTypes.string,
};

const AppInner = withStyles(useStyles, { name: 'MuiGovGRApp' })(AppInnerBase);
AppInner.dispayName = 'AppInner';

export default App;
