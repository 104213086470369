import React, { createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useImmerReducer } from 'use-immer';
import { useAPI } from '../../../lib/api';

const defaultState = [];

const getMyRequests = async function(dispatch, api) {
  const { token } = api;
  const response = await fetch(
    `${api.config.baseURL}/my-permission-requests/`,
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );
  //   debugger;
  const json = await response.json();
  dispatch({ type: 'GET', payload: json.data });
};

const reducer = (draftState, action) => {
  switch (action.type) {
    case 'GET':
      return action.payload;
    default:
      return draftState;
  }
};

export const Context = createContext(defaultState);

export const useActions = dispatch => {
  const api = useAPI();
  return {
    getMyRequests: useCallback(() => getMyRequests(dispatch, api)),
  };
};

export default function useMyRequestsStore() {
  return useContext(Context);
}
export function MyRequestsStore({ children }) {
  const [state, dispatch] = useImmerReducer(reducer, defaultState);
  const actions = useActions(dispatch);
  return (
    <Context.Provider value={[state, actions, dispatch]}>
      {children}
    </Context.Provider>
  );
}
MyRequestsStore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
