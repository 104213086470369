// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

type Props = {
  text: string,
  type?: 'warning' | 'noBackground' | 'dark',
};

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 500,
    display: 'inline-block',
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(.5),
    backgroundColor: theme.palette.grey['400'],
    borderBottom: `1px solid ${theme.palette.grey['500']}`,
  },
}));

const HighLight = ({ text, type = 'noBackground', ...props }: Props) => {
  const classes = useStyles();
  let bgColor;

  switch (type) {
    case 'warning': {
      bgColor = 'gold';
      break;
    }
    case 'dark': {
      bgColor = '#dedfe1';
      break;
    }
    default: {
      // no background color
      bgColor = undefined;
    }
  }

  return (
    <span
      className={clsx(classes.root, bgColor ? classes.badge : undefined)}
      style={{ backgroundColor: bgColor }}
    >
      {text}
    </span>
  );
};

export default HighLight;
