import React from 'react';
import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles,
} from '@material-ui/core/styles';
import logo from '../layouts/logogov.svg';
import useConfig, { useFlags } from '../lib/config';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
export const PORTAL_URL = 'https://gov.gr/';

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.footer.main,
    color: theme.palette.footer.contrastText,
    textAlign: 'center',
    padding: '15px',
  },
  logo: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontSize: '0.85rem',
    color: theme.palette.footer.contrastText,
    '& a': {
      color: theme.palette.footer.link,
    },
  },
}));

const Logo = () => {
  const { t } = useTranslation();
  return (
    <a href="https://gov.gr" style={{ display: 'flex' }}>
      <img src={logo} height={50} alt={t('header.logo_alt_label')} />
    </a>
  );
};


const Footer = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const flags = useFlags();

  return (
    <Grid
      container
      alignContent="center"
      className={styles.container}
      position="static"
    >
      <Grid item xs={12}>
        <Typography variant="body2" className={styles.title}>
          {t('footer.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={styles.logo}>
        <a href={PORTAL_URL}>
          <img height={41} src={logo} alt={t('footer.logo_alt_label')} />
        </a>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={styles.title}>
          Υλοποίηση απο την <a href="https://www.grnet.gr/">ΕΔΥΤΕ</a> με χρήση{' '}
          <a
            href="https://mathe.ellak.gr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.open_source')}
          </a>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className={styles.title}>
          <a href="/terms">Όροι και Προϋποθέσεις</a>
        </Typography>
      </Grid>
      {flags.DASHBOARD_ENABLED && (
        <Grid item xs={12}>
          <Typography variant="body2" className={styles.title}>
            <a href="/dashboard">Συνδεθείτε ως Διαχειριστής</a>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

let theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
      },
      text: {},
      containedPrimary: {
        backgroundColor: '#046ec5',
        color: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected, &$selected:hover': { backgroundColor: '#046ec526' },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: '#046ec5',
      light: 'linear-gradient(358.5deg,#00aeef .09%,#134a86)',
      pale: '#046ec526',
    },
    secondary: {
      main: '#ffffff',
      dark: '#f5f5f5',
    },
    common: {
      black: '#003476',
    },
    footer: {
      main: 'linear-gradient(315deg,#003476 .09%,#134a86)',
      link: '#00aeef',
      contrastText: '#fff',
    },
    success: {
      main: '#00703c',
    },
    error: {
      main: '#ca2e2e',
    },
    warning: {
      main: '#C25400',
    },
    info: {
      main: '#036ec5',
    },
    cookie: {
      main: '#003476',
    },
  },
  typography: {
    htmlFontSize: 14,
  },
  header: {
    logo: {
      component: Logo,
      height: 45,
    },
    border: {
      width: '100%',
      height: 5,
    },
    height: '75px',
  },
  footer: {
    component: Footer,
  },
});

theme = responsiveFontSizes(theme, ['sm', 'md', 'lg']);

export default theme;
