import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import NavMenuSubheader from './NavMenuSubheader';
import NavMenuItem from './NavMenuItem';

const flattenLinks = links => {
  return links.flatMap(link => {
    if ('links' in link) {
      return flattenLinks(link.links);
    } else {
      return link.path.replace(/\//g, '');
    }
  });
};
const renderLinks = props => {
  return props.links.map((link, key) => {
    if ('links' in link) {
      return <NavMenu key={key} {...link} collapse nested={props.root} />;
    } else {
      return (
        <NavMenuItem key={key} {...link} nested={true} root={!props.root} />
      );
    }
  });
};

export default function NavMenu(props) {
  const [open, setOpen] = useState(props.open);
  const location = useLocation();
  const links = useMemo(() => flattenLinks(props.links), [props.links]);
  useEffect(() => {
    if (links.includes(location.pathname)) {
      setOpen(true);
    }
  }, [location.pathname, links]);
  const content = renderLinks(props);
  return (
    <List
      disablePadding
      subheader={
        <NavMenuSubheader
          open={open}
          onClick={() => setOpen(!open)}
          {...props}
        />
      }
    >
      {props.collapse ? (
        <Collapse in={open} timeout={400} unmountOnExit>
          {content}
        </Collapse>
      ) : (
        content
      )}
    </List>
  );
}
NavMenu.propTypes = {
  /* Subheader title that describe the NavMenu content */
  title: PropTypes.string,
  prefix: PropTypes.string,
  open: PropTypes.bool,
  collapse: PropTypes.bool,
  nested: PropTypes.bool,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      as: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      prefix: PropTypes.string,
    })
  ),
};
NavMenu.defaultProps = {
  links: [],
  open: false,
  collapse: true,
  nested: false,
  root: false,
};
