import React, { Fragment } from 'react';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import KeyValueList from '../../layouts/KeyValueList';
import { Title, Paragraph } from '../../components/Typography';
import Spoiler from '../../components/Spoiler';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { useResourceMany } from '../../lib/api';
import {
  useTextFormFields,
  useIsUserOwner,
  useFieldsToText,
} from '../../models/declaration';
import 'moment/locale/el';

const useStyles = makeStyles(theme => ({
  keyValueList: {
    marginBottom: theme.spacing(4),
  },
  noMargin: {
    margin: 0,
  },
  statusRow: {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #efefef',
  },

  state_accepted: {
    color: theme.palette.success.main,
  },

  state_rejected: {
    color: theme.palette.warning.main,
  },

  state_issued: {
    color: theme.palette.info.main,
  },

  state: {
    fontWeight: '600',
  },
  tooltip: {
    textTransform: 'none',
    textDecoration: 'underline',
    fontWeight: '400',
    fontSize: 'inherit',
    padding: theme.spacing(0),
    marginBottom: '3px',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: '#fafafa',
    },
  },
}));

const History = ({ declaration }) => {
  const { t } = useTranslation();
  const { data: history } = useResourceMany(
    `my-declarations/${declaration.id}/history`
  );
  const logs = (
    <List dense>
      {history.map(log => (
        <>
          <ListItem>
            <ListItemText
              primary={t(`history.${log.action}`)}
              secondary={
                <Moment locale="el" format="LLLL">
                  {log.timestamp}
                </Moment>
              }
            />
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
  return (
    <Spoiler label="Δείτε αναλυτικά το ιστορικό ενεργειών" explanation={logs} />
  );
};

export default function DeclarationForm(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const template = props.template;

  const isCurrentUser = props.isOwner;
  const isAdmin = props.isAdmin;
  const [, , fieldsForText] = useTextFormFields(props.declaration);
  const declarationText = useFieldsToText(fieldsForText, props.template.text);

  const declarationFields = {};
  props.declaration.fields.forEach(({ key, value }) => {
    declarationFields[key] = value;
  });

  const declarationData = {};
  const authData = {};
  const isAuthorization = template.template_type.refname === 'authorization';
  const templateType = template.template_type.refname;
  const alias = templateType === 'solemn_declaration' ? 'decl' : 'auth';
  Object.keys(declarationFields)
    .sort(k => (k.includes('recipient') ? -1 : 0))
    .forEach(key => {
      if (key.endsWith('recipient')) {
        const recipient = declarationFields[key];
        if (recipient && recipient.startsWith && recipient.startsWith('{')) {
          try {
            declarationFields[key] = JSON.parse(recipient).name;
          } catch (err) {
            console.error(err);
          }
        }
      }
      if (key.startsWith('solemn:')) {
        declarationData[key.replace(['solemn:'], '')] = declarationFields[key];
      } else if (key.includes('authorized_')) {
        authData[key.replace(`${template.template_type.refname}:`, '')] =
          declarationFields[key];
      } else if (
        key.includes('authorization:') &&
        key !== 'authorization:recipient'
      ) {
        declarationData[key.replace(`${template.template_type.refname}:`, '')] =
          declarationFields[key];
      }
    });

  const declarationKeys = [
    'firstname',
    'surname',
    'afm',
    'email',
    'tel',
    'father_fullname',
    'mother_fullname',
    'birth_date',
    'birth_place',
    'adt',
    'residence',
    'street',
    'street_number',
    'tk',
  ];
  const authKeys = [
    'authorized_firstname',
    'authorized_surname',
    'authorized_afm',
    'authorized_email',
    'authorized_tel',
    'authorized_father_fullname',
    'authorized_mother_fullname',
    'authorized_birth_date',
    'authorized_birth_place',
    'authorized_adt',
    'authorized_residence',
    'authorized_street',
    'authorized_street_number',
    'authorized_tk',
  ];
  return (
    <Fragment>
      <Title size="md">{t('declaration.details')}</Title>
      {isAuthorization ? (
        <Paragraph>Εξουσιοδοτώ τον κάτωθι εξουσιοδοτούμενο</Paragraph>
      ) : (
        <Paragraph>
          Με ατομική μου ευθύνη και γνωρίζοντας τις{' '}
          <Tooltip
            title={template.template_type.footnotes[2]}
            placement="bottom"
          >
            <Button className={classes.tooltip}>κυρώσεις</Button>
          </Tooltip>
          <sup>(3)</sup>, που προβλέπονται από τις διατάξεις της παρ. 6 του
          άρθρου 22 του Ν. 1599/1986, δηλώνω ότι:
        </Paragraph>
      )}
      <Paragraph>{declarationText}</Paragraph>
      <Title size="md">{t(`declaration.${alias}_content`)}</Title>
      <KeyValueList
        className={classes.keyValueList}
        data={declarationData}
        keys={declarationKeys}
      />
      {isAuthorization && (
        <>
          <Title size="md">{t('declaration.authorized_content')}</Title>
          <KeyValueList
            className={classes.keyValueList}
            data={authData}
            keys={authKeys}
          />
        </>
      )}
      {(isCurrentUser || isAdmin) && (
        <History declaration={props.declaration} />
      )}
    </Fragment>
  );
}
DeclarationForm.propTypes = {
  declaration: PropTypes.object,
  template: PropTypes.object,
  key: PropTypes.object,
  inbox: PropTypes.object,
  isOwner: PropTypes.bool,
  isAdmin: PropTypes.bool,
};
