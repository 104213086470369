import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.text.primary,
  },
  caret: {
    position: 'relative',
    top: 7,
    right: 3,
  },
  explanation: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(2),
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.grey['400']}`,
  },
}));

const Spoiler = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = event => {
    event.preventDefault();
    if (props.to) {
      navigate(props.to);
    } else {
      setOpen(!open);
    }
  };
  return (
    <div className={classes.root}>
      <a className={classes.link} href="" onClick={handleClick}>
        <span className={classes.caret}>
          {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </span>
        {props.label}
      </a>
      <Collapse in={open}>
        <div className={classes.explanation}>{props.explanation}</div>
      </Collapse>
    </div>
  );
};

Spoiler.propTypes = {
  label: PropTypes.string,
  explanation: PropTypes.string,
  to: PropTypes.string,
};

export default Spoiler;
