import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../layouts/BasicLayout';
import Markdown from 'react-markdown';
import terms from '../terms.md';

const Main = ({ markdown }) => (
  <div>
    <Markdown source={markdown} escapeHtml={false} />
  </div>
);

export default function LoginForm() {
  const { t } = useTranslation();
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(terms)
      .then(res => res.text())
      .then(text => setMarkdown(text))
      .catch(error => console.error(error));
  });

  return (
    <BasicLayout
      title={t('service_terms.header')}
      hasBackButton
      isLoggedIn={false}
      main={<Main markdown={markdown} />}
    />
  );
}
Main.propTypes = {
  markdown: PropTypes.string,
};
