import React from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../components/Typography';
import Top from 'components/Top';

export function NotFoundInner() {
  const { t } = useTranslation();
  return (
    <div style={{paddingBottom: '24px'}}>
      <Top
        size="xl"
        hasBackButton={true}
        title={t('notfound.title')}
      />
      <NotFoundContent />
    </div>
  );
}

export function NotFoundContent() {
  return (
    <>
      <Paragraph>notfound.paragraph1</Paragraph>
      <Paragraph>notfound.paragraph2</Paragraph>
      <Paragraph>notfound.paragraph3</Paragraph>
    </>
  );
}

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <BasicLayout
      title={t('notfound.title')}
      topPadding
      main={<NotFoundContent />}
    />
  );
}
