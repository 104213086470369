import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Paragraph } from './Typography';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(2),
    color: theme.palette.grey['800'],
  },
  info: {
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.info.main}`,
  },
  rootWarning: {
    margin: theme.spacing(4, 0),
  },
  warningIcon: {
    float: 'left',
    marginLeft: theme.spacing(1),
  },
  warningText: {
    paddingLeft: theme.spacing(8),
  },
  error: {
    borderLeftColor: theme.palette.error.main,
  },
}));

const QuoteWarning = props => {
  const classes = useStyles();
  return (
    <div className={classes.rootWarning}>
      <ErrorIcon className={classes.warningIcon} fontSize="large" />
      <div className={classes.warningText}>{props.children}</div>
    </div>
  );
};

const Quote = props => {
  const classes = useStyles();

  if (props.warning) {
    return <QuoteWarning {...props} />;
  } else {
    return (
      <Paragraph
        className={clsx(classes.root, {
          [classes.info]: props.info,
        })}
      >
        {props.children}
      </Paragraph>
    );
  }
};

Quote.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  info: PropTypes.bool,
};

Quote.defaultProps = {
  info: false,
  warning: false,
  error: false,
};

export default Quote;
