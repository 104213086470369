import React from 'react';
import { useLocation, Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Paragraph, Title } from '../../components/Typography';
import Button from '../../components/Button';
import { useResourceMany } from '../../lib/api';
import { useSearchTemplates, TextToDOM } from '../../models/template';
import BasicLayout from '../../layouts/BasicLayout';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    border: '1px solid lightgrey',
    marginBottom: -1,
    marginTop: theme.spacing(-2),
  },
  title: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  dummyText: {
    background: theme.palette.grey['300'],
    padding: theme.spacing(2),
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));

export default function NewPage(props) {
  const { t } = useTranslation();

  const query = new URLSearchParams(useLocation());
  const templates = useResourceMany('templates', {
    type: query.get('template_type'),
  });
  const templatesList = useSearchTemplates(templates.data);
  const { refname } = useParams();
  const templateData = templatesList.find(
    template => template.refname === refname
  );

  return (
    <BasicLayout
      title={templateData.shortname}
      captionRight={
        <Item
          label={t('templates.foreas')}
          value={templateData.recipient_hint}
        />
      }
      captionLeft={
        <Item label={t('templates.code')} value={templateData.refname} />
      }
      main={<TemplateItem refName={refname} templateData={templateData} />}
    />
  );
}

const Label = ({ label }) => {
  return <>{label}: </>;
};
Label.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

const Value = ({ value }) => {
  return <>{value}</>;
};
Value.propTypes = {
  value: PropTypes.string,
};
const Item = ({ label, value }) => {
  return (
    <span>
      <Label label={label} />
      <Value value={value} />
    </span>
  );
};
Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const TemplateItem = ({ refName, templateData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const url = `/create/q/confirm-personal-info?refname=${refName}`;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Paragraph>{t('templates.text')}</Paragraph>
        <div>
          <TextToDOM fields={templateData.fields} text={templateData.text} />
        </div>
      </CardContent>
      <CardContent>
        <Button to={`${url}`} component={Link}>
          Δημιουργία
        </Button>
      </CardContent>
    </Card>
  );
};
