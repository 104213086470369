import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Title, Paragraph } from '../../components/Typography';
import Button from '../../components/Button';
import BackButton from '../../components/BackButton';
import Spoiler from '../../components/Spoiler';
import { Field, FormBuilder, Fieldset } from '../../lib/form';
import { useResourceAction } from '../../lib/api';
import QrCodeImage from './qr-code.png';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: theme.spacing(4),
  },
  refCodeContainer: {
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
  },
  qrCodeContainer: {
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.grey['400']}`,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Explanation = () => {
  return (
    <>
      <Paragraph>
        Αν δε θυμάστε τον κωδικό της δήλωσης, μπορείτε να ψάξετε στην αναλυτική
        λίστα με όλες τις δηλώσεις που έχετε δημιουργήσει ή απευθύνονται στο
        πρόσωπο σας.
      </Paragraph>
      <Paragraph>
        <Link to="/vault">Δείτε τη θυρίδα σας</Link>
      </Paragraph>
    </>
  );
};
const ValidateRefCode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);
  const [refCode, setRefCode] = useState(null);
  const [error, setError] = useState(null);

  const validate = useResourceAction('declarations', refCode, 'GET');

  const fields = [
    {
      label: 'Κωδικός Επαλήθευσης',
      key: 'refCode',
      required: true,
    },
  ];

  useEffect(() => {
    if (validate.error && validate.error.error.code === 404) {
      setError({ message: 'Ο κωδικός επαλήθευσης δεν είναι σωστός' });
    } else {
      setError(null);
    }
  }, [validate.error]);
  useEffect(() => {
    if (validate.data && validate.data.reference_code) {
      setError(null);
      setRefCode(null);
      setValid(true);
    }
  }, [validate.data]);
  useEffect(() => {
    if (refCode) {
      validate.fetch();
    }
  }, [refCode]);

  useEffect(() => {
    if (valid) window.location = `/show/${validate.data.reference_code}`;
  }, [valid]);
  return (
    <>
      <Title size="lg">Εισάγετε τον κωδικό επαλήθευσης</Title>
      <FormBuilder
        fields={fields}
        onSubmit={({ refCode }) => {
          setRefCode(refCode);
        }}
      >
        <Fieldset>
          <Field id="refCode" name="refCode" error={error} />
        </Fieldset>
        <Spoiler label="Δεν γνωρίζω τον κωδικό" explanation={<Explanation />} />
        <Button className={classes.button} type="submit">
          Δείτε τη δήλωση
        </Button>
      </FormBuilder>
    </>
  );
};

const ValidateQRCode = () => {
  const classes = useStyles();
  return (
    <>
      <Title size="lg">Ή σαρώστε το QR code</Title>
      <Paragraph>
        Θα χρειαστείτε μια συσκευή με λειτουργία κάμερας, π.χ. έναν υπολογιστή,
        ένα κινητό ή ένα tablet.
      </Paragraph>
      <div>
        <img
          width="175"
          src={QrCodeImage}
          alt="Mobile phone showing a QR code"
        />
      </div>
      <Button href="/show/q/scan" className={classes.button}>
        Σαρώστε QR Code
      </Button>
    </>
  );
};

export default function ValidateCode() {
  const classes = useStyles();
  return (
    <>
      <BackButton />
      <Grid className={classes.mainContainer} container>
        <Grid className={classes.refCodeContainer} item md={6}>
          <ValidateRefCode />
        </Grid>
        <Grid item md={6} className={classes.qrCodeContainer}>
          <ValidateQRCode />
        </Grid>
      </Grid>
    </>
  );
}
