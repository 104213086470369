import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '../../components/ButtonIcon';
import { Title, Paragraph } from '../../components/Typography';
import { useAPI } from '../../lib/api';
import { useDeclarationURL } from '../../models/declaration';
import EmailIcon from '@material-ui/icons/MailOutline';
import SaveIcon from '@material-ui/icons/Save';
import SmsIcon from '@material-ui/icons/Sms';
import { makeStyles } from '@material-ui/core/styles';
import RevokeButton from './RevokeButton';
import { useSendEmail, useSendSms } from '../../models/declaration';
import { useMessages } from '../../utils/messages';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(4, 0),
  },
  linkContainer: {
    marginTop: theme.spacing(4),
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  sms: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EmailSection = ({ declaration }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const showMessage = useMessages();
  const id = declaration.id;
  const {
    fetch: sendEmail,
    error,
    loading,
    loaded,
    data: result,
  } = useSendEmail(id);

  const handleSendEmail = () => {
    sendEmail();
  };
  useEffect(() => {
    if (error) {
      if (error.error.message === 'no.email.set') {
        showMessage(
          'Δεν έχετε συμπληρώσει e-mail στα στοιχεία επικοινωνίας σας',
          'error'
        );
      } else {
        showMessage('Παρουσιάστηκε σφάλμα', 'error');
      }
    } else if (loaded) {
      showMessage('Το e-mail σας έχει αποσταλεί επιτυχώς', 'success');
    }
  }, [error, loaded]);

  return (
    <>
      <Title>Στείλτε τη δήλωση</Title>
      <Paragraph>declaration_final.email</Paragraph>
      <ButtonIcon
        className={classes.button}
        Icon={EmailIcon}
        label="Αποστολή e-mail"
        color="secondary"
        onClick={handleSendEmail}
      />
    </>
  );
};

const SmsSection = ({ declaration }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const showMessage = useMessages();
  const id = declaration.id;
  const { fetch: sendSms, error, loading, loaded, data: result } = useSendSms(
    id
  );

  const handleSendSms = () => {
    sendSms();
  };

  useEffect(() => {
    if (error) {
      showMessage('Παρουσιάστηκε σφάλμα', 'error');
    } else if (loaded) {
      showMessage('Το SMS σας έχει αποσταλεί επιτυχώς', 'success');
    }
  }, [error, loaded]);

  return (
    <>
      <Paragraph className={classes.sms}>declaration_final.sms</Paragraph>
      <ButtonIcon
        className={classes.button}
        Icon={SmsIcon}
        label="Αποστολή SMS"
        color="secondary"
        onClick={handleSendSms}
      />
    </>
  );
};
const RevokeSection = props => {
  const { t } = useTranslation();
  return (
    <>
      <Title>Ανακαλέστε τη δήλωση</Title>
      <Paragraph>declaration_final.revoke</Paragraph>
      <RevokeButton declaration={props.declaration} />
    </>
  );
};

export function SavePDF({ declaration, template }) {
  const { t } = useTranslation();
  const api = useAPI();
  const apiURL = (api && api.config && api.config.baseURL) || '/api';
  const classes = useStyles();
  return (
    <>
      <Title>Αποθηκεύστε στο αρχείο σας</Title>
      <Paragraph>{t(`declaration_final.save`)}</Paragraph>
      <ButtonIcon
        className={classes.button}
        target="_blank"
        href={`${apiURL}/declarations/${declaration.reference_code}/pdf`}
        xsFullWidth
        Icon={SaveIcon}
        component="a"
        label={t('button.save')}
      />
    </>
  );
}
export default function SaveEmail({
  declaration,
  template,
  showRevoke,
  showEmail,
}) {
  const alias =
    template.template_type.refname === 'solemn_declaration' ? 'decl' : 'auth';

  // we consider that if recipient policy is set to global the declaration is not placed
  // in an account inbox and thus we should inform user to communicate the declaration via 
  // other means.
  // TODO: reconsider the way we resolve inboxed status
  const inboxed =
    template.recipient_policy !== 'global' &&
    template.recipient_policy !== 'none';

  return (
    <div>
      {!inboxed && <Paragraph>declaration_final.description</Paragraph>}
      <SavePDF declaration={declaration} template={template} />
      {showEmail && (
        <>
          <EmailSection declaration={declaration} />
          <SmsSection declaration={declaration} />
          {showRevoke && alias === 'auth' && (
            <RevokeSection declaration={declaration} alias={alias} />
          )}
        </>
      )}
    </div>
  );
}

SaveEmail.propTypes = {
  declaration: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};
