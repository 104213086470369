import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../../components/Title';
import Loader from '../../../../components/Loader';
import { useResourceMany } from '../../../../lib/api';
import { useRequireAdminUser } from '../../../../lib/auth';
import { Paragraph } from 'components/Typography';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  paragraph: {
    padding: theme.spacing(2),
  },
}));

export default function DeclarationsList() {
  useRequireAdminUser();
  const { entityId, status } = useParams();
  const { t } = useTranslation();
  const filter = useMemo(() => {
    if (!status || status === 'all') {
      return {};
    }
    return { status };
  }, [status]);
  const classes = useStyles();

  const declarations = useResourceMany(`entity/${entityId}/inbox`, filter);
  const {
    hasNext,
    hasPrevious,
    setLimit,
    setPage,
    total,
    limitOptions,
    limit,
    page,
  } = declarations;

  const templates = useResourceMany('templates');
  const templateRefnames = templates.data.reduce(
    (acc, template) => ({ ...acc, [template.id]: template.refname }),
    {}
  );

  // extract useful data from declarations that will be displayed in the declarations table
  const rows = declarations.data.map(declaration => {
    const { id } = declaration;
    const templateID = declaration.declaration.template_id;
    const date = declaration.declaration.timestamp.slice(
      0,
      declaration.declaration.timestamp.indexOf('T')
    );
    const refCode = declaration.declaration.reference_code;
    const { inbox_status: inboxStatus } = declaration;
    const status = inboxStatus || declaration.status;
    const template = templateRefnames[templateID];
    const fullName = declaration.declaration.fields.reduce((name, field) => {
      if (field.key === 'solemn:firstname' || field.key === 'solemn:surname') {
        name = `${name} ${field.value}`;
      }
      return name;
    }, '');
    const afm = declaration.declaration.fields.reduce((afm, field) => {
      if (field.key === 'solemn:afm') {
        afm = field.value;
      }
      return afm;
    }, '');
    return { id, date, refCode, status, template, fullName, afm };
  });
  return (
    <>
      <Grid container>
        <Grid item xs={true}>
          <Title size="lg">Εισερχόμενα</Title>
        </Grid>
        {/*<Grid item>
          <Select
            style={{ padding: '0 20px' }}
            value={entityId}
            onChange={handleChange}
          >
            {entities.map((e, index) => {
              return (
                <MenuItem key={index} value={e.id}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
          */}
      </Grid>
      {rows.length ? (
        <TableContainer component={Paper} elevation={0} square>
          <Box width="100%" height="5px">
            {declarations.loading ? <Loader /> : []}
          </Box>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('declaration_entity_table.date')}</TableCell>
                <TableCell>{t('declaration_entity_table.refCode')}</TableCell>
                <TableCell>{t('declaration_entity_table.status')}</TableCell>
                <TableCell>{t('declaration_entity_table.template')}</TableCell>
                <TableCell>{t('declaration_entity_table.fullName')}</TableCell>
                <TableCell>{t('declaration_entity_table.afm')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {rows.map(row => (
                  <TableRow key={row.refCode}>
                    <TableCell>{row.date}</TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`../../show/${row.id}`}>{row.refCode}</Link>
                    </TableCell>
                    <TableCell>{t(`${row.status}`)}</TableCell>
                    <TableCell>{row.template}</TableCell>
                    <TableCell>{row.fullName}</TableCell>
                    <TableCell>{row.afm}</TableCell>
                  </TableRow>
                ))}
              </>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={limitOptions}
                  count={total}
                  page={page}
                  rowsPerPage={limit}
                  onChangeRowsPerPage={e => {
                    setLimit(e.target.value);
                  }}
                  onChangePage={(e, p) => {
                    setPage(p);
                  }}
                  backIconButtonProps={{ disabled: !hasPrevious }}
                  nextIconButtonProps={{ disabled: !hasNext }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <div>
          <Paragraph className={classes.paragraph}>
            Δεν υπάρχουν εισερχόμενα για αυτή τη θυρίδα.
          </Paragraph>
        </div>
      )}
    </>
  );
}
