import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import {
  useDeclarationByRef,
  useDeclarationFields,
  useDeclarationURL,
} from '../../models/declaration';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  qrCodeContainer: {
    border: `${theme.spacing(1)}px solid black`,
    display: 'inline-block',
    height: 128 + theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  qrCode: {
    border: `${theme.spacing(1)}px solid white`,
  },
}));

export default function DeclarationQRCode({ declaration, ...props }) {
  const classes = useStyles();
  const url = useDeclarationURL(declaration);
  return (
    <div className={classes.qrCodeContainer}>
      <QRCode
        {...props}
        className={classes.qrCode}
        value={url}
        aria-label="Link redirecting to the issued declaration"
      />
    </div>
  );
}

DeclarationQRCode.propTypes = {
  declaration: PropTypes.object,
};
