import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BasicLayout from '../../layouts/BasicLayout';
import { useParams, useNavigate } from 'react-router-dom';
import { ContinueButton } from '../../components/Button';
import { NormalText } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import ReplayIcon from '@material-ui/icons/Replay';
import user from '../../data/user';
import ButtonIcon from '../../components/ButtonIcon';
import { useMessages } from '../../utils/messages';
import { useRequireUser } from '../../lib/auth';
import {
  useDeclarationVerify,
  useDeclarationFields,
  useDeclarationTel,
  useDeclarationRequestSMS,
} from '../../models/declaration';
import { useResource } from '../../lib/api';
import { FormBuilder, Fieldset, Field } from '../../lib/form';
import { usePersonalDetails, useMobile } from '../../models/user';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
  },
  iconInButton: {
    marginRight: theme.spacing(1),
  },
  smsField: { marginBottom: theme.spacing(2) },
  continueButton: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resendButton: {
    marginBottom: theme.spacing(2),
  },
}));

const Main = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { data } = useResource('my-declarations', id);
  const declaration = useDeclarationFields(data);
  const template = useResource('all-templates', data.template_id).data;
  const [smsCode, setSMSCode] = useState(null);
  const [smsError, setSMSError] = useState(null);
  const mobile = useMobile();
  const {
    fetch: verifyDeclaration,
    error,
    loading,
    loaded,
    data: result,
  } = useDeclarationVerify(id, smsCode);
  const {
    fetch: resend,
    error: resentError,
    loading: resending,
    loaded: resent,
  } = useDeclarationRequestSMS(id);
  const { t } = useTranslation();
  const showMessage = useMessages();

  useEffect(() => {
    if (smsCode && !loading) {
      verifyDeclaration();
      setSMSCode(null);
    }
  }, [smsCode, loading]);

  useEffect(() => {
    if (data.state === 'issued') {
      navigate('/dilosi/' + data.reference_code);
    } else {
      setSMSError(null);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setSMSCode(null);
      // error is the Exception object, error.error the error details object
      const fieldError = {
        message: t(error.error.message),
      };
      setSMSError(fieldError);
    } else if (loaded) {
      console.log(result);
      showMessage(t('success.declaration.create'), 'success');
      navigate('/dilosi/' + result.reference_code + '/done');
    }
  }, [error, loaded]);

  useEffect(() => {
    if (resentError) {
      // error is the Exception object, error.error the error details object
      const fieldError = {
        message: t(error.error.message),
      };
      setSMSError(fieldError);
    } else if (resent) {
      showMessage(t('success.code.resend'), 'success');
    }
  }, [resentError, resent]);

  const fields = useMemo(() => {
    return [
      {
        key: 'sms_code',
        label: 'label.sms_code',
        required: true,
        width: '100px',
        type: 'string',
        autocomplete: 'off',
      },
    ];
  }, []);

  const phone = mobile.mobileConsentDisplay;

  return (
    <Fragment>
      <NormalText
        style={{
          marginBottom: 24,
        }}
      >
        {t('sms_confirmation_code.description', { phone })}
      </NormalText>
      <FormBuilder
        fields={fields}
        onSubmit={data => {
          setSMSCode(data.sms_code);
        }}
      >
        <Fieldset>
          <Field
            id="sms_code"
            name="sms_code"
            className={classes.smsField}
            error={smsError}
            onKeyPress={() => {
              setSMSError(null);
            }}
          />
        </Fieldset>

        <ContinueButton
          className={classes.continueButton}
          disabled={loading}
          type="submit"
        />
        <ButtonIcon
          className={classes.resendButton}
          color="secondary"
          label={t('button.resend_sms_code')}
          Icon={ReplayIcon}
          disabled={resending}
          onClick={resend}
        />
      </FormBuilder>
    </Fragment>
  );
};

export default function DeclarationSMSConfirmation(props) {
  useRequireUser();
  const classes = useStyles();
  const { id } = useParams();
  const declaration = useResource('my-declarations', id);
  const template = useResource('all-templates', declaration.data.template_id);
  const { t } = useTranslation();

  return (
    <BasicLayout
      title={t('sms_confirmation_code.title')}
      captionRight="Βήμα 4 από 4"
      captionLeft={template.data.shortname}
      hasBackButton
      backButtonDisabled={true}
      isLoggedIn={true}
      name={`${user.firstName} ${user.lastName}`}
      main={<Main classes={classes} t={t} />}
    />
  );
}
