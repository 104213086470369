import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BasicLayout from '../../layouts/BasicLayout';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Panel from '../../components/Panel';
import { Title } from '../../components/Typography';
import DeclarationForm from '../../components/Declaration/DeclarationForm';
import DeclarationQRCode from '../../components/Declaration/DeclarationQRCode';
import {
  useDeclarationFields,
  useIsUserOwner,
} from '../../models/declaration';
import { useParams } from 'react-router';
import { useResource } from '../../lib/api';
import DeclarationActions from '../../components/DeclarationActions';

const useStyles = makeStyles(theme => ({
  refTitle: { fontWeight: 'normal', margin: 0 },
  refCode: { wordWrap: 'break-word'}
}));
export function DeclarationID({ declaration }) {
  const classes = useStyles();
  const { data: template } = useResource('all-templates', declaration.template_id);
  const templateType = template.template_type.refname;
  const isSolemn = templateType === 'solemn_declaration';
  return (
    <Box display="block" displayPrint="none" marginBottom={4} marginTop={5}>
      <Panel>
        <Grid xs={12} item>
          <Title size="xl">issued_verbose</Title>
        </Grid>
        <Grid xs={12} item>
          <Title size="lg" className={classes.refTitle}>
            issued_refcode
          </Title>
        </Grid>
        <Grid xs={12} item>
          <Title className={classes.refCode} size="lg">{declaration.reference_code}</Title>
        </Grid>
      </Panel>
    </Box>
  );
}
DeclarationID.propTypes = {
  template_id: PropTypes.number,
  declaration: PropTypes.object,
};

const Main = ({ declaration, template, ...props }) => {
  const { ref, inboxId } = useParams();

  const templateType = template.template_type.refname;
  const fields = useDeclarationFields(declaration);

  return (
    <Fragment>
      <DeclarationID declaration={declaration} />
      <DeclarationForm isOwner={props.isOwner} declaration={declaration} template={template} />
    </Fragment>
  );
};

const Sidebar = props => {
  return (
    <>
      <DeclarationQRCode {...props} />
      <DeclarationActions {...props} />
    </>
  );
};

export default function FinalDeclarationPage(props) {
  const { ref } = useParams();
  const { data: declaration } = useResource('declarations', ref);
  const { data: template } = useResource('all-templates', declaration.template_id);
  const nextProps = { ...props, declaration, template };
  const isCurrentUser = useIsUserOwner(declaration);

  return (
    <BasicLayout
      main={<Main isOwner={isCurrentUser} {...nextProps} />}
      side={<Sidebar isOwner={isCurrentUser} {...nextProps} />}
      printOnlyContent
      isLoggedIn={true}
      topPadding
    />
  );
}
