// @flow
import { useSnackbar } from 'notistack';

export function useMessages() {
  const { enqueueSnackbar } = useSnackbar();
  const passMessageToSnackbar = function(
    messageText: string,
    messageVariant: string
  ) {
    return enqueueSnackbar(messageText, { variant: messageVariant });
  };
  return passMessageToSnackbar;
}
