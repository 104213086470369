export const HEALTH_DISTRICT_OPTIONS = [
  {
    label: '1η Υγειονομική Περιφέρεια Αττικής',
    value: '1η Υγειονομική Περιφέρεια Αττικής',
  },
  {
    label: '2η Υγειονομική Περιφέρεια Πειραιώς και Αιγαίου',
    value: '2η Υγειονομική Περιφέρεια Πειραιώς και Αιγαίου',
  },
  {
    label: '3η Υγειονομική Περιφέρεια Δυτικής Μακεδονίας',
    value: '3η Υγειονομική Περιφέρεια Δυτικής Μακεδονίας',
  },
  {
    label: '4η Υγειονομική Περιφέρεια Μακεδονίας και Θράκης',
    value: '4η Υγειονομική Περιφέρεια Μακεδονίας και Θράκης',
  },
  {
    label: '5η Υγειονομική Περιφέρεια Θεσσαλίας και Στερεάς Ελλάδας',
    value: '5η Υγειονομική Περιφέρεια Θεσσαλίας και Στερεάς Ελλάδας',
  },
  {
    label: '6η Υγειονομική Περιφέρεια Πελοποννήσου, Ιονίων Νήσων, Ηπείρου και Δυτικής Ελλάδας',
    value: '6η Υγειονομική Περιφέρεια Πελοποννήσου, Ιονίων Νήσων, Ηπείρου και Δυτικής Ελλάδας',
  },
  {
    label: '7η Υγειονομική Περιφέρεια Κρήτης',
    value: '7η Υγειονομική Περιφέρεια Κρήτης',
  },
]

export const DISTRICT_OPTIONS = [
  {
    group: 'Ανατολική Μακεδονία και Θράκη',
    label: 'Π.Ε. Δράμας',
    value: 'Π.Ε. Δράμας',
  },
  {
    group: 'Ανατολική Μακεδονία και Θράκη',
    label: 'Π.Ε. Έβρου',
    value: 'Π.Ε. Έβρου',
  },
  {
    group: 'Ανατολική Μακεδονία και Θράκη',
    label: 'Π.Ε. Καβάλας',
    value: 'Π.Ε. Καβάλας',
  },
  {
    group: 'Ανατολική Μακεδονία και Θράκη',
    label: 'Π.Ε. Θάσου',
    value: 'Π.Ε. Θάσου',
  },
  {
    group: 'Ανατολική Μακεδονία και Θράκη',
    label: 'Π.Ε. Ξάνθης',
    value: 'Π.Ε. Ξάνθης',
  },
  {
    group: 'Ανατολική Μακεδονία και Θράκη',
    label: 'Π.Ε. Ροδόπης',
    value: 'Π.Ε. Ροδόπης',
  },
  { group: 'Κεντρική Μακεδονία', label: 'Π.Ε. Ημαθίας', value: 'Π.Ε. Ημαθίας' },
  {
    group: 'Κεντρική Μακεδονία',
    label: 'Π.Ε. Θεσσαλονίκης',
    value: 'Π.Ε. Θεσσαλονίκης',
  },
  { group: 'Κεντρική Μακεδονία', label: 'Π.Ε. Κιλκίς', value: 'Π.Ε. Κιλκίς' },
  { group: 'Κεντρική Μακεδονία', label: 'Π.Ε. Πέλλας', value: 'Π.Ε. Πέλλας' },
  { group: 'Κεντρική Μακεδονία', label: 'Π.Ε. Πιερίας', value: 'Π.Ε. Πιερίας' },
  { group: 'Κεντρική Μακεδονία', label: 'Π.Ε. Σερρών', value: 'Π.Ε. Σερρών' },
  {
    group: 'Κεντρική Μακεδονία',
    label: 'Π.Ε. Χαλκιδικής',
    value: 'Π.Ε. Χαλκιδικής',
  },
  { group: 'Δυτική Μακεδονία', label: 'Π.Ε. Γρεβενών', value: 'Π.Ε. Γρεβενών' },
  {
    group: 'Δυτική Μακεδονία',
    label: 'Π.Ε. Καστοριάς',
    value: 'Π.Ε. Καστοριάς',
  },
  { group: 'Δυτική Μακεδονία', label: 'Π.Ε. Κοζάνης', value: 'Π.Ε. Κοζάνης' },
  { group: 'Δυτική Μακεδονία', label: 'Π.Ε. Φλώρινας', value: 'Π.Ε. Φλώρινας' },
  { group: 'Ήπειρος', label: 'Π.Ε. Άρτας', value: 'Π.Ε. Άρτας' },
  { group: 'Ήπειρος', label: 'Π.Ε. Θεσπρωτίας', value: 'Π.Ε. Θεσπρωτίας' },
  { group: 'Ήπειρος', label: 'Π.Ε. Ιωαννίνων', value: 'Π.Ε. Ιωαννίνων' },
  { group: 'Ήπειρος', label: 'Π.Ε. Πρέβεζας', value: 'Π.Ε. Πρέβεζας' },
  { group: 'Θεσσαλία', label: 'Π.Ε. Καρδίτσας', value: 'Π.Ε. Καρδίτσας' },
  { group: 'Θεσσαλία', label: 'Π.Ε. Λάρισας', value: 'Π.Ε. Λάρισας' },
  { group: 'Θεσσαλία', label: 'Π.Ε. Μαγνησίας', value: 'Π.Ε. Μαγνησίας' },
  { group: 'Θεσσαλία', label: 'Π.Ε. Σποράδων', value: 'Π.Ε. Σποράδων' },
  { group: 'Θεσσαλία', label: 'Π.Ε. Τρικάλων', value: 'Π.Ε. Τρικάλων' },
  {
    group: 'Περιφέρεια Ιονίων Νήσων',
    label: 'Π.Ε. Ζακύνθου',
    value: 'Π.Ε. Ζακύνθου',
  },
  {
    group: 'Περιφέρεια Ιονίων Νήσων',
    label: 'Π.Ε. Κέρκυρας',
    value: 'Π.Ε. Κέρκυρας',
  },
  {
    group: 'Περιφέρεια Ιονίων Νήσων',
    label: 'Π.Ε. Κεφαλληνίας',
    value: 'Π.Ε. Κεφαλληνίας',
  },
  {
    group: 'Περιφέρεια Ιονίων Νήσων',
    label: 'Π.Ε. Ιθάκης',
    value: 'Π.Ε. Ιθάκης',
  },
  {
    group: 'Περιφέρεια Ιονίων Νήσων',
    label: 'Π.Ε. Λευκάδας',
    value: 'Π.Ε. Λευκάδας',
  },
  {
    group: 'Περιφέρεια Δυτικής Ελλάδας',
    label: 'Π. Ε. Αιτωλοακαρνανίας',
    value: 'Π. Ε. Αιτωλοακαρνανίας',
  },
  {
    group: 'Περιφέρεια Δυτικής Ελλάδας',
    label: 'Π.Ε. Αχαΐας',
    value: 'Π.Ε. Αχαΐας',
  },
  {
    group: 'Περιφέρεια Δυτικής Ελλάδας',
    label: 'Π.Ε. Ηλείας',
    value: 'Π.Ε. Ηλείας',
  },
  {
    group: 'Περιφέρεια Στερεάς Ελλάδας',
    label: 'Π.Ε. Βοιωτίας',
    value: 'Π.Ε. Βοιωτίας',
  },
  {
    group: 'Περιφέρεια Στερεάς Ελλάδας',
    label: 'Π.Ε. Εύβοιας',
    value: 'Π.Ε. Εύβοιας',
  },
  {
    group: 'Περιφέρεια Στερεάς Ελλάδας',
    label: 'Π.Ε. Ευρυτανίας',
    value: 'Π.Ε. Ευρυτανίας',
  },
  {
    group: 'Περιφέρεια Στερεάς Ελλάδας',
    label: 'Π.Ε. Φθιώτιδας',
    value: 'Π.Ε. Φθιώτιδας',
  },
  {
    group: 'Περιφέρεια Στερεάς Ελλάδας',
    label: 'Π.Ε. Φωκίδας',
    value: 'Π.Ε. Φωκίδας',
  },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Βορείου Τομέα Αθηνών',
    value: 'Π.Ε. Βορείου Τομέα Αθηνών',
  },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Δυτικού Τομέα Αθηνών',
    value: 'Π.Ε. Δυτικού Τομέα Αθηνών',
  },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Κεντρικού Τομέα Αθηνών',
    value: 'Π.Ε. Κεντρικού Τομέα Αθηνών',
  },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Νοτίου Τομέα Αθηνών',
    value: 'Π.Ε. Νοτίου Τομέα Αθηνών',
  },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Πειραιώς',
    value: 'Π.Ε. Πειραιώς',
  },
  { group: 'Περιφέρεια Αττικής', label: 'Π.Ε. Νήσων', value: 'Π.Ε. Νήσων' },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Ανατολικής Αττικής',
    value: 'Π.Ε. Ανατολικής Αττικής',
  },
  {
    group: 'Περιφέρεια Αττικής',
    label: 'Π.Ε. Δυτικής Αττικής',
    value: 'Π.Ε. Δυτικής Αττικής',
  },
  { group: 'Πελοπόννησος', label: 'Π.Ε. Αργολίδας', value: 'Π.Ε. Αργολίδας' },
  { group: 'Πελοπόννησος', label: 'Π.Ε. Αρκαδίας', value: 'Π.Ε. Αρκαδίας' },
  { group: 'Πελοπόννησος', label: 'Π.Ε. Κορινθίας', value: 'Π.Ε. Κορινθίας' },
  { group: 'Πελοπόννησος', label: 'Π.Ε. Λακωνίας', value: 'Π.Ε. Λακωνίας' },
  { group: 'Πελοπόννησος', label: 'Π.Ε. Μεσσηνίας', value: 'Π.Ε. Μεσσηνίας' },
  { group: 'Βόρειο Αιγαίο', label: 'Π.Ε. Λέσβου', value: 'Π.Ε. Λέσβου' },
  { group: 'Βόρειο Αιγαίο', label: 'Π.Ε. Ικαρίας', value: 'Π.Ε. Ικαρίας' },
  { group: 'Βόρειο Αιγαίο', label: 'Π.Ε. Λήμνου', value: 'Π.Ε. Λήμνου' },
  { group: 'Βόρειο Αιγαίο', label: 'Π.Ε. Σάμου', value: 'Π.Ε. Σάμου' },
  { group: 'Βόρειο Αιγαίο', label: 'Π.Ε. Χίου', value: 'Π.Ε. Χίου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Άνδρου', value: 'Π.Ε. Άνδρου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Μήλου', value: 'Π.Ε. Μήλου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Θήρας', value: 'Π.Ε. Θήρας' },
  {
    group: 'Νότιο Αιγαίο',
    label: 'Π.Ε. Κέας-Κύθνου',
    value: 'Π.Ε. Κέας-Κύθνου',
  },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Μυκόνου', value: 'Π.Ε. Μυκόνου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Νάξου', value: 'Π.Ε. Νάξου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Σύρου', value: 'Π.Ε. Σύρου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Τήνου', value: 'Π.Ε. Τήνου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Πάρου', value: 'Π.Ε. Πάρου' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Καλύμνου', value: 'Π.Ε. Καλύμνου' },
  {
    group: 'Νότιο Αιγαίο',
    label: 'Π.Ε. Καρπάθου-Ηρωικής Νήσου Κάσου',
    value: 'Π.Ε. Καρπάθου-Ηρωικής Νήσου Κάσου',
  },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Κω', value: 'Π.Ε. Κω' },
  { group: 'Νότιο Αιγαίο', label: 'Π.Ε. Ρόδου', value: 'Π.Ε. Ρόδου' },
  { group: 'Κρήτη', label: 'Π.Ε. Ηρακλείου', value: 'Π.Ε. Ηρακλείου' },
  { group: 'Κρήτη', label: 'Π.Ε. Λασιθίου', value: 'Π.Ε. Λασιθίου' },
  { group: 'Κρήτη', label: 'Π.Ε. Ρεθύμνης', value: 'Π.Ε. Ρεθύμνης' },
  { group: 'Κρήτη', label: 'Π.Ε. Χανίων', value: 'Π.Ε. Χανίων' },
];
