import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMessages } from '../../utils/messages';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useResourceAction } from '../../lib/api';
import Button from '../../components/Button';
import ButtonIcon from '../../components/ButtonIcon';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles(theme => ({
  revokeButton: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  dialog: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  dialogActions: {
    margin: theme.spacing(0, 2, 2, 2),
  },
  dialogParagraph: {
    marginBottom: theme.spacing(4),
  },
}));

export default function RevokeButton(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const showMessage = useMessages();
  const navigate = useNavigate();
  const {
    fetch: revoke,
    error,
    loading,
    loaded,
    data: result,
  } = useResourceAction('my-declarations', `${props.declaration.id}/revoke`);
  const [open, setOpen] = useState(false);
  const [, setConfirm] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleClickConfirm = () => {
    setConfirm(true);
    setOpen(false);
    revoke();
  };

  useEffect(() => {
    if (loaded) {
      showMessage('Έχετε ανακαλέσει επιτυχώς την δήλωση', 'success');
      navigate(`/show/${props.declaration.reference_code}`);
    }
  }, [loaded]);

  return (
    <>
      <ButtonIcon
        className={classes.revokeButton}
        xsFullWidth
        Icon={SettingsBackupRestoreIcon}
        label={t('declaration_actions.revoke_button')}
        onClick={handleClickOpen}
      />
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t('declaration_actions.revoke_dialog_title')}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <DialogContentText className={classes.dialogParagraph}>
            {t('declaration_actions.revoke_dialog_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClickClose} color="secondary">
            Ακύρωση
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClickConfirm}
          >
            {t('declaration_actions.revoke_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

RevokeButton.propTypes = {
  onSubmit: PropTypes.func,
};
