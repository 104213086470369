import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(0, 6, 4),
    '@media (max-width: 767px)': {
      padding: theme.spacing(0, 2),
    },
  },
  title: {
    margin: theme.spacing(6, 0),
  },
}));

export default function FullWidthLayout(props) {
  const classes = useStyles();
  const { main, printOnlyContent, isLoggedIn, name } = props;

  const displayPrint = printOnlyContent ? 'none' : 'flex';
  return (
    <Fragment>
      <Box display="flex" displayPrint={displayPrint} flex={1} flexGrow={0}>
        <Header isLoggedIn={isLoggedIn} name={name} />
      </Box>
      <Container className={classes.mainContainer}>{main}</Container>
    </Fragment>
  );
}
FullWidthLayout.propTypes = {
  hasBackButton: PropTypes.bool,
  title: PropTypes.string,
  captionRight: PropTypes.string,
  captionLeft: PropTypes.string,
  main: PropTypes.node.isRequired,
  side: PropTypes.node,
  printOnlyContent: PropTypes.bool,
  noTop: PropTypes.bool,
  topPadding: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  name: PropTypes.bool,
};
