import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '../../components/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  sidebarTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  sidebarList: {
    padding: 0,
  },
  sidebar: {
    marginTop: theme.spacing(8),
  },
}));
const useSidebarItemStyles = makeStyles(theme => ({
  primary: {
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: 'bolder',
  },
}));
const SidebarItem = props => {
  const { pathname } = useLocation();
  const isCurrentRoute = props.to === pathname;
  const classes = useSidebarItemStyles();
  const { t } = useTranslation();
  return (
    <ListItem to={props.to} component={Link} selected={isCurrentRoute} button>
      <ListItemText classes={classes} primary={t(props.text)} />
    </ListItem>
  );
};
export default function Sidebar() {
  const classes = useStyles();
  return (
    <div className={classes.sidebar}>
      <Title className={classes.sidebarTitle} size="md">
        Δείτε Επίσης
      </Title>
      <List
        className={classes.sidebarList}
        component="nav"
        aria-label="main mailbox folders"
      >
        <SidebarItem to="/vault/sent" text="vault.sent.title" />
        <Divider />
        <SidebarItem to="/vault/inbox" text="vault.inbox.title" />
        {/* <Divider />
        <SidebarItem to="/vault/archive" text="vault.archive" /> */}
        <Divider />
        <SidebarItem
          to="/vault/personal-details"
          text="vault.personal-details.title"
        />
      </List>
    </div>
  );
}
