import React, { useMemo, useEffect, useState } from 'react';
import { map } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { useResource, useResourceAction } from '../lib/api';
import useAuth, { useUser } from '../lib/auth';
import { useTranslation } from 'react-i18next';
import { tokenize } from './template';
import HighLight from '../components/HighLight';
import { useTextFieldsOverrides } from './fields';
import { useNavigate } from 'react-router-dom';

const FIELD_PREFIX_MAP = {
  solemn_declaration: 'solemn',
  authorization: 'authorization',
};

const FIELDS_DEFAULTS = {
  firstname: {
    layout: { sm: 6 },
  },
  surname: {
    layout: { sm: 6 },
  },
  street: {
    layout: {
      sm: 7,
    },
  },
  birth_date: {
    layout: { sm: 6 },
  },
  birth_place: {
    layout: { sm: 6 },
  },
  street_number: {
    layout: { sm: 2 },
  },
  adt: {
    layout: { sm: 12 },
  },
  afm: {
    layout: { sm: 6 },
  },
  tk: {
    layout: { sm: 3 },
  },
};

const FIELDS = {
  solemn_declaration: [
    'tel',
    'email',
    'surname',
    'firstname',
    'father_fullname',
    'mother_fullname',
    'birth_date',
    'birth_place',
    'adt',
    'afm',
    'residence',
    'street',
    'street_number',
    'tk',
  ],
  authorization: [
    'tel',
    'email',
    'surname',
    'firstname',
    'father_fullname',
    'mother_fullname',
    'birth_date',
    'birth_place',
    'adt',
    'afm',
    'residence',
    'street',
    'street_number',
    'tk',
    'authorized_surname',
    'authorized_firstname',
    'authorized_father_fullname',
    'authorized_mother_fullname',
    'authorized_birth_date',
  ],
};

export function useTemplate(id) {
  return useResource('all-templates', id);
}

export function templateFieldsList(fields) {
  return Object.keys(fields).map(fname => {
    return { field_name: fname, ...fields[fname] };
  });
}

export function extractDeclarationFields(template, declaration) {
  const values = {};
  declaration &&
    Object.keys(declaration).forEach(f => {
      values[f.field_name] = f;
    });

  template.template_type.fields.concat(template.fields).forEach(f => {
    values[f.key] = { ...f, value: values[f] };
  });
  return values;
}

export function useUpdateDeclaration(id, formData) {
  const declaration = useResource('my-declarations', id);
  const template = useResource('all-templates', declaration.data.template_id);
  const [formPostData, formPostAttachments] = useMemo(() => {
    const fields = extractDeclarationFields(template.data, declaration);
    if (formData) {
      const prefix = FIELD_PREFIX_MAP[template.data.template_type.refname];
      const templateFields = map(
        template.data.template_type.fields,
        field => field.key
      );
      const data = { fields: [] };
      const attachments = [];
      /*
       * The bellow keys should not be modified. Also, their values should not
       * be altered.
       */
      const keysKeepAsAre = ['recipient_name', 'recipient_id'];
      Object.keys(formData).forEach(key => {
        if (keysKeepAsAre.includes(key)) {
          data[key] = formData[key];
          return;
        }
        const prefixed = prefix + ':' + key;
        let value = formData[key];
        const fieldName = templateFields.includes(prefixed) ? prefixed : key;
        const meta = fields[fieldName];
        if (meta && meta.type === 'attachment') {
          attachments.push({ field_name: fieldName, value });
        } else {
          if (value === undefined && meta.type === 'string') {
            value = '';
          }
          if (value !== undefined) {
            data.fields.push({ field_name: fieldName, value });
          }
        }
      });

      // construct a FormData object
      let formPostAttachments = null;
      if (attachments.length) {
        formPostAttachments = new FormData();
        attachments.forEach(f => {
          if (f && f.value && f.value.file) {
            formPostAttachments.append(
              f.field_name,
              f.value.file,
              f.value.fileName
            );
          }
        });
      }
      return [data, formPostAttachments];
    }
    return [null, null];
  }, [formData]);

  const upload = useResourceAction(
    'my-declarations',
    id + '/upload',
    'POST',
    formPostAttachments
  );

  const update = useResourceAction(
    'my-declarations',
    id + '/update',
    'POST',
    formPostData
  );

  useEffect(() => {
    if (upload.loaded && !upload.error && !update.loading) {
      update.fetch();
    }
  }, [upload.loaded, upload.error, update.loading]);

  if (!formPostAttachments) {
    return {
      ...update,
      dataSet: formPostData,
    };
  } else {
    const loaded = upload.loaded && update.loaded;
    const loading = upload.loading || update.loading;
    const error = update.error || upload.error;
    const data = update.data;
    const fetch = formPostAttachments ? upload.fetch : update.fetch;

    return {
      ...update,
      loaded,
      loading,
      error,
      data,
      fetch,
      dataSet: formPostData,
    };
  }
}

export function useDeclarationFields(
  declaration,
  removePrefix = false,
  type = null,
  values = false
) {
  return useMemo(() => {
    const fields = {};
    declaration.fields.forEach(f => {
      let key = f.key;
      if (removePrefix && type) {
        key = f.key.replace(FIELD_PREFIX_MAP[type] + ':', '');
      }
      let value = f;
      if (values) {
        value = f.value;
      }
      fields[key] = value;
    });
    return fields;
  }, [declaration]);
}

export function useDeclarationTel(declaration, template) {
  return useMemo(() => {
    const key =
      template.template_type.refname === 'solemn_declaration'
        ? 'solemn'
        : 'authorization';
    return [declaration[`${key}:tel`]];
  }, [declaration, template]);
}

export function useTextFormFields(declaration, text) {
  const template = useResource('all-templates', declaration.template_id);
  const overrides = useTextFieldsOverrides(template.data);
  const { t } = useTranslation();

  return useMemo(() => {
    const initial = {};
    const tplFields = {};
    template.data.fields.forEach(f => {
      tplFields[f.key] = f;
    });

    // locate fields from template text and return corresponding info from template.fields
    const textFields = tokenize(text || template.data.text)
      .filter(t => t.type === 'var')
      .map(t => t.value)
      .map(key => ({ key, ...tplFields[key] }))
      .map(f => {
        const currentField =
          template.data.fields.find(field => field.key === f.key) ||
          template.data.template_type.fields.find(
            field => field.key === f.key
          ) ||
          {};
        const declarationField =
          declaration.fields.find(field => field.key === f.key) || {};

        if (currentField) {
          if (declarationField.value !== undefined) {
            initial[f.key] = declarationField.value;
          }
          if (currentField.type === 'attachment') {
            const valueSet = declarationField.value;
            if (valueSet) {
              const value = JSON.parse(valueSet);
              initial[f.key] = {
                fileName: value.file_name || t('file_upload.value_set'),
                textPlaceholderLabel:
                  value.file_name || t('file_upload.set_text_placeholder'),
              };
            }
          }
        }

        // TODO: resolve this from backend
        if (f.key.endsWith('afm')) {
          f.type = 'afm';
        }
        const override = overrides[f.key];

        return {
          ...f,
          hint: currentField.hint,
          label: currentField.label || f.key + '.label',
          editable: true,
          ...override,
        };
      });

    const authorizedFields = [];
    template.data.template_type.fields.forEach(f => {
      if (f.key.includes('authorized_')) {
        const currentField = template.data.template_type.fields.find(
          field => field.key === f.key
        );
        const declarationField =
          declaration.fields.find(field => field.key === f.key) || {};
        if (currentField) {
          initial[f.key] = declarationField.value || '';
        }

        if (f.key.includes('email')) {
          f.type = 'email';
        }

        authorizedFields.push({
          ...f,
          label: currentField.label || f.key + '.label',
          editable: true,
        });
      }
    });

    return [textFields, authorizedFields, initial];
  }, [template.data.fields]);
}

export function useContactFormFields(
  declaration,
  onlyEditable = true,
  text = false
) {
  const template = useResource('all-templates', declaration.template_id).data;
  const templateType = template.template_type.refname;
  const tplFields = template.template_type.fields;
  const prefix = FIELD_PREFIX_MAP[template.template_type.refname] + ':';
  const declFields = useDeclarationFields(declaration);
  const overrides = useTextFieldsOverrides(template);

  const { t } = useTranslation();
  const formFields = useMemo(() => {
    const initial = {};
    const fields = tplFields
      .map(f => {
        const key = f.key.replace(prefix, '');
        if (FIELDS[templateType].includes(key)) {
          initial[key] = declFields[f.key] && declFields[f.key].value;
        }
        let type = 'string';
        if (key.endsWith('tel')) {
          type = 'mobile_phone';
          f.hint = t('form.validation.mobile_phone');
          f.required = true;
        }
        const extra = overrides[f.key] || {};
        const field = {
          label: 'label.' + key,
          key,
          sourceKey: f.key,
          external_immutable: f.external_immutable,
          validate: null,
          required: f.required === undefined ? false : f.required,
          hint: f.hint || undefined,
          hint_md: f.hint_md || undefined,
          example: f.example || undefined,
          type: type,
          layout: {},
          ...(FIELDS_DEFAULTS[key] || {}),
          ...extra,
        };
        return field;
      })
      .map(f => {
        const value = declFields[f.sourceKey] && declFields[f.sourceKey].value;
        const immutable = f.external_immutable;
        const origin = declFields[f.sourceKey] && declFields[f.sourceKey].origin;
        f.editable = !immutable || value === undefined || origin === 'user';
        return f;
      })
      .filter(f => {
        return onlyEditable ? f.editable : true;
      })
      .filter(
        f =>
          FIELDS[templateType].includes(f.key) && !f.key.includes('authorized_')
      )
      .sort((a, b) => {
        const fields = FIELDS[templateType];
        return fields.indexOf(a.key) - fields.indexOf(b.key);
      });
    return [fields, initial];
  }, [declaration]);

  return formFields;
}

// initialize declaration 2-factor verification
export function useDeclarationRequestIssue(id) {
  return useResourceAction('my-declarations', id + '/request');
}

// verify declaration issue
export function useDeclarationVerify(id, smsCode) {
  const data = useMemo(() => {
    return smsCode ? { confirmation_code: smsCode } : null;
  }, [smsCode]);
  return useResourceAction('my-declarations', id + '/confirm', 'POST', data);
}

export function useDeclarationRequestSMS(id) {
  return useResourceAction('my-declarations', id + '/resend');
}

export function useDeclarationByRef(ref) {
  return useResource('declarations', ref);
}

export function useDeclarationByInboxId(entity, id) {
  return useResource('entity/' + entity + '/inbox', id);
}

export const useFieldsToText = (fields, text) => {
  /*
   * Transform array of declaration fields to object with key the field_name and
   * value the value of the field.
   */
  const tokenizedText = tokenize(text);
  const {
    typography: { fontFamily },
  } = useTheme();
  return (
    <pre
      style={{
        fontFamily,
        whiteSpace: 'pre-line',
        overflowWrap: 'anywhere',
      }}
    >
      {tokenizedText.map(item => {
        if (item.type === 'var') {
          const field = fields[item.value];
          const value = (field && field.textPlaceholderLabel) ?? field;
          item.value = <HighLight type="dark" text={value} />;
        }
        return item.value;
      })}
    </pre>
  );
};

export function useDeclarationURL(declaration) {
  return window.location.origin + '/show/' + declaration.reference_code;
}

export const AUTHORIZATION_DECLARATION_TEXT = `
Ο/Η κάτωθι υπογεγραμμένος/η {authorization:firstname} {authorization:surname}
του {authorization:father_fullname} και της {authorization:mother_fullname}
γεννηθείς την {authorization:birth_date}, στην {authorization:birth_place},
κάτοικος {authorization:residence}, οδός {authorization:street} αρ
{authorization:street_number}, με ΑΔΤ/Διαβατηρίου {authorization:adt}`;
// που εκδόθηκε την <> από το <>.

export const AUTHORIZATION_AUTHORIZED_TEXT = `
Τον/την 
{authorization:authorized_firstname}
{authorization:authorized_surname} του
{authorization:authorized_father_fullname} και της
{authorization:authorized_mother_fullname} που γεννήθηκε την
{authorization:authorized_birth_date}, στην
{authorization:authorized_birth_place}, κάτοικο
{authorization:authorized_residence}, οδός {authorization:authorized_street}
αρ {authorization:authorized_street_number}, με ΑΔΤ/Διαβατηρίου
{authorization:authorized_adt}`; // που εκδόθηκε την <> από το <>.

export function useAuthorizationTexts(declaration, template) {
  const [, , fields] = useTextFormFields(
    declaration.data,
    template.data.text +
      AUTHORIZATION_AUTHORIZED_TEXT +
      AUTHORIZATION_DECLARATION_TEXT
  );
  const text = useFieldsToText(fields, template.data.text);
  const declarationText = useFieldsToText(
    fields,
    AUTHORIZATION_DECLARATION_TEXT
  );
  const authorizedText = useFieldsToText(fields, AUTHORIZATION_AUTHORIZED_TEXT);
  return [declarationText, authorizedText, text];
}

export function useIsUserOwner(declaration) {
  const user = useUser();
  return user && user.id === declaration.user_id;
}

export function useSendEmail(id) {
  const send = useResourceAction(
    `my-declarations/${id}/email`,
    undefined,
    'POST'
  );
  return send;
}

export function useSendSms(id) {
  const send = useResourceAction(
    `my-declarations/${id}/sms`,
    undefined,
    'POST'
  );
  return send;
}

export function useRecipient(decl) {
  const fields = useDeclarationFields(decl);
  const recipient = fields['solemn:recipient'];
  try {
    return recipient && recipient.value && JSON.parse(recipient.value);
  } catch (err) {
    return recipient && recipient.value;
  }
}

export function useDeleteDraft(id) {
  const deleteDraft = useResourceAction(
    `my-declarations/${id}/dismiss`,
    undefined,
    'POST'
  );
  return deleteDraft;
}

export const useCreateDeclaration = (template, onSuccess, onError) => {
  const {
    fetch: create,
    data: declaration,
    loading,
    error,
  } = useResourceAction('my-declarations', null, 'POST', {
    template_id: template.id,
  });
  const [claims, setClaims] = useState({});

  useEffect(() => {
    if (declaration && declaration.id && !error) {
      onSuccess(declaration);
    }
    if (error) {
      const data = error?.error?.data;
      if (data.missing && data.missing.includes('mobile_certified_login')) {
        setClaims({
          mobile: true,
        });
      }
      onError(error);
    }
  }, [declaration, error]);

  return {
    declaration,
    loading,
    error,
    claims,
    create,
  };
};
