import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BasicLayout from '../../layouts/BasicLayout';
import { Paragraph, Title, NormalText } from '../../components/Typography';
import Button from '../../components/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useUser } from '../../lib/auth';

const Main = ({ t }) => {
  const user = useUser();
  const nextUrl = useMemo(() => {
    return user && user.is_admin ? '/dashboard' : '/q/start';
  }, [user]);
  return (
    <div>
      <Paragraph>{t('home_page.subtitle')}</Paragraph>
      <ul>
        <li>
          <NormalText>home_page.uses_1</NormalText>
        </li>
      </ul>
      <Button
        to={nextUrl}
        component={Link}
        style={{
          marginBottom: 32,
        }}
      >
        {t('button.start')}
        <ChevronRightIcon />
      </Button>
      {/*
      <Title size="md">home_page.before_start</Title>
      <Paragraph>home_page.create.description</Paragraph>
       <ul>
        <li>
          <NormalText>home_page.create.password</NormalText>
        </li>
        <li>
          <NormalText>home_page.create.recipient</NormalText>
        </li>
        <li>
          <NormalText>home_page.create.mobile</NormalText>
        </li>
      </ul>
      <Paragraph>home_page.check.description</Paragraph>
      <ul>
        <li>
          <NormalText>home_page.check.refcode</NormalText>
        </li>
        <li>
          <NormalText>home_page.check.qrcode</NormalText>
        </li>
      </ul>
      <Paragraph>home_page.vault.description</Paragraph>
      <ul>
        <li>
          <NormalText>home_page.create.password</NormalText>
        </li>
      </ul>
      <Title size="md">{t('home_page.specs')}</Title>
      <ul>
        <li>
          <NormalText>{t('home_page.specs_1')}</NormalText>
        </li>
        <li>
          <NormalText>{t('home_page.specs_2')}</NormalText>
        </li>
      </ul> */}
    </div>
  );
};
Main.propTypes = {
  t: PropTypes.func.isRequired,
};

export default function Home() {
  const { t } = useTranslation();
  return (
    <BasicLayout
      main={<Main t={t} />}
      topPadding
      title={t('declaration.title')}
      isLoggedIn={false}
    />
  );
}
