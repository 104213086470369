import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { head, includes } from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import { Title, Caption, NormalText } from '../../components/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { useResource } from '../../lib/api';
import Button from '../../components/Button';
import { useDeleteDraft } from '../../models/declaration';
import useMyInboxStore from '../../store/myInbox';
import { useMessages } from '../../utils/messages';
import useOutboxStore from '../../store/outbox';

const useStyles = makeStyles(theme => ({
  inboxTitle: {
    fontWeight: 'bolder',
  },
  emptyTitle: {
    marginBottom: theme.spacing(2),
  },
  pendingAvatar: {
    background: theme.palette.primary.main,
  },
  inbox_status: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(5),
  },
  inline: {
    display: 'inline',
  },
  deleteButton: {
    marginLeft: theme.spacing(5),
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.error.dark,
    },
  },
}));

const DeleteButton = props => {
  const classes = useStyles();
  const showMessage = useMessages();
  const [open, setOpen] = useState(false);
  const { declarationId, getMyInbox, getOutbox, inboxType } = props;
  const {
    fetch: deleteDraft,
    error,
    loading,
    loaded,
    data: result,
  } = useDeleteDraft(declarationId);
  const handleDelete = () => {
    deleteDraft();
  };

  useEffect(() => {
    if (error) {
      showMessage('Παρουσιάστηκε σφάλμα', 'error');
    } else if (inboxType === 'sent' && loaded) {
      getOutbox();
      showMessage(
        'Το υπό επεξεργασία έγγραφο σας διαγράφηκε επιτυχώς',
        'success'
      );
      setOpen(false);
    } else if (inboxType === 'inbox' && loaded) {
      getMyInbox();
      showMessage(
        'Το υπό επεξεργασία έγγραφο σας διαγράφηκε επιτυχώς',
        'success'
      );
      setOpen(false);
    }
  }, [error, loaded]);

  return (
    <>
      <IconButton
        className={classes.deleteButton}
        aria-label="delete"
        onClick={() => setOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Διαγραφή προχείρου</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Είστε βέβαιοι ότι θέλετε να διαγράψετε το υπό επεξεργασία έγγραφο
            σας; Η ενέργεια αυτή θα είναι μόνιμη και δε θα μπορεί να αναιρεθεί.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Ακύρωση
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Διαγραφή
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const InboxItem = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getMyInbox, getOutbox, inboxType } = props;

  const declaration = props.row.declaration || props.row;
  const { data: template } = useResource(
    'all-templates',
    declaration.template_id
  );
  const pending = props.row.inbox_status === 'pending';
  const state = declaration.state;
  const prefix =
    template.template_type.refname === 'solemn_declaration'
      ? 'solemn'
      : 'authorization';
  const filteredFullName = declaration.fields
    .filter(field =>
      includes([`${prefix}:firstname`, `${prefix}:surname`], field.key)
    )
    .map(field => field.value);
  const initials = filteredFullName.map(token => head(token.split('')));
  const authorizingFullName = filteredFullName.join(' ');
  let url;

  // ~ : Bitwise Operator (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_Operators)
  const hasTelephone = !!~declaration.fields.findIndex(field =>
    field.key.includes('tel')
  );

  if (declaration.reference_code) {
    url = `/show/${declaration.reference_code}`;
    if (props.inboxType === 'inbox') {
      url = `${url}/${props.row.id || ''}`;
    }
  } else {
    if (declaration.state === 'editing') {
      if (hasTelephone) {
        url = `/dilosi/${declaration.id}/text`;
      } else {
        url = url = `/dilosi/${declaration.id}/edit`;
      }
    } else if (declaration.state === 'requested') {
      url = `/dilosi/${declaration.id}/sms`;
    }
  }

  return (
    <ListItem component={Link} to={url} button alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          className={clsx(pending && classes.pendingAvatar)}
          alt={authorizingFullName}
        >
          <NormalText>{initials}</NormalText>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            component="span"
            variant="body1"
            className={classes.inline}
            color="textPrimary"
            className={clsx(pending && classes.inboxTitle)}
          >
            {template.shortname}
          </Typography>
        }
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              Εκδότης: {authorizingFullName}
            </Typography>
            {' - '}
            {t(`${props.row.inbox_status || state}_verbose_user`)}
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction>
        {declaration.reference_code === null &&
          declaration.state !== 'requested' && (
            <DeleteButton
              declarationId={declaration.id}
              getMyInbox={getMyInbox}
              getOutbox={getOutbox}
              inboxType={inboxType}
            />
          )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
InboxItem.propTypes = {
  getMyInbox: PropTypes.func,
  getOutbox: PropTypes.func,
  inboxType: PropTypes.string,
  row: PropTypes.object,
};

export default function MyInbox() {
  const classes = useStyles();
  const { inbox: inboxType } = useParams();
  const [myInbox, { getMyInbox }] = useMyInboxStore();
  const [outbox, { getOutbox }] = useOutboxStore();

  useEffect(() => {
    if (inboxType === 'sent') {
      getOutbox();
    } else if (inboxType === 'inbox') {
      getMyInbox();
    }
  }, [inboxType]);

  const rows = inboxType === 'inbox' ? myInbox : outbox;

  return (
    <Fragment>
      <Title noMargin size="xl">
        {`vault.${inboxType}.title`}
      </Title>
      {rows.length === 0 ? (
        <div className={classes.container}>
          <Caption className={classes.emptyTitle}>
            {`vault.${inboxType}.empty`}
          </Caption>
        </div>
      ) : (
        <div className={classes.container}>
          <NormalText>{`vault.${inboxType}.description`}</NormalText>
          <List>
            {rows.map((row, index) => (
              <>
                <InboxItem
                  inboxType={inboxType}
                  key={index}
                  row={row}
                  getMyInbox={getMyInbox}
                  getOutbox={getOutbox}
                />
                {index + 1 < rows.length && (
                  <Divider variant="inset" component="li" />
                )}
              </>
            ))}
          </List>
        </div>
      )}
    </Fragment>
  );
}
