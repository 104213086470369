import React from 'react';
import clsx from 'clsx';
import BaseText from './BaseText';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => {
  return {
    caption: {
      padding: theme.spacing(1, 0, 0),
      color: '#4d4d4d',
      fontWeight: 'normal',
    },
  };
});

export default function Caption(props) {
  const classes = useStyles();
  return (
    <Typography className={clsx(props.className, classes.caption)} variant="h6">
      <BaseText {...props} />
    </Typography>
  );
}
Caption.propTypes = {
  i18n: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
