import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import Button from '../../../components/Button';
import useAuth, { useRequireAdminUser, useUser } from '../../../lib/auth';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { TablePagination } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SelectNewUser from '../components/SelectNewUser';
import useStaffUserStore from '../store/staffUser';
import { useMessages } from '../../../utils/messages';
import { useDeleteUserRole } from '../models/entities';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  column: {
    width: '25%',
  },
}));

const DeleteButton = props => {
  const { refresh: reloadProfile } = useAuth();
  const [open, setOpen] = useState(false);
  const { permId, entityId, getStaffUsers } = props;
  const showMessage = useMessages();
  const {
    fetch: deleteUserRole,
    error,
    loading,
    loaded,
    data: result,
  } = useDeleteUserRole(entityId, permId);

  const handleDelete = () => {
    deleteUserRole();
  };

  useEffect(() => {
    if (error) {
      setOpen(false);
      showMessage('Σφάλμα', 'error');
    } else if (loaded) {
      setOpen(false);
      getStaffUsers(entityId);
      reloadProfile();
      showMessage(
        'Η διαγραφή του ρόλου του χρήστη ολοκληρώθηκε επιτυχώς',
        'success'
      );
    }
  }, [error, loaded]);

  return (
    <>
      <IconButton aria-label="delete" onClick={() => setOpen(true)}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Διαγραφή χρήστη</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Είστε βέβαιοι ότι θέλετε να διαγράψετε τον ρόλο του συγκεκριμένου
            χρήστη;
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Ακύρωση
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Διαγραφή
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

StaffTable.propTypes = {
  activeUsers: PropTypes.array,
};

export default function StaffTable(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useRequireAdminUser();
  console.log('user', user);
  const { entityId } = props;
  const [activeUsers, { getStaffUsers }] = useStaffUserStore();
  useEffect(() => {
    getStaffUsers(entityId);
  }, []);
  const {
    hasNext,
    hasPrevious,
    setLimit,
    setPage,
    total,
    limitOptions,
    limit,
    page,
  } = activeUsers;
  // extract useful data from staffRoles that will be displayed in the staff users table
  const rows = activeUsers
    .filter(activeUser => activeUser.role !== 'author')
    .map(filteredUser => {
      const lastName = filteredUser.user_info.surname;
      const firstName = filteredUser.user_info.firstname;
      const fullName = firstName.concat(' ', lastName);
      const username = filteredUser.user_info.username;
      const id = filteredUser.user_info.id;
      const role = filteredUser.role;
      const afm = filteredUser.user_info.afm;
      const permId = filteredUser.id;
      return { fullName, username, id, role, afm, permId };
    });

  console.log('staff', activeUsers);

  return (
    <>
      <Title size="md">Χρήστες με Πρόσβαση στη Θυρίδα</Title>
      <SelectNewUser entityId={entityId} getStaffUsers={getStaffUsers} />
      {rows.length === 0 ? (
        <div className={classes.container}>
          Δεν υπάρχουν χρήστες με πρόσβαση στη συγκεκριμένη θυρίδα
        </div>
      ) : (
        <TableContainer component={Paper} elevation={0} square>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ονοματεπώνυμο</TableCell>
                <TableCell>ΑΦΜ</TableCell>
                <TableCell>Ρόλος</TableCell>
                <TableCell>Ενέργειες</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.container}>
              {rows.lengtht === 0 ? (
                ''
              ) : (
                <>
                  {rows.map(row => (
                    <TableRow key={row.id}>
                      <TableCell className={classes.column}>
                        {row.fullName}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {row.afm}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {t(`dashboard.roles.${row.role}`)}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {!(user.afm === row.afm && row.role === 'head') && (
                          <DeleteButton
                            entityId={entityId}
                            permId={row.permId}
                            getStaffUsers={getStaffUsers}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}{' '}
                </>
              )}
            </TableBody>
            {/* <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={limitOptions}
                  count={total}
                  page={page}
                  rowsPerPage={limit}
                  onChangeRowsPerPage={e => {
                    setLimit(e.target.value);
                  }}
                  onChangePage={(e, p) => {
                    setPage(p);
                  }}
                  backIconButtonProps={{ disabled: !hasPrevious }}
                  nextIconButtonProps={{ disabled: !hasNext }}
                />
              </TableRow>
            </TableFooter> */}
          </Table>
        </TableContainer>
      )}
    </>
  );
}
