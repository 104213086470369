import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export default function Footer(props) {
  const theme = useTheme();
  const Footer = theme.footer.component;

  return (
    <Box displayPrint="none" zIndex="2">
      <Footer />
    </Box>
  );
}
