import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Title from './Title';
import Button from '../../../components/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { TablePagination } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useAcceptUser, useRejectUser } from '../models/entities';
import { useMessages } from '../../../utils/messages';
import usePendingUserStore from '../store/pendingUsers';
import useStaffUserStore from '../store/staffUser';
import { useResourceMany } from '../../../lib/api';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  container: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    paddingTop: theme.spacing(4),
  },
  acceptButton: {
    color: theme.palette.success.main,
  },
  rejectButton: {
    color: theme.palette.error.main,
  },
  column: {
    width: '25%',
  },
}));

const RejectButton = props => {
  const classes = useStyles();
  const { actionId, entityId, getPendingUsers } = props;
  const [open, setOpen] = useState(false);
  const showMessage = useMessages();
  const {
    fetch: rejectUser,
    error,
    loading,
    loaded,
    data: result,
  } = useRejectUser(entityId, actionId);

  const handleReject = () => {
    rejectUser();
  };

  useEffect(() => {
    if (error) {
      showMessage('Σφάλμα', 'error');
    } else if (loaded) {
      setOpen(false);
      getPendingUsers(entityId);
      showMessage('Η απόρριψη χρήστη ολοκληρώθηκε επιτυχώς', 'success');
    }
  }, [error, loaded]);

  return (
    <>
      <IconButton aria-label="reject" onClick={() => setOpen(true)}>
        <CancelIcon className={classes.rejectButton} />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Απόρριψη χρήστη</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Είστε βέβαιοι ότι θέλετε να απορρίψετε τον επιλεγμένο χρήστη;
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Ακύρωση
          </Button>
          <Button color="primary" onClick={handleReject}>
            Απόρριψη
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AcceptButton = props => {
  const classes = useStyles();
  const { actionId, entityId, getPendingUsers, getStaffUsers } = props;
  const [open, setOpen] = useState(false);
  const showMessage = useMessages();
  const {
    fetch: acceptUser,
    error,
    loading,
    loaded,
    data: result,
  } = useAcceptUser(entityId, actionId);

  const handleAccept = () => {
    acceptUser();
  };

  useEffect(() => {
    if (error) {
      showMessage('Σφάλμα', 'error');
    } else if (loaded) {
      setOpen(false);
      getPendingUsers(entityId);
      getStaffUsers(entityId);
      showMessage('Η αποδοχή χρήστη ολοκληρώθηκε επιτυχώς', 'success');
    }
  }, [error, loaded]);

  return (
    <>
      <IconButton aria-label="accept" onClick={() => setOpen(true)}>
        <CheckCircleIcon className={classes.acceptButton} />
      </IconButton>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Αποδοχή χρήστη</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Είστε βέβαιοι ότι θέλετε να αποδεχθείτε τον επιλεγμένο χρήστη;
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Ακύρωση
          </Button>
          <Button color="primary" onClick={handleAccept}>
            Αποδοχή
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PendingUsersTable.propTypes = {
  pendingUsers: PropTypes.array,
};

export default function PendingUsersTable(props) {
  const { entityId } = props;
  const [pendingUsers, { getPendingUsers }] = usePendingUserStore();
  const [activeUsers, { getStaffUsers }] = useStaffUserStore();
  useEffect(() => {
    getPendingUsers(entityId);
  }, []);
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    hasNext,
    hasPrevious,
    setLimit,
    setPage,
    total,
    limitOptions,
    limit,
    page,
  } = pendingUsers;

  // extract useful data from staffRoles that will be displayed in the staff users table
  const rows = pendingUsers
    .filter(pendingUser => pendingUser.role !== 'author')
    .map(filteredUser => {
      const lastName = filteredUser.claim.person.surname;
      const firstName = filteredUser.claim.person.firstname;
      const actionId = filteredUser.action_id;
      const fullName = firstName.concat(' ', lastName);
      const afm = filteredUser.claim.person.afm;
      const role = filteredUser.role;
      return { fullName, afm, actionId, role };
    });
  console.log('pend', pendingUsers);
  return (
    <>
      <Title size="md">Αιτήματα πρόσβασης σε εκκρεμότητα</Title>
      {rows.length === 0 ? (
        <div className={classes.container}>
          Δεν υπάρχουν αιτήματα πρόσβασης στη θυρίδα
        </div>
      ) : (
        <TableContainer component={Paper} elevation={0} square>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Ονοματεπώνυμο</TableCell>
                <TableCell>ΑΦΜ</TableCell>
                <TableCell>Ρόλος</TableCell>
                <TableCell>Ενέργειες</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.lengtht === 0 ? (
                ''
              ) : (
                <>
                  {rows.map(row => (
                    <TableRow key={row.actionId}>
                      <TableCell className={classes.column}>
                        {row.fullName}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {row.afm}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {t(`dashboard.roles.${row.role}`)}
                      </TableCell>
                      <TableCell className={classes.column}>
                        <AcceptButton
                          entityId={entityId}
                          actionId={row.actionId}
                          getPendingUsers={getPendingUsers}
                          getStaffUsers={getStaffUsers}
                        />
                        <RejectButton
                          entityId={entityId}
                          actionId={row.actionId}
                          getPendingUsers={getPendingUsers}
                        />
                      </TableCell>
                    </TableRow>
                  ))}{' '}
                </>
              )}
            </TableBody>
            {/* <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={limitOptions}
                  count={total}
                  page={page}
                  rowsPerPage={limit}
                  onChangeRowsPerPage={e => {
                    setLimit(e.target.value);
                  }}
                  onChangePage={(e, p) => {
                    setPage(p);
                  }}
                  backIconButtonProps={{ disabled: !hasPrevious }}
                  nextIconButtonProps={{ disabled: !hasNext }}
                />
              </TableRow>
            </TableFooter> */}
          </Table>
        </TableContainer>
      )}
    </>
  );
}
