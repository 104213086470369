import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Support 2 buttons only

const useStyles = makeStyles(theme => ({
  itemRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function ButtonsContainer(props) {
  const classes = useStyles();
  return (
    <Grid container spacing={2} {...props}>
      <Grid item sm={6} xs={12}>
        {props.children[0]}
      </Grid>
      <Grid item sm={6} xs={12} className={classes.itemRight}>
        {props.children[1]}
      </Grid>
    </Grid>
  );
}
ButtonsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
