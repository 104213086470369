import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Top from '../components/Top';
import { Paragraph } from '../components/Typography';
import TemplatesList from '../components/TemplatesList';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';

function SearchInput(props) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginBottom: 30,
      }}
    >
      <TextField
        {...props}
        id="search"
        placeholder={t('templates_page.search')}
        variant="outlined"
        style={{
          width: '100%',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              style={{
                marginRight: 12,
              }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default function Templates(props) {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  return (
    <div>
      <Top
        captionLeft={props.captionLeft}
        captionRight={props.captionRight}
        hasBackButton
        title={props.title}
        size="xl"
      />
      <Paragraph>{t('templates_page.content')}</Paragraph>
      <SearchInput
        value={search}
        onChange={evt => {
          setSearch(evt.currentTarget.value);
        }}
      />
      <TemplatesList search={search} />
    </div>
  );
}
Templates.propTypes = {
  captionLeft: PropTypes.string,
  captionRight: PropTypes.string,
  title: PropTypes.string,
};
